import { PROFILE_FRAGMENT } from './fragments'

/**
 * Query for getting user profile
 */
export const GET_PROFILE_QUERY = `
query GetProfile {
  portfolio {
    ...profileFragment
  }
}

${PROFILE_FRAGMENT}
`
