/**
 * NuxtJS plugin to capture scroll position
 * after back to previous page
 */

/**
 * Monitor the scroll selector's scroll position
 * @type {string}
 */
export const scrollSelector = '#pageScrollContainer'
let popStateDetected = false

/**
 * Store Scroll Position
 * history state key : { x, y }
 */
const positionMap = {}

export default function ({ app }) {
  // On client side,
  if (process.client) {
    window.addEventListener('popstate', (event) => {
      popStateDetected = true
    })

    app.router.beforeEach((to, from, next) => {
      // Move forward and store position
      if (!popStateDetected && history && history.state) {
        const pageDom = document.querySelector(scrollSelector)
        if (pageDom) {
          positionMap[history.state.key] = {
            x: pageDom.scrollLeft,
            y: pageDom.scrollTop,
          }
        }
      }
      next()
    })

    app.router.afterEach((route) => {
      // Move backward and recover position
      let savedPosition = null
      if (popStateDetected && history && history.state) {
        popStateDetected = false
        savedPosition = positionMap[history.state.key]
      }
      const vm = app.router.app
      vm.$nextTick(() => {
        recoverScrollPosition(savedPosition)
      })
    })
  }
}

function recoverScrollPosition(savedPosition) {
  const pageDom = document.querySelector(scrollSelector)
  const pos = {
    x: 0,
    y: 0,
  }
  if (pageDom) {
    if (savedPosition) {
      pos.x = savedPosition.x
      pos.y = savedPosition.y
    }
    pageDom.scrollTo(pos.x, pos.y)
  }
}
