<template>
  <base-jc
    class="jc_ad--active"
    :company="company"
    :job="job"
    :tracking-params="trackingParams"
    :is-fire-tracking-event="isFireTrackingEvent"
    :is-interact="isInteract"
    :observer="observer"
    :position="position"
    :session-id="sessionId"
    :source="source"
    :click="onClick"
    @impression="onImpressionEvent"
  >
    <span slot="job-ad" class="jc_ad">{{ $t('jobDetail.jobAdsLabel') }}</span>
  </base-jc>
</template>

<script>
import { sendJobAdClickEvent, sendJobAdImpressionEvent } from '../../../libs/tracking/utils/event'
import BaseJobCard from './baseJobCard'
import { clickAd } from '../../../api/oppa/bidding'

export default {
  components: {
    'base-jc': BaseJobCard,
  },
  methods: {
    async fireClickAdEvent() {
      await clickAd(this.requestId, this.biddingId, this.job._id)
    },
    onClick() {
      this.fireClickAdEvent()
      if (this.isFireTrackingEvent) {
        sendJobAdClickEvent(this.$eventTracker, {
          jobId: this.job._id,
          position: this.position,
          sessionId: this.sessionId,
          source: this.source,
          sourceSection: this.sourceSection,
          trackingSearchParams: this.trackingParams,
        })
      }
    },
    onImpressionEvent() {
      if (this.isFireTrackingEvent) {
        sendJobAdImpressionEvent(this.$eventTracker, {
          jobId: this.job._id,
          position: this.position,
          sessionId: this.sessionId,
          source: this.source,
          sourceSection: this.sourceSection,
          trackingSearchParams: this.trackingParams,
        })
      }
    },
  },
  props: {
    // Required ads argument
    biddingId: {
      default: '',
      type: String,
    },
    company: {
      required: true,
      type: Object,
    },
    isFireTrackingEvent: {
      default: true,
      type: Boolean,
    },
    isInteract: {
      default: false,
      required: false,
      type: Boolean,
    },
    job: {
      required: true,
      type: Object,
    },
    observer: {
      default: () => ({}),
      type: Object,
    },
    position: {
      required: true,
      type: Number,
    },
    // Required ads argument
    requestId: {
      default: '',
      type: String,
    },
    sessionId: {
      default: '',
      type: String,
    },
    source: {
      default: '',
      type: String,
    },
    sourceSection: {
      default: '',
      type: String,
    },
    /** Formatted tracking params */
    trackingParams: {
      default: () => ({}),
      type: Object,
    },
  },
}
</script>

<style lang="postcss" scoped>
.jc_ad--active {
  @apply px-0;

  & >>> .jc__salary {
    @apply px-3;
  }
  & >>> .jc__cover {
    @apply px-3;
  }
  & >>> .emp-type {
    @apply ml-2;
  }
}

.jc_ad {
  @apply inline-block text-center text-black rounded-r text-xs;
  @apply bg-pink-xlight;
  @apply px-3;
  min-width: 44px;
  line-height: 1.72;
}
</style>
