import { getJSON } from '..'
import { removeEmptyField } from '../../libs/object'

const requestAdsTimeoutMs = 5000

/**
 * Send GET request to Octo endpoint,
 * return JSON data
 * @param {string} path
 * @param {*} bodyJson
 * @param {string} token
 */
export function getOppa(path, bodyJson, options) {
  const oppaUrl = process.env.SEEKER_WEB__OPPA_URL || process.env.SEEKER_WEB__OPPA_INTERNAL_URL || ''
  return getJSON(`${oppaUrl}${path}`, bodyJson, {}, options)
}

export function requestAd(requestId, employmentType, jobTypes, districts, latlng, skipJobIds, query, userid) {
  return getOppa(
    'get',
    removeEmptyField({
      d: districts,
      et: employmentType,
      ex: skipJobIds,
      jt: jobTypes,
      latlng,
      q: query,
      requestid: requestId,
      userid,
    }),
    {
      timeout: requestAdsTimeoutMs,
    },
  )
}

export function clickAd(requestId, biddingId, adId) {
  return getOppa(
    'click',
    removeEmptyField({
      adid: adId,
      bidid: biddingId,
      requestid: requestId,
    }),
  )
}
