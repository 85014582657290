import { PROFILE_FRAGMENT } from './fragments'

export const PROFILE_UPDATE_NAME = `
mutation UpdateProfileName (
  $id: ID
  $name: String
) {
  update_profile (
    applicant: $id
    name: $name
  ) {
    ...profileFragment
  }
}

${PROFILE_FRAGMENT}
`
export const PROFILE_UPDATE_INTRO = `
mutation UpdateProfileIntro (
  $id: ID
  $intro: String
) {
  update_profile (
    applicant: $id
    message_to_employer: $intro
  ) {
    ...profileFragment
  }
}

${PROFILE_FRAGMENT}
`

export const PROFILE_UPDATE_EDUCATION = `
mutation UpdateProfileEducation (
  $id: ID
  $education: ID
) {
  update_profile (
    applicant: $id
    education: $education
  ) {
    ...profileFragment
  }
}

${PROFILE_FRAGMENT}
`

export const PROFILE_UPDATE_LANGUAGE = `
mutation UpdateProfileLanguage (
  $id: ID
  $spoken_skill: [MSpokenSkill]
  $written_skill: [MWrittenSkill]
) {
  update_profile (
    applicant: $id
    spoken_skill: $spoken_skill
    written_skill: $written_skill
  ) {
    ...profileFragment
  }
}

${PROFILE_FRAGMENT}
`

export const PROFILE_UPDATE_SKILL = `
mutation UpdateProfileLanguage (
  $id: ID
  $cert: [MCert]
  $skill: [MSkill]
) {
  update_profile (
    applicant: $id
    cert: $cert
    skill: $skill
  ) {
    ...profileFragment
  }
}

${PROFILE_FRAGMENT}
`

export const PROFILE_UPDATE_WORK_EXP = `
mutation UpdateProfileWorkExp (
  $id: ID
  $work_experience: [MWorkExperience]
) {
  update_profile (
    applicant: $id
    work_experience: $work_experience
  ) {
    ...profileFragment
  }
}

${PROFILE_FRAGMENT}
`

export const PROFILE_UPDATE_PICTURE = `
mutation UpdateProfilePicture (
  $id: ID
  $profile_picture: MImageAsset
) {
  update_profile (
    applicant: $id
    profile_picture: $profile_picture
  ) {
    ...profileFragment
  }
}

${PROFILE_FRAGMENT}
`

export const APPLY_JOB = `
mutation ApplyJob (
  $jobID: ID
  $addressIDs: [ID]
) {
  apply_job (
    job_id: $jobID
    address_ids: $addressIDs
  ) {
    _id
  }
}
`

export const REGISTER_DEVICE = `
mutation RegisterDevice (
  $agent: PushAgent
  $deviceName: String
  $token: String
) {
  register_device(agent: $agent, name: $deviceName, token: $token)
}
`

export const DELETE_ACCOUNT = `
mutation DeleteAccount (
  $action: DeleteAccountAction
  $telephone: String
  $reason: String
) {
  delete_account(action: $action, telephone: $telephone, reason: $reason)
}
`
