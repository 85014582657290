/**
 * Mixin for handling redirection
 */
export default {
  computed: {
    /**
     * Target path for redirection
     * based on URL query
     */
    redirectPath() {
      return this.$route.query.target || '/'
    },

    /**
     * URL query for passing redirection info
     */
    redirectQuery() {
      if (this.$route.query.target !== '/') {
        return {
          target: this.$route.query.target,
        }
      }
      return {}
    },
  },
  methods: {
    /**
     * Handle redirect to target path
     */
    redirect() {
      this.$router.push(this.redirectPath)
    },
  },
}
