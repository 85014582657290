<template>
  <header class="emp-hdr">
    <emp-header-main @menu-toggle="onMenuToggleClick" />
    <emp-header-nav />
  </header>
</template>

<script>
import EmployerHeaderMain from './employerHeaderMain'
import EmployerHeaderNav from './employerHeaderNav'

export default {
  components: {
    'emp-header-main': EmployerHeaderMain,
    'emp-header-nav': EmployerHeaderNav,
  },
  methods: {
    /**
     * Menu toggle click event
     */
    onMenuToggleClick() {
      this.$emit('menu-toggle')
    },
  },
}
</script>

<style lang="postcss" scoped>
.emp-hdr {
  @apply flex flex-col w-full bg-white shadow;
}

@screen mobile {
  .emp-hdr-main {
    @apply h-h-emp;
  }
  .emp-hdr-nav {
    @apply hidden;
  }
}

@screen desktop {
  .emp-hdr {
    @apply h-h-emp-desktop;
  }
  .emp-hdr-main {
    @apply flex-grow;
  }
}
</style>
