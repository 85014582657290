/**
 * For the beginning, pick up the firebase messaging api by the offical sample
 * https://github.com/firebase/quickstart-js/tree/master/messaging
 */

import Bowser from 'bowser'
import { captureException } from '@sentry/browser'
import { postSeekerGQL } from '../../api/octo/graphql'
import { REGISTER_DEVICE } from '../../api/octo/graphql/mutation'

const ios = 'IOS'
const android = 'Android'

// Desktop
const PUSH_AGENT_CHROME = 'Chrome'
const PUSH_AGENT_FIREBOX = 'Firefox'
const PUSH_AGENT_SAFARI = 'Safari'

// Mobile
const PUSH_AGENT_CHROME_ANDROID = 'ChromeAndroid'
const PUSH_AGENT_CHROME_IOS = 'ChromeIOS'
const PUSH_AGENT_FIREFOX_ANDROID = 'FirefoxAndroid'
const PUSH_AGENT_FIREFOX_IOS = 'FirefoxIOS'
const PUSH_AGENT_SAFARI_MOBILE = 'SafariMobile'

// Unknown
const PUSH_AGENT_UNKNOWN = 'Unknown'

const pushAgentList = [
  PUSH_AGENT_CHROME,
  PUSH_AGENT_FIREBOX,
  PUSH_AGENT_SAFARI,
  PUSH_AGENT_CHROME_ANDROID,
  PUSH_AGENT_CHROME_IOS,
  PUSH_AGENT_FIREFOX_ANDROID,
  PUSH_AGENT_FIREFOX_IOS,
  PUSH_AGENT_SAFARI_MOBILE,
]

function getAgent(browser) {
  const os = browser.parsedResult.os
  const browserResult = browser.parsedResult.browser
  let osName = os.name
  if (osName === 'iOS') {
    osName = ios
  }

  const browserName = browserResult.name

  // Detect Non-Mobile
  if (osName !== android && osName !== ios) {
    osName = ''
  }

  // Detect Safari Mobile
  if (browserName === PUSH_AGENT_SAFARI && osName === ios) {
    osName = 'Mobile'
  }

  let pushAgent = `${browserName}${osName}`
  if (!pushAgentList.includes(pushAgent)) {
    pushAgent = PUSH_AGENT_UNKNOWN
  }
  return pushAgent
}

function getDeviceName(browser) {
  const os = browser.parsedResult.os
  const browserResult = browser.parsedResult.browser
  return `${os.name} (${os.version}), ${browserResult.name} (${browserResult.version})`
}

function setUpFCMMessaging(fireMess, publicKey) {
  fireMess.usePublicVapidKey(publicKey)
}

async function createCustomServiceWorker(fireMess, path) {
  try {
    const swRegistration = await navigator.serviceWorker.register(path)

    // The timing when browser updates sw when sw has an update is unreliable by my experiment.
    // It leads to version conflict when the SDK upgrades to a newer version in the main page, but
    // sw is stuck with the old version. For example, https://github.com/firebase/firebase-js-sdk/issues/2590
    // The following line reliably updates sw if there was an update.
    swRegistration.update().catch(() => {
      /* it is non blocking and we don't care if it failed */
    })

    // Setup custom service worker
    fireMess.useServiceWorker(swRegistration)
  } catch (e) {
    captureException(e)
  }
}

export function initFirebaseMessaging(fireMess, options) {
  // if support
  if (fireMess) {
    if (options.publicKey) {
      // Setup FCM
      setUpFCMMessaging(fireMess, options.publicKey)

      if (options.serviceWorker && options.serviceWorker.path) {
        createCustomServiceWorker(fireMess, options.serviceWorker.path)
      }
    }
  }
}

export async function deleteFCMToken(fireMess) {
  // if support
  if (fireMess) {
    try {
      await fireMess.deleteToken()
    } catch (err) {
      captureException(err)
    }
  }
}

export async function getFCMToken(fireMess) {
  let token
  // if support
  if (fireMess) {
    try {
      token = await fireMess.getToken()
    } catch (err) {
      // Fail to get token
      captureException(err)
    }
  }
  return token
}

export function monitorFCMTokenRefresh(app, fireMess) {
  // if support
  if (fireMess) {
    fireMess.onTokenRefresh(() => {
      app.$tokenReady((accessToken) => {
        // For login user
        if (app.store.getters['auth/isLoggedIn']) {
          registerDevice(fireMess, accessToken)
        }
      })
    })
  }
}

export async function registerDevice(fireMess, accessToken) {
  // if support
  if (process.client && fireMess) {
    // Request notification permission
    const permission = await Notification.requestPermission()

    // Grant permission
    if (permission === 'granted') {
      // Get FCM Token
      const fcmToken = await getFCMToken(fireMess)
      if (fcmToken) {
        const bowser = Bowser.getParser(navigator.userAgent)
        const deviceName = getDeviceName(bowser)
        if (deviceName) {
          const agent = getAgent(bowser)

          // Trigger to register device
          await postSeekerGQL(
            REGISTER_DEVICE,
            {
              agent,
              deviceName,
              token: fcmToken,
            },
            accessToken,
          )
        }
      }
    }
  }
}

/**
 * Unregister service workers
 * registered to current domain
 * @returns {Promise<void>}
 */
export async function unregisterAllServiceWorkers() {
  try {
    if (
      typeof navigator !== 'undefined' &&
      typeof navigator.serviceWorker !== 'undefined' &&
      typeof navigator.serviceWorker.getRegistrations === 'function'
    ) {
      // If service worker available,
      // try to get all registrations in current domain
      const registrations = await navigator.serviceWorker.getRegistrations()

      // For each returned registration,
      // try to unregister it
      if (Array.isArray(registrations)) {
        for (let i = 0; i < registrations.length; i++) {
          const registration = registrations[i]
          if (typeof registration !== 'undefined' && typeof registration.unregister === 'function') {
            await registration.unregister()
          }
        }
      }
    }
  } catch (err) {
    captureException(err)
  }
}
