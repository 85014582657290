<template>
  <header class="hdr" :class="hdrBottom">
    <section class="hdr__main">
      <div class="hrd__leading">
        <app-header-masthead class="hdr__masthead" />
      </div>
      <div class="hrd__content">
        <search-bar v-if="hasSearchBar" :init-value="searchText" @on-search="onSearch" />
        <slot name="search" />
      </div>
      <div class="hrd__ending">
        <div class="hdr-nav__links">
          <template v-for="(link, i) in links">
            <!-- Link -->
            <b-nav :key="`HeaderNavLink${i}`" class="hdr-nav__link" v-bind="link" @click="onSendEvent(link.event)" />
          </template>
        </div>
        <div class="hrd__ending-btn">
          <template v-for="(item, i) in altLocaleLinks">
            <n-link
              v-if="item && item.titleShort"
              :key="`localeLink${i}`"
              class="locale-btn"
              :to="item.linkPath"
              :title="item.titleShort"
            >
              <svg-globe class="globe-icon svg-fill" />
              {{ item.titleShort }}
            </n-link>
          </template>
          <main-header-menu v-if="isReady" class="hrd__menu" />
        </div>
      </div>
    </section>
    <section class="hdr__actions">
      <slot name="actions" />
    </section>
  </header>
</template>

<script>
import { sendEmployerPortalClickEvent, sendUpdateLanguageEvent } from '../../libs/tracking/utils/event'
import AppHeaderMasthead from './appHeaderMasthead'
import GlobeSVG from '../../assets/svg/globe.svg?inline'
import MainHeaderMenu from './main/mainHeaderMenu'
import NavButton from '../button/navButton'
import SearchBar from './searchBar'

export default {
  components: {
    'app-header-masthead': AppHeaderMasthead,
    'b-nav': NavButton,
    'main-header-menu': MainHeaderMenu,
    'search-bar': SearchBar,
    'svg-globe': GlobeSVG,
  },
  computed: {
    hdrBottom() {
      return this.hasSearchBar ? 'hdr--bottom__searchbar' : ''
    },
    links() {
      return [
        ...this.altLocaleLinks,
        {
          event: {
            name: 'portal',
          },
          linkPath: this.getArticleLocalePath('employer/landing'),
          title: this.$t('link.postJob'),
        },
      ]
    },
  },
  data() {
    return {
      isReady: false,
    }
  },
  methods: {
    onSearch(searchText) {
      this.$emit('on-search', searchText)
    },
    onSendEvent(event) {
      if (event) {
        switch (event.name) {
          case 'portal': {
            sendEmployerPortalClickEvent(this.$eventTracker)
            break
          }
        }
      }
    },
  },
  mounted() {
    this.$tokenReady((token) => {
      this.isReady = true
    })
  },
  props: {
    hasSearchBar: {
      default: true,
      required: false,
      type: Boolean,
    },
    searchText: {
      default: '',
      required: false,
      type: String,
    },
  },
  watch: {
    currentLocale(to, from) {
      sendUpdateLanguageEvent(this.$eventTracker, to.code)
    },
  },
}
</script>

<style lang="postcss" scoped>
.hdr {
  @apply relative w-full z-10;
  @apply text-pink bg-red-500 shadow;
}
.hdr__main {
  @apply px-2 mx-auto;
}
.hdr__actions {
  @apply mx-auto;
}
.hrd__ending {
  @apply items-center justify-end;
}
.hrd__menu {
  @apply flex items-center;
  @apply relative;
  margin-top: 3px;
}
.hdr-nav__link {
  &::v-deep.b {
    height: 40px;
  }
}

.locale-btn {
  display: none;
}

.globe-icon {
  @apply mr-1 text-red-900;
  width: 16px;
}

@screen mobile {
  .hdr--bottom__searchbar {
    @apply pb-1 pt-2;
  }
  .hdr__main {
    @apply relative;
  }
  .hrd__content {
    @apply flex flex-row m-2;
  }
  .hrd__leading {
    @apply mt-2 inline-block;
  }
  .hrd__ending {
    @apply absolute top-0 right-0;
  }
  .hrd__ending-btn {
    @apply flex items-center mr-2;
    height: 42px;
  }
  .hdr-nav__links {
    @apply sr-only;
  }
  .locale-btn {
    @apply flex rounded justify-center items-center cursor-pointer no-underline text-red-900 mr-2;
    margin-bottom: 2px;
  }
  .hrd__menu >>> .b-menu {
    @apply mr-0;
  }
}
@screen desktop {
  .hdr__main {
    @apply flex w-full h-h-main-desktop;
    @apply px-4;
  }
  .hdr__actions {
    @apply flex w-full;
  }
  .hrd__leading {
    @apply flex flex-shrink-0;
  }
  .hrd__content {
    @apply flex flex-1 min-w-0 justify-center items-center;
    @apply px-8;
  }
  .hrd__ending {
    @apply flex flex-shrink-0;
  }
  .hrd__menu {
    @apply h-h-main-desktop;
  }
  .hdr-nav__links {
    @apply flex items-center text-black;
    @apply mr-4;
  }
  .hdr-nav__link {
    @apply no-underline flex-shrink-0 text-cbody1 font-cbody1;
    &:hover,
    &:active {
      @apply text-red-500 no-underline;
    }
  }
  .hdr-nav__link >>> .b__title {
    @apply text-red-50;
  }
}
</style>
