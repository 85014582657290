<template>
  <div class="ftr__links">
    <app-link
      v-for="(link, i) in links"
      :key="`FooterSectionLink${i}`"
      class="ftr__link"
      v-bind="link"
      @click="onClick(link)"
    />
  </div>
</template>

<script>
import AppLink from '../appLink'

export default {
  components: {
    'app-link': AppLink,
  },
  methods: {
    onClick(link) {
      this.$emit('click', link)
    },
  },
  props: {
    links: {
      default: () => [],
      type: Array,
    },
  },
}
</script>

<style lang="postcss" scoped>
@screen mobile {
  .ftr__link {
    @apply mb-7;
  }
}
@screen desktop {
  .ftr__link:not(:last-child) {
    @apply mb-4;
  }
}
</style>
