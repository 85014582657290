import { captureException, configureScope } from '@sentry/browser'
import fetch from 'node-fetch'

/**
 * Handle fetch error
 * @param {Error} err
 * @returns {FetchResponse}
 */
const handleFetchError = (err, url) => {
  // Send exception to sentry
  configureScope((scope) => {
    scope.setExtra('request-url', url)
  })
  captureException(err)

  // Return error response w/ no status code
  return {
    error: {
      payload: err,
    },
  }
}

/**
 * Handle fetch response
 * based on status
 * @returns {FetchResponse}
 */
const handleFetchResponse = (res, data, url = '', statusCaptureThreshold = 500) => {
  if (res.status >= 200 && res.status < 300) {
    // If request success (code *2xx*),
    // return fetch response
    return { data }
  } else {
    // If status code greater than threshold,
    // send exception to sentry
    if (res.status >= statusCaptureThreshold) {
      configureScope((scope) => {
        scope.setExtra('request-url', url)
        scope.setExtra('payload', JSON.stringify(data))
      })
      captureException(new Error(`Request status code ${res.status}`))
    }

    // Return error response w/ status code
    return {
      error: {
        payload: data,
        status: res.status,
      },
    }
  }
}

function fetchTimeout(url, options, ms) {
  const controller = new AbortController()
  setTimeout(() => controller.abort(), ms)
  return fetch(url, { signal: controller.signal, ...options })
}

function querybuilder(json) {
  return Object.entries(json)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map((v) => `${key}=${v}`).join('&')
      }
      return `${key}=${value}`
    })
    .join('&')
}

/**
 * @typedef FetchError
 * @property {*} payload
 * @property {number} status
 */
/**
 * @typedef FetchResponse
 * @property {*} data
 * @property {FetchError} error
 */
/**
 * Fetch JSON data from provided URL using provided method,
 * return JSON response
 * @param {string} url
 * @param {string} method
 * @param {*} bodyJson
 * @param {Map<string, string>} headers
 * @returns {Promise<FetchResponse>}
 */
export async function fetchJSON(url, method, bodyJson, headers, options = {}) {
  try {
    const request = {
      headers,
      method,
    }

    if (options.credentials) {
      request.credentials = options.credentials
    }

    if (bodyJson) {
      request.body = JSON.stringify(bodyJson)
    }

    // Send fetch request w/ *application/json* content type
    let res
    if (options.timeout) {
      res = await fetchTimeout(url, request, options.timeout)
    } else {
      res = await fetch(url, request)
    }

    // For scenario where request has no response data available
    const text = await res.text()
    try {
      const data = JSON.parse(text)
      return handleFetchResponse(res, data, url)
    } catch (err) {
      return
    }
  } catch (err) {
    // Handle fetch error
    return handleFetchError(err, url)
  }
}

/**
 * Send POST request to fetch JSON data
 * @param {string} url
 * @param {*} bodyJson
 * @param {Map<string, string>} headers
 */
export function postJSON(url, bodyJson, headers, options) {
  headers = {
    ...headers,
    'Content-Type': 'application/json',
  }
  return fetchJSON(url, 'post', bodyJson, headers, options)
}

/**
 * Send GET request to fetch JSON data
 * @param {string} url
 * @param {*} bodyJson
 * @param {Map<string, string>} headers
 */
export function getJSON(url, queryJson, headers, options) {
  url = `${url}?${querybuilder(queryJson)}`
  headers = {
    ...headers,
  }
  return fetchJSON(url, 'get', null, headers, options)
}

/**
 * Send POST request with *FormData* body
 * @param {string} url
 * @param {FormData} bodyForm
 * @param {Map<string, string>} headers
 * @returns {Promise<FetchResponse>}
 */
export async function postForm(url, bodyForm, headers = {}) {
  // Return error if body not *FormData*
  if (!(bodyForm instanceof FormData)) {
    return {
      error: { payload: 'Body must be FormData' },
    }
  }

  try {
    // Send fetch request w/ *FormData* body
    const res = await fetch(url, {
      body: bodyForm,
      headers,
      method: 'post',
    })

    // Extract text data from fetch response
    const data = await res.text()

    // Return fetch response
    return handleFetchResponse(res, data, url, 400)
  } catch (err) {
    // Handle fetch error
    return handleFetchError(err, url)
  }
}
