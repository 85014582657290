/**
 * Profile *Vuex* module state
 */
export default function () {
  return {
    /**
     * Seeker profile
     */
    profile: {},

    /**
     * Access token ready queue,
     * with callbacks to be triggered once profile is ready
     */
    readyQueue: [],
  }
}
