/**
 * Centralised configuration for
 * pages w/ localised page paths.
 * Full paths are required.
 */
export const pageLocales = {
  'employer/bidding': {
    en: '/en/employer/job-ad-booster/',
    'zh-hant': '/employer/提升曝光功能/',
  },
  'employer/bidding-faq': {
    en: '/en/employer/job-ad-booster-faq/',
    'zh-hant': '/employer/提升曝光功能-常見問題/',
  },
  'employer/blogs/list': {
    en: '/en/employer/blogs-list/recruitment-advice/',
    'zh-hant': '/employer/blogs-list/僱主網誌/',
  },
  'employer/community-guidelines': {
    en: '/en/employer/community-guidelines/',
    'zh-hant': '/employer/社群守則/',
  },
  'employer/faq': {
    en: '/en/employer/faq/',
    'zh-hant': '/employer/常見問題/',
  },
  'employer/landing': {
    en: '/en/employer/recruitment-services/',
    'zh-hant': '/employer/recruitment-services/',
  },
  'employer/shortcut': {
    en: '/en/employer/shortcut/',
    'zh-hant': '/employer/僱主常用連結/',
  },
  'employer/solutions': {
    en: '/en/employer/recruitment-solutions/',
    'zh-hant': '/employer/收費招聘方案/',
  },
  'jobseeker/app': {
    en: '/en/jobseeker/mobile-app/',
    'zh-hant': '/jobseeker/手機app/',
  },
  'jobseeker/blogs/list': {
    en: '/en/blogs-list/career-blogs/',
    'zh-hant': '/blogs-list/職場網誌/',
  },
  // 'jobseeker/recruitment-day': {
  //  en: '/en/jobseeker/recruitment-day/',
  //  'zh-hant': '/jobseeker/招聘日/',
  // },
  // 'jobseeker/user-benefit-programme': {
  //  en: '/en/jobseeker/user-benefit-programme/',
  //  'zh-hant': '/jobseeker/用戶優惠/',
  // },
  'press/blogs/list': {
    en: '/en/latest-news/',
    'zh-hant': '/latest-news/',
  },
}
