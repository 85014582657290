export function getFeatureListTitle(titleLocales, currentLocale) {
  const titleLocale = titleLocales.find((locale) => locale && locale.value && locale.locale === currentLocale)
  if (titleLocale) {
    return titleLocale.value
  }
  return ''
}

export function getFeatureListSourceSection(titleLocales) {
  // Always use feature list's english name
  const featureListLocale = titleLocales.find((loc) => loc.locale === 'en')
  if (featureListLocale && featureListLocale.value) {
    return featureListLocale.value
  }
  return ''
}
