import ImpressionListItemMixin from './impressionListItem'

export default {
  mixins: [ImpressionListItemMixin],
  props: {
    /** Formatted tracking params */
    trackingParams: {
      default: () => ({}),
      type: Object,
    },
  },
}
