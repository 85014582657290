export default class Tracker {
  constructor() {
    this._initDone = false
    this._deferInitDone = false
  }

  init() {
    this._initDone = true
  }

  deferInit() {
    this._deferInitDone = true
  }

  sendConversion(id) {}

  sendCustomEvent(eventName, eventVariables) {}

  sendPageViewEvent(route, pageTitle, pageLocation, pagePath) {}

  setUserId(userId) {}

  setAppVersion(version) {}

  setUserProperties(properties) {}
}
