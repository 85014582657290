/**
 * Mixin for profile
 */
import GraphqlFormMixin from './form/graphql'

export default {
  computed: {
    avatar() {
      return this.$store.getters['profile/avatar']
    },

    certs() {
      return this.$store.getters['profile/certs']
    },

    // TODO: This getter will be used from account-restore-page
    deleteAccountAt() {
      return this.$store.getters['profile/deleteAccountAt']
    },

    education() {
      return this.$store.getters['profile/education']
    },

    hasCreatedProfile() {
      return this.$store.getters['profile/hasCreatedProfile']
    },

    messageToEmployer() {
      return this.$store.getters['profile/messageToEmployer']
    },

    name() {
      return this.$store.getters['profile/name']
    },

    profileId() {
      return this.$store.getters['profile/profileId']
    },

    skills() {
      return this.$store.getters['profile/skills']
    },
    spokenSkills() {
      return this.$store.getters['profile/spokenSkills']
    },

    telephone() {
      return this.$store.getters['profile/telephone']
    },

    thumbnail() {
      return this.$store.getters['profile/thumbnail']
    },

    workExperiences() {
      return this.$store.getters['profile/workExperiences']
    },

    writtenSkills() {
      return this.$store.getters['profile/writtenSkills']
    },
  },
  methods: {
    async updateProfile(successCallback) {
      const res = await this.submitGql('updateProfile', true)
      if (res && res.data && res.data.update_profile) {
        const profile = res.data.update_profile
        if (profile) {
          if (this.gqlVariables.profile_picture) {
            setTimeout(() => {
              this.$store.commit('profile/setProfile', profile)
            }, 3000) // Delay before uploaded image is ready on server, in ms
          } else {
            this.$store.commit('profile/setProfile', profile)
          }
        }
        successCallback(profile)
        this.$emit('success')
      }
    },
  },
  mixins: [GraphqlFormMixin],
}
