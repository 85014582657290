/**
 * Auth *Vuex* module state
 */
export default function () {
  return {
    /**
     * Token payload
     */
    payload: null,

    /**
     * Access token ready queue,
     * with callbacks to be triggered once token is ready
     */
    readyQueue: [],

    /**
     * Access token
     */
    token: '',
  }
}
