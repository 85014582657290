<template>
  <div class="jc-logo">
    <img
      v-if="src"
      loading="lazy"
      :height="128"
      :width="128"
      :alt="companyLogoTitle"
      :src="src"
      :title="companyLogoTitle"
    />
  </div>
</template>

<script>
export default {
  computed: {
    companyLogoTitle() {
      // Image alt/title attribute for company logo
      return `${this.name} logo`
    },
  },
  props: {
    name: {
      default: 'Company',
      type: String,
    },
    src: {
      required: true,
      type: String,
    },
  },
}
</script>

<style lang="postcss" scoped>
.jc-logo {
  @apply flex-grow-0 flex-shrink-0 rounded-xl;
  & img {
    @apply rounded-xl;
  }
}
</style>
