<template>
  <horizontal-job-list
    v-bind="this.$props"
    :title="title"
    :to="path"
    :source-section="featureListSourceSection"
    @click="onClickMore"
  />
</template>

<script>
import { getFeatureListSourceSection, getFeatureListTitle } from '../../libs/featureList'
import HorizontalJobList from './horizontalJobList'
import ListMixin from '../../mixins/list'
import { removeEmptyField } from '../../libs/object'
import { sendFeatureListMoreClickEvent } from '../../libs/tracking/utils/event'

export default {
  components: {
    'horizontal-job-list': HorizontalJobList,
  },
  computed: {
    featureListSourceSection() {
      return getFeatureListSourceSection(this.titleLocales)
    },
    path() {
      if (!this.filterQueryObject) {
        // Fallback to feature list by id
        return {
          path: this.localePathName(`/feature/${this.id}`),
        }
      }
      return {
        path: this.localePathName(`/find-jobs`),
        query: removeEmptyField(this.filterQueryObject),
      }
    },
    title() {
      return getFeatureListTitle(this.titleLocales, this.$i18n.locale)
    },
  },
  methods: {
    onClickMore() {
      sendFeatureListMoreClickEvent(this.$eventTracker, {
        listId: this.id,
        listName: this.title,
        totalCount: this.jobs.length,
      })
    },
  },
  mixins: [ListMixin],
  props: {
    filterQueryObject: {
      default: null,
      type: Object,
    },
    id: {
      required: true,
      type: String,
    },
    titleLocales: {
      required: true,
      type: Array,
    },
  },
}
</script>
