<template>
  <div class="app">
    <loading :class="loadingClass" />
    <div id="pageScrollContainer" class="page page--full">
      <app-header @on-search="onTextSearch">
        <span slot="search" class="b advanced__button" @click="showAdvancedSearch">{{ advancedSearchLabel }}</span>
      </app-header>
      <nuxt class="page__main" />
      <app-footer />
    </div>
    <error-box :message="errorMessage" :show="isShowError" :action="$t('general.ok')" @action="dismissError" />
  </div>
</template>

<script>
import AuthMonitorMixin from '../mixins/auth/monitor'
import { createFilterQueryObjectFromRoute } from '../libs/search/filter'
import DefaultMinxin from '../mixins/layout/default'
import ErrorBox from '../components/error/errorBox'
import ErrorBoxMixin from '../mixins/error/errorBox'
import FilterMixin from '../mixins/search/filter'
import { removeEmptyField } from '../libs/object'
import TrackingMixin from '../mixins/layout/tracking'

export default {
  components: {
    'error-box': ErrorBox,
  },
  computed: {
    advancedSearchLabel() {
      if (this.numberOfSelectedFilters) {
        const numberStr = this.numberOfSelectedFilters > 99 ? '99+' : `${this.numberOfSelectedFilters}`
        return `${this.$t('search.advance')} (${numberStr})`
      }
      return this.$t('search.advance')
    },

    filterQueryObject() {
      return createFilterQueryObjectFromRoute(this.$route)
    },
  },
  methods: {
    showAdvancedSearch() {
      // Get the name of the current page,
      // for routing to the advanced search page in that current page.
      // For English version, we have '/en' at the beginning of the fullPath,
      // which needs to be filtered
      // FIXME: We should have a better way to get the name of current page,
      // instead of using hard-code to filter 'en'
      const currentPage = this.$route.fullPath
        .split('/')
        .filter((path) => path)
        .filter((path) => path !== 'en')[0]
      this.$router.push({
        path: this.localePathName(`/${currentPage}/advanced`),
        query: removeEmptyField(this.filterQueryObject),
      })
    },
  },
  mixins: [AuthMonitorMixin, DefaultMinxin, ErrorBoxMixin, FilterMixin, TrackingMixin],
  name: 'DefaultNoStickyLayout',
}
</script>

<style lang="postcss" scoped>
.ftr {
  @apply mt-10;
}
.loading {
  @apply hidden;
}

.app::v-deep {
  & .hdr {
    @apply shadow-none;
    z-index: inherit;
  }
  & .hdr .hrd__menu .m__list {
    @apply z-20;
  }
}
.b.advanced__button {
  @apply shadow-none ml-2 flex-shrink-0;
  @apply bg-white-plain text-grey-900 border-red-50 border;
  @apply h-auto cursor-pointer;
}

@screen desktop {
  .app {
    & .advanced__button {
      @apply hidden;
    }
  }
  .ftr {
    @apply mt-12;
  }
}
</style>
