<template>
  <n-link class="more" event="" :title="_title" :to="path" @click.native="onClick">
    <p class="more__text">{{ _title }}</p>
    <svg-arrow-right class="more__icon" />
  </n-link>
</template>

<script>
import SvgArrowRightIcon from '../../assets/svg/arrow_right.svg?inline'

export default {
  components: {
    'svg-arrow-right': SvgArrowRightIcon,
  },
  computed: {
    _title() {
      return this.title || this.$t('general.more')
    },
  },
  methods: {
    onClick() {
      this.$emit('click')
    },
  },
  props: {
    path: {
      default: () => ({}),
      type: Object,
    },
    title: {
      default: '',
      type: String,
    },
  },
}
</script>

<style lang="postcss" scoped>
.more {
  @apply flex rounded justify-center items-center cursor-pointer no-underline;
  height: 32px;
  padding-left: 20px;
  padding-right: 8px;

  &:hover {
    @apply bg-red-50;
  }
}
.more__text {
  @apply text-right text-cbody2 font-cbody2;
}
.more__icon {
  height: 24px;
  width: 24px;
}

@screen desktop {
  .more {
    height: 44px;
    padding-left: 24px;
  }
  .more__text {
    @apply text-cplain-action1 font-cplain-action1;
  }
  .more__icon {
    height: 44px;
    width: 44px;
  }
}
</style>
