<template>
  <div class="jc-text">
    <h3 class="jc-text__name">{{ jobName }}</h3>
    <p v-if="companyName" class="jc-text__company-name">{{ companyName }}</p>
    <p v-if="districtsLabel" class="jc-text__districts">{{ districtsLabel }}</p>
  </div>
</template>

<script>
export default {
  props: {
    companyName: {
      default: '',
      type: String,
    },
    districtsLabel: {
      default: '',
      type: String,
    },
    jobName: {
      required: true,
      type: String,
    },
  },
}
</script>

<style lang="postcss" scoped>
.jc-text {
  @apply flex-1 min-w-0;
}
.jc-text__name {
  @apply text-17/4 font-bold truncate;
}
.jc-text__company-name {
  @apply text-grey-medium truncate;
}
.jc-text__districts {
  @apply truncate;
}
</style>
