<template>
  <nav class="emp-menu">
    <ol class="emp-menu__items">
      <template v-for="item in menuItems">
        <li v-if="item && item.title" :key="`EmployerMenuItem-${item.title}`" class="emp-menu__item">
          <b-nav :href="item.href" :is-new="item.isNew" :link-path="item.linkPath" :title="item.title" />
        </li>
      </template>
    </ol>
  </nav>
</template>

<script>
import EmployerNavMixin from '../../mixins/employer/nav'
import NavButton from '../button/navButton'

export default {
  components: {
    'b-nav': NavButton,
  },
  computed: {
    menuItems() {
      return [
        {
          linkPath: this.getArticleLocalePath('employer/landing'),
          title: this.$t('employer.homepage'),
        },
        ...this.pageItems,
        ...this.actionItems,
      ]
    },
  },
  mixins: [EmployerNavMixin],
}
</script>

<style lang="postcss" scoped>
.emp-menu {
  @apply bg-grey-bg;
}
.emp-menu__items {
  @apply w-full py-3;

  & .b.b-nav {
    @apply px-6;
  }
  & .b.b-nav,
  & .b.b-nav:after {
    @apply rounded-none;
  }
}
</style>
