/**
 * Schedule callback execution when browser is idle,
 * ref: https://developer.mozilla.org/en-US/docs/Web/API/Window/requestIdleCallback
 * @param {Function} callback
 */
export function requestIdleCallback(callback) {
  if (callback instanceof Function) {
    if (typeof window !== 'undefined' && window.requestIdleCallback instanceof Function) {
      window.requestIdleCallback(callback)
    } else {
      // If requestIdleCallback API not available,
      // fallback to fixed timeout
      setTimeout(callback, 500)
    }
  }
}
