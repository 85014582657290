import _get from 'lodash/get'
import { addTrailingSlash } from '../libs/metadata'
import { pageLocales } from '../locales/pages'

export default {
  computed: {
    /**
     * Links to locales other than current one
     * @returns {Array}
     */
    altLocaleLinks() {
      return (
        this.altLocales
          // Map each alternate locale into link
          .map(({ code, name, nameShort } = {}) => {
            const link = {
              title: name,
              titleShort: nameShort,
            }
            if (this.currentPageLocalePaths) {
              // If localised path mapping available,
              // try to find link path
              // for provided locale code
              if (this.currentPageLocalePaths[code]) {
                link.linkPath = this.currentPageLocalePaths[code]
                link.linkQuery = this.$route.query
              }
              // If link path not available for locale code,
              // i.e. no localised path for this locale,
              // just leave it empty
            } else {
              // Otherwise use default i18n logic
              // to switch locale prefix in path
              link.linkPath = this.switchLocalePath(code)
            }

            return link
          })
          // Filter out link w/o localised path
          .filter((link) => link.linkPath)
      )
    },

    /**
     * Locales other than current one
     * @returns {Array}
     */
    altLocales() {
      return this.$i18n.locales.filter((locale) => locale && locale.code !== this.$i18n.locale)
    },

    /**
     * Current locale config
     */
    currentLocale() {
      return this.$i18n.locales.find((locale) => locale && locale.code === this.$i18n.locale)
    },

    /**
     * Localised path mapping
     * of current page
     * @returns {*}
     */
    currentPageLocalePaths() {
      return this.$store.getters['locale/getPageLocalePaths'](this.$route.path)
    },
  },
  methods: {
    /**
     * Translate with fallback:
     * translate if *prefix + key* keypath exists,
     * otherwise return key
     * @param {string} prefix Locale keypath prefix
     * @param {string} key Locale keypath key
     * @returns {string}
     */
    $tf(prefix, key) {
      const keypath = `${prefix}['${key}']`
      if (this.$te(keypath)) {
        return this.$t(keypath)
      }
      return key
    },

    /**
     * Wrapper function for getting
     * article page locale path
     * using page name (e.g. employer/landing, employer/bidding)
     * @param {string} name
     * @param {string} locale
     * @returns {string}
     */
    getArticleLocalePath(name = '', locale) {
      const path = _get(pageLocales, `${name}.${locale || this.$i18n.locale}`)
      if (path) {
        return path
      } else if (process.env.SEEKER_WEB__ENVIRONMENT !== 'production') {
        throw new Error('Wrong page name! Please refer to /locales/pages.js')
      }

      return ''
    },

    /**
     * Wrapper function for i18n *localePath()*,
     * accepting string paths
     * @param {string} path
     * @param {string} locale
     */
    localePathName(path = '', locale) {
      return this.localePath(
        {
          // Enforce trailing slash in all routes,
          // ref: https://hreflang.org/trailing-slashes-in-urls-seo-implications/
          path: addTrailingSlash(path),
        },
        locale,
      )
    },
  },
}
