/**
 * Function for matching
 * selected and list items by ID
 */
export function matchItemByItemShortCode(a, b) {
  return a && b && a === b.shortCode
}

export function matchItemByValue(a, b) {
  return a && b && a.category === b.category && a.name === b.name
}

/**
 * Clean string for use in URL
 * @param {string} string
 * @returns {string}
 */
export function cleanStringForURL(string) {
  if (string) {
    return string
      .replace(/[\s,&、/-]+/g, '-') // Replace all whitespaces and separators w/ "-"
      .toLowerCase() // Convert to lowercase
  }
  return ''
}
