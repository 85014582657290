import GtagTracker from './gtag'

/**
 * Google Analytics 4 tracker,
 * ref: https://developers.google.com/analytics/devguides/collection/ga4
 */
export default class ExternalGA4Tracker extends GtagTracker {
  constructor(gtag, id) {
    super(gtag, id)

    // Initial config
    this._config = {}
  }

  deferInit() {
    // Only initialize once
    if (!this._deferInitDone) {
      super.deferInit()

      // Trigger tracker initialisation
      // by setting config for the first time
      this.setConfig(this._config)
    }
  }

  /**
   * Set config params
   * @param {*} config
   */
  setConfig(config = {}) {
    this._gtag('config', this._id, config)
  }
}
