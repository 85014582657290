/**
 * Loading *Vuex* module getter
 */
export default {
  errorMessage(state) {
    return state.errMsg
  },
  isShowError(state) {
    return state.isShowError
  },
}
