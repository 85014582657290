/**
 * NuxtJS middleware to redirect user to login page
 * when not logged in yet
 */
export default function ({ app, redirect, route, store }) {
  // Whether user is logged in
  const isLoggedIn = store.getters['auth/isLoggedIn']

  if (!isLoggedIn) {
    // Redirect to login page
    // localePath() injected from i18n
    redirect(
      app.localePath({
        path: '/login/',
        query: { target: route.fullPath },
      }),
    )
  }
}
