import { ResizeObserver } from '@juggle/resize-observer'

export default function ({ store }, inject) {
  if (process.client) {
    const ro = new ResizeObserver((entries, observer) => {
      const value = entries[0].contentRect.width <= 959
      store.commit('device/updateDevice', value)
    })

    ro.observe(document.body) // Watch dimension changes on body
  }
}
