export default {
  computed: {
    companyAbout() {
      return this.company.about || this.$t('jobDetail.emptyAboutCompany')
    },
    companyLogo() {
      return this.company.company_logo_thumbnail || ''
    },
    companyName() {
      return this.company.name || ''
    },
  },
  props: {
    company: {
      required: true,
      type: Object,
    },
  },
}
