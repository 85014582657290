export default {
  computed: {
    /**
     * Button component classes
     */
    buttonClass() {
      let classes = this.isLink ? 'b--link' : ''
      if (!this.active || this.isLoading) {
        classes += `${classes.length ? ' ' : ''}b--inactive`
      } else if (this.active) {
        classes += `${classes.length ? ' ' : ''}b--active`
      }
      return classes
    },

    /**
     * Button component name
     */
    buttonComponent() {
      if (this.linkPath) {
        return 'n-link'
      } else if (this.href) {
        return 'a'
      }
      return 'button'
    },

    /**
     * Props for binding
     */
    buttonProps() {
      const props = {
        class: this.buttonClass,
        title: this.title,
      }
      if (this.linkPath) {
        // Button-like internal link
        props.to = {
          path: this.linkPath,
          query: this.linkQuery,
        }
        // Hack the nuxt-link to prevent firing default click event
        // https://github.com/vuejs/vue-router/blob/dev/src/components/link.js
        // https://stackoverflow.com/questions/59885664/nuxt-how-to-prevent-nuxt-link-goes-to-another-page
        props.event = ''
      } else if (this.href) {
        // Button-like external link
        props.href = this.href
        props.target = this.target
        if (this.target === '_blank') {
          props.rel = 'noopener'
        }
      } else {
        // Button
        props.type = this.type
      }

      return props
    },

    /**
     * Whether button acts as a link
     */
    isLink() {
      return !!(this.linkPath || this.href)
    },
  },
  methods: {
    /**
     * Emit click event
     * if button is active and not a link
     */
    onClick() {
      if (this.active && !this.isLink) {
        this.$emit('click')
      }
    },

    /**
     * Emit click event when click an internal redirection link
     */
    onLinkClick() {
      if (this.active) {
        if (this.linkPath) {
          this.$emit('click')
          this.$router.push({
            path: this.linkPath,
            query: this.linkQuery,
          })
        }
      }
    },
  },
  props: {
    /**
     * Button active state
     */
    active: {
      default: true,
      type: Boolean,
    },

    /**
     * External path for button-like link
     */
    href: {
      default: '',
      type: String,
    },

    /**
     * Button action executed state
     */
    isLoading: {
      default: false,
      type: Boolean,
    },

    /**
     * Route path for button-like link
     */
    linkPath: {
      default: '',
      type: String,
    },

    /**
     * Route query for button-like link
     */
    linkQuery: {
      default: () => {},
      type: Object,
    },

    /**
     * External link target
     */
    target: {
      default: '_blank',
      type: [String, Boolean],
    },

    /**
     * Button title
     */
    title: {
      required: true,
      type: String,
    },

    /**
     * Button element type
     */
    type: {
      default: 'button',
      type: String,
    },
  },
}
