<template>
  <div class="err-box" :class="errorClass">
    <span class="err-box__title">
      {{ message }}
    </span>
    <div class="err-box__action">
      <b-opt class="b--full" :title="action" @click="onAction" />
    </div>
  </div>
</template>

<script>
import OptionalButton from '../button/optionalButton'

export default {
  components: {
    'b-opt': OptionalButton,
  },
  computed: {
    errorClass() {
      return {
        'err-box--disable': !this.show,
      }
    },
  },
  methods: {
    onAction() {
      this.$emit('action')
    },
  },
  props: {
    action: {
      default: '',
      type: String,
    },
    message: {
      default: '',
      type: String,
    },
    show: {
      default: false,
      type: Boolean,
    },
  },
}
</script>

<style lang="postcss" scoped>
.err-box {
  @apply flex flex-row bg-grey-900 rounded-lg z-10 items-center opacity-80;
  @apply fixed bottom-0 p-4 mb-4;
  left: 16px;
  bottom: 0;
  transition:
    transform 0.4s,
    opacity 0.4s linear;
  &.err-box--disable {
    @apply pointer-events-none;
    @apply opacity-0;
    transform: translateY(20px);
  }
}
.err-box__title {
  @apply flex-1 text-white-plain text-cbody2 font-cbody2;
}
.err-box__action {
  @apply text-indigo text-cbody2 font-cbody2;
}
.err-box__action >>> .b__title {
  @apply text-white-plain;
}
@screen mobile {
  .err-box {
    right: 16px;
  }
}
@screen desktop {
  .err-box {
    min-width: 392px;
  }
}
</style>
