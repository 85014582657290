/**
 * Map HK districts to administrative regions,
 * ref: https://en.wikipedia.org/wiki/Districts_of_Hong_Kong
 */
export const mapHKAdminRegion = {
  Aberdeen: 'Hong Kong Island',
  Central: 'Hong Kong Island',
  'DBay & Disney': 'New Territories',
  'Fanling & Sheung Shui': 'New Territories',
  Hunghom: 'Kowloon',
  'Island East': 'Hong Kong Island',
  'Island South': 'Hong Kong Island',
  'Island West': 'Hong Kong Island',
  'Kowloon Bay': 'Kowloon',
  'Kowloon City': 'Kowloon',
  'Kowloon Tong': 'Kowloon',
  'Kwai Chung': 'New Territories',
  'Kwun Tong': 'Kowloon',
  Lantau: 'New Territories',
  'Ma On Shan': 'New Territories',
  'Mei Foo': 'Kowloon',
  'Mid-Levels & Peak': 'Hong Kong Island',
  'New Territories East': 'New Territories',
  'New Territories West': 'New Territories',
  'Pok Fu Lam': 'Hong Kong Island',
  'Sai Kung': 'New Territories',
  'Sham Shui Po': 'Kowloon',
  Shatin: 'New Territories',
  'Tai Po': 'New Territories',
  'Tin Shui Wai': 'New Territories',
  'Tseung Kwan O': 'New Territories',
  'Tsing Yi': 'New Territories',
  'Tsuen Wan': 'New Territories',
  'Tuen Mun': 'New Territories',
  'Tung Chung and Airport': 'New Territories',
  'Wan Chai / Causeway Bay': 'Hong Kong Island',
  'West Kowloon': 'Kowloon',
  'Wong Tai Sin': 'Kowloon',
  'Yau Tsim Mong': 'Kowloon',
  'Yuen Long': 'New Territories',
}

/**
 * Check if provided latitude and longitude pair is valid,
 * ref: https://stackoverflow.com/a/16743805
 * @param {number} latitude
 * @param {number} longitude
 */
export function isLatLngValid(latitude, longitude) {
  return (
    Number.isFinite(latitude) &&
    latitude >= -90 &&
    latitude <= 90 &&
    Number.isFinite(longitude) &&
    longitude >= -180 &&
    longitude <= 180
  )
}
