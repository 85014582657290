export function transformValueToArray(value) {
  return Array.isArray(value) ? value : value ? [value] : []
}

export function interleave(original, seasoning) {
  const ori = original === null ? [] : original
  const sea = seasoning === null ? [] : seasoning

  const result = []
  let i = 0

  // special logic, seasoning will come first
  if (ori.length > 0 && sea.length > 0) {
    if (Array.isArray(sea[0])) {
      result.push(...sea[0])
    } else {
      result.push(sea[0])
    }
  }

  while (i < ori.length) {
    result.push(...ori[i])

    if (i + 1 < sea.length) {
      result.push(...sea[i + 1])
    }

    i++
  }

  return result
}

export function sliceByInt(originalList, slicers, repeatedly = false) {
  const output = []
  let start = 0
  let i = 0
  // eslint-disable-next-line no-unmodified-loop-condition
  while (i < slicers.length || repeatedly) {
    if (start >= originalList.length) {
      break
    }
    const slicer = slicers[Math.min(i, slicers.length - 1)]
    output.push(originalList.slice(start, Math.min(start + slicer, originalList.length)))
    start += slicer
    i++
  }
  return output
}
