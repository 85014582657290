/**
 * Mixin for logging out user
 */
export default {
  data() {
    return {
      /**
       * Delay for showing UI alert about
       * force-logging out user, in ms
       */
      forceLogoutDelay: 2000,
    }
  },
  methods: {
    /**
     * Submit logout request to Octo *REST* endpoint,
     * clear token and then redirect to login page
     */
    async logout() {
      // Send logout request to Octo
      // and unregister the device token
      await this.$store.dispatch('auth/logout')

      // Redirect to home page
      this.$router.push(this.localePathName('/'))
    },
    /**
     * Submit create Anonymous request to Octo *REST* endpoint,
     * clear token and then redirect to login page
     */
    async switchToAnonymousUser() {
      // Send logout request to Octo
      await this.$store.dispatch('auth/switchToAnonymousUser')

      // Redirect to home page
      this.$router.push(this.localePathName('/'))
    },
  },
}
