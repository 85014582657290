/**
 * Notification *Vuex* module state
 */
export default function () {
  return {
    /**
     * Notification Indicator state
     */
    isShowIndicator: false,
  }
}
