import { formatLocalePaths } from '../../libs/locale'

/**
 * Localisation Vuex module mutations
 */
export default {
  /**
   * Set localised path mapping for provided page
   * @param {*} state
   * @param {Dictionary<string>} localePaths
   */
  setPageLocalePaths(state, localePaths = {}) {
    if (Object.keys(localePaths).length) {
      // Format localised paths
      const formattedLocalePaths = formatLocalePaths(localePaths)

      // Clone state property to maintain reactivity,
      // ref: https://vuex.vuejs.org/guide/mutations.html#mutations-follow-vue-s-reactivity-rules
      const pageLocalePathsMap = { ...state.pageLocalePaths }

      // For each localised path,
      // add path mapping to state
      Object.values(formattedLocalePaths).forEach((localePath) => {
        pageLocalePathsMap[localePath] = formattedLocalePaths
      })

      // Set state w/ updated mapping
      state.pageLocalePaths = pageLocalePathsMap
    }
  },
}
