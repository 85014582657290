/**
 * Profile *Vuex* module mutations
 */
export default {
  /**
   * Add callback function to ready queue
   * @param {Function} callback
   */
  addReadyCallback(state, callback) {
    if (typeof callback === 'function') {
      state.readyQueue.push(callback)
    }
  },

  /**
   * Clear user profile from state
   */
  clearProfile(state) {
    state.profile = {}
  },

  /**
   * Clear ready queue
   */
  clearReadyQueue(state) {
    state.readyQueue = []
  },

  /**
   * Set seeker profile
   */
  setProfile(state, profile) {
    state.profile = profile
  },
}
