<template>
  <component
    :is="buttonComponent"
    tabindex="0"
    class="app-link"
    v-bind="buttonProps"
    @click="onClick"
    @click.native="onLinkClick"
    >{{ title }}</component
  >
</template>

<script>
import ButtonMixin from '../mixins/button'

export default {
  methods: {
    onClick() {
      this.$emit('click')
    },
  },
  mixins: [ButtonMixin],
}
</script>

<style lang="postcss" scoped>
.app-link {
  @apply outline-none text-cbody2 font-cbody2;
}
.app-link:hover {
  @apply underline;
}
</style>
