/**
 * Profile *Vuex* module getters
 */
export default {
  avatar(state) {
    const profile = state.profile
    if (profile) {
      return profile.profile_picture || ''
    }
    return ''
  },

  certs(state) {
    const profile = state.profile
    if (profile) {
      return profile.cert || []
    }
    return []
  },

  deleteAccountAt(state) {
    const profile = state.profile
    if (profile) {
      return profile.delete_account_at || null
    }
    return null
  },

  education(state) {
    const profile = state.profile
    if (profile && profile.education) {
      return profile.education.name || ''
    }
    return ''
  },

  hasCreatedProfile(state) {
    const profile = state.profile
    return (
      profile &&
      !!profile._id &&
      profile.spoken_skill &&
      profile.spoken_skill.length &&
      profile.written_skill &&
      profile.written_skill.length &&
      !!profile.education
    )
  },

  hasProfileImage(state) {
    const profile = state.profile
    return !!(profile.profile_picture && profile.profile_picture_thumbnail && profile.profile_picture_medium)
  },

  messageToEmployer(state) {
    const profile = state.profile
    if (profile) {
      return profile.message_to_employer || ''
    }
    return ''
  },

  name(state) {
    const profile = state.profile
    if (profile) {
      return profile.name || ''
    }
    return ''
  },

  profileId(state) {
    const profile = state.profile
    if (profile) {
      return profile._id || ''
    }
    return ''
  },

  skills(state) {
    const profile = state.profile
    if (profile && profile.skill) {
      return profile.skill || []
    }
    return []
  },

  spokenSkills(state) {
    const profile = state.profile
    if (profile && profile.spoken_skill) {
      return profile.spoken_skill || []
    }
    return []
  },

  telephone(state) {
    const profile = state.profile
    if (profile) {
      return profile.telephone || ''
    }
    return ''
  },

  thumbnail(state) {
    const profile = state.profile
    if (profile) {
      return profile.profile_picture_thumbnail || ''
    }
    return ''
  },

  workExperiences(state) {
    const profile = state.profile
    if (profile && profile.work_experience) {
      return profile.work_experience || []
    }
    return []
  },

  writtenSkills(state) {
    const profile = state.profile
    if (profile && profile.written_skill) {
      return profile.written_skill || []
    }
    return []
  },
}
