<template>
  <section class="list list__horizontal">
    <div class="list__header">
      <h2 v-if="title" class="list__header-title">{{ title }}</h2>
      <more-btn v-if="to" class="list__header-more" :path="to" @click="onClickMore" />
    </div>
    <div class="list__jobs">
      <template v-for="(job, i) in jobs">
        <div v-if="job && job._id" :key="`HorizontalListJob${id}-${job._id}`" class="list__job">
          <job-ad-card
            v-if="job.is_ad"
            :company="job.company"
            :job="job"
            :observer="observerObj"
            :is-interact="isInteraction(job._id)"
            :tracking-params="trackingParams"
            :session-id="biddingSession"
            :request-id="requestId"
            :bidding-id="biddingId"
            :source="source"
            :source-section="sourceSection"
            :position="i"
          />
          <job-card
            v-else
            :company="job.company"
            :job="job"
            :observer="observerObj"
            :is-interact="isInteraction(job._id)"
            :tracking-params="trackingParams"
            :session-id="sessionId"
            :source="source"
            :source-section="sourceSection"
            :position="i"
          />
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import ListMixin from '../../mixins/list'
import MoreButton from './moreButton'

export default {
  components: {
    'more-btn': MoreButton,
  },
  methods: {
    onClickMore() {
      this.$emit('click')
      if (this.to) {
        this.$router.push(this.to)
      }
    },
  },
  mixins: [ListMixin],
  props: {
    id: {
      default: '',
      type: String,
    },
    sourceSection: {
      default: '',
      type: String,
    },
    title: {
      default: '',
      type: String,
    },
    to: {
      default: null,
      type: Object,
    },
  },
}
</script>

<style lang="postcss" scoped>
.list__header {
  @apply flex justify-center items-center mb-1;
}
.list__header-title {
  @apply flex-grow;
}
.list__header-more {
  @apply flex-shrink-0;
}

@screen mobile {
  .list {
    @apply mb-2;
  }
  .list__jobs {
    @apply flex flex-no-wrap overflow-x-auto;
    /* Disable scroll snapping until browser has better support */
    /* scroll-snap-type: x proximity; */
  }
  .list__job {
    @apply flex-grow-0 flex-shrink-0 max-w-card pt-2;
    width: calc(100vw - 2rem);
    box-sizing: content-box;
    /* scroll-snap-align: center; */
    /* scroll-snap-stop: always; */
    /* scroll-margin-left: 4px; */
  }
  .list__job:not(:first-child) {
    @apply pl-1;
  }
}

@screen desktop {
  .list {
    @apply mb-11;
  }
}
</style>
