import { render, staticRenderFns } from "./appFooterCountry.vue?vue&type=template&id=2881c860&scoped=true&"
import script from "./appFooterCountry.vue?vue&type=script&lang=js&"
export * from "./appFooterCountry.vue?vue&type=script&lang=js&"
import style0 from "./appFooterCountry.vue?vue&type=style&index=0&id=2881c860&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2881c860",
  null
  
)

export default component.exports