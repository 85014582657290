<template>
  <section class="load">
    <div class="load__bg" />
    <div class="load__main">
      <div class="load__loading-container">
        <svg-loading-bg class="load__load-bg" />
      </div>
    </div>
  </section>
</template>

<script>
import SvgLoadingBgIcon from '../assets/svg/loading.svg?inline'

export default {
  components: {
    'svg-loading-bg': SvgLoadingBgIcon,
  },
}
</script>

<style lang="postcss" scoped>
.load__loading-container >>> .load__load-bg {
  stroke-width: 10px;
}
.load {
  @apply sticky top-0 z-20 w-screen h-screen;
}
.load__bg {
  @apply w-full h-full bg-grey-bg;
  opacity: 0.8;
}
.load__main {
  @apply absolute top-0 flex w-full h-full items-center justify-center;
}
.load__loading-container {
  @apply absolute;
  height: 70px;
  width: 70px;
}
.load__load-bg {
  @apply absolute;
  animation: rotate 1.5s linear infinite;
}
@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
</style>
