import { decodeToken, LS_TOKEN_KEY } from '../libs/token'
import { getLocalStorage, removeLocalStorage } from '../libs/localStorage'

/**
 * Restore access token from *local storage*,
 * and set to *Vuex* store,
 * returns *true* if successful
 */
const restoreAndSetToken = async (store) => {
  // Try to get token from *local storage*
  const token = getLocalStorage(LS_TOKEN_KEY)

  // If token found,
  // try to decode it
  if (token) {
    const payload = decodeToken(token)
    if (payload) {
      // If token is valid, set token to *Vuex* store,
      // then return *true*
      await store.commit('auth/setTokenAndPayload', {
        payload,
        token,
      })
      return true
    } else {
      // Otherwise, remove invalid token from *local storage*
      removeLocalStorage(LS_TOKEN_KEY)
    }
  }

  return false
}

/**
 * Try to restore access token from *local storage*
 * If failed, fetch new one from *Octo*
 */
const restoreOrFetchToken = async (store) => {
  // Try to restore and set access token
  const isTokenRestored = await restoreAndSetToken(store)

  // If token not restored,
  // fetch new one from Octo
  if (!isTokenRestored) {
    // isAnonymousUser
    store.dispatch('auth/signinWithAnonymousUser')
  } else if (store.getters['auth/isLoggedIn']) {
    store.dispatch('auth/resfreshTokenAndGetProfile')
  }
}

/**
 * NuxtJS plugin to setup access token on client side
 */
export default async function ({ store }, inject) {
  // Inject global function for
  // adding callback to access token ready queue
  // only on client side
  inject('tokenReady', (callback) => {
    if (process.client) {
      store.dispatch('auth/whenTokenReady', callback)
    }
  })

  // On client side,
  // try to restore or fetch new access token
  if (process.client) {
    await restoreOrFetchToken(store)
  }
}
