import { captureException, configureScope } from '@sentry/browser'

export default {
  methods: {
    beforeDestroy() {
      if (this.observer.value && typeof this.observer.value.observe === 'function' && this.$el) {
        this.observer.value.unobserve(this.$el)
      }
    },
    fireImpression() {
      if (this.isInteract && this.isFireTrackingEvent) {
        this.onImpressionEvent()
      }
    },
    monitorImpression() {
      if (this.isFireTrackingEvent) {
        this.$nextTick(() => {
          setTimeout(() => {
            if (this.observer.value && typeof this.observer.value.observe === 'function') {
              // If intersection observer available,
              // observe list item element
              this.observer.value.observe(this.$el)
            } else {
              // Otherwise send exception to sentry
              // w/ item source and position
              configureScope((scope) => {
                scope.setExtra('item-source', this.source)
                scope.setExtra('item-position', this.position)
              })
              captureException(new Error('IntersectionObserver not created'))
            }
          }, 200)
        })
      }
    },
  },
  mounted() {
    this.monitorImpression()
    this.fireImpression()
  },
  props: {
    isFireTrackingEvent: {
      default: true,
      type: Boolean,
    },
    isInteract: {
      default: false,
      type: Boolean,
    },
    observer: {
      default: () => ({}),
      type: Object,
    },
    position: {
      required: true,
      type: Number,
    },
    sessionId: {
      default: '',
      type: String,
    },
    source: {
      default: '',
      type: String,
    },
  },
  watch: {
    isInteract(to, from) {
      if (this.isInteract && this.isFireTrackingEvent) {
        this.onImpressionEvent()
      }
    },
  },
}
