<template>
  <div class="app" :class="showMenu ? 'app--show-menu' : ''">
    <!-- Page content -->
    <div id="pageScrollContainer" class="page page--full">
      <emp-header @menu-toggle="toggleMenu" />
      <nuxt class="page__main" />
      <app-footer referral-source="employer" />
    </div>
    <!-- Nav menu -->
    <emp-menu class="page page--full" :class="showMenu ? 'emp-menu--show' : ''" />
    <!-- Nav menu close overlay -->
    <div class="page__menu-overlay" @click="closeMenu" />
  </div>
</template>

<script>
import { getPageMetadata, getPageUrlTags } from '../libs/metadata'
import AppFooter from '../components/footer/appFooter'
import EmployerHeader from '../components/header/employer/employerHeader'
import EmployerMenu from '../components/menu/employerMenu'
import TrackingMixin from '../mixins/layout/tracking'

export default {
  components: {
    'app-footer': AppFooter,
    'emp-header': EmployerHeader,
    'emp-menu': EmployerMenu,
  },
  computed: {
    /** Default title and description tags */
    defaultTags() {
      return getPageMetadata(this.$t('metadata.title'), this.$t('metadata.description'))
    },

    /** Page URL <link> and <meta> tags */
    urlTags() {
      return getPageUrlTags(
        // Base URL
        process.env.SEEKER_WEB__BASE_URL,
        // Current locale path
        this.$route.path,
        // Default locale path
        this.switchLocalePath(this.$i18n.defaultLocale),
      )
    },
  },
  data() {
    return {
      /** Whether to show nav menu */
      showMenu: false,
    }
  },
  head() {
    // Generate localization metadata
    const i18nTags = this.$nuxtI18nSeo()

    return {
      htmlAttrs: i18nTags.htmlAttrs,
      link: this.urlTags.link,
      meta: [...this.defaultTags.meta, ...this.urlTags.meta, ...i18nTags.meta],
      title: this.defaultTags.title,
    }
  },
  methods: {
    /**
     * Show nav menu
     */
    closeMenu() {
      this.showMenu = false
    },

    /**
     * Toggle show nav menu
     */
    toggleMenu() {
      this.showMenu = !this.showMenu
    },
  },
  mixins: [TrackingMixin],
  name: 'EmployerLayout',
  watch: {
    /**
     * Close nav menu on route change
     */
    $route() {
      this.showMenu = false
    },
  },
}
</script>

<style lang="postcss" scoped>
.app {
  @apply bg-white;
}
.emp-hdr {
  @apply sticky top-0 z-10;
}

@screen mobile {
  .page {
    transition: transform 0.3s ease;
  }
  .page.emp-menu {
    @apply fixed top-0 z-30;
    right: 100%;
    width: 80vw;
  }
  .page__menu-overlay {
    @apply fixed inset-0 z-20;
    @apply bg-black-plain cursor-pointer;
    transition: opacity 0.3s ease;
  }

  /** Menu shown */
  .app.app--show-menu {
    & .page {
      transform: translateX(80vw);
    }
    & .page__menu-overlay {
      opacity: 0.35;
    }
  }
  /** Menu hidden */
  .app:not(.app--show-menu) {
    & .page.emp-menu {
      @apply pointer-events-none;
    }
    & .page__menu-overlay {
      @apply opacity-0 pointer-events-none;
    }
  }
}

@screen desktop {
  .page.emp-menu,
  .page__menu-overlay {
    @apply hidden;
  }
}
</style>
