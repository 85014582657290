import {
  employmentTypes,
  FullTime,
  getEmploymentTypeLocaleKey,
  PartTime,
  Temp,
} from '../../libs/mapping/employmentType'
import { getDistrictLocaleKey, getDistrictRegionLocaleKey, getRegionDistrictGroups } from '../../libs/mapping/district'
import {
  getJobCategoryLocaleKey,
  getJobCategoryTypeGroups,
  getJobTypeLocaleKey,
  Others,
} from '../../libs/mapping/jobType'
import _includes from 'lodash/includes'
import { removeEmptyField } from '../../libs/object'

export default {
  computed: {
    /**
     * Graphql variables
     */
    gqlVariables() {
      const queryObject = this.filterQueryObject
      return {
        company: queryObject.company,
        district: queryObject.district,
        employment: queryObject.employment,
        fromWorkingDaysPerWeek: queryObject.fromWorkingDaysPerWeek,
        fromWorkingHoursPerDay: queryObject.fromWorkingHoursPerDay,
        hourlyRate: queryObject.hourlyRate,
        jobType: queryObject.jobType,
        latlng:
          queryObject.latitude && queryObject.longitude
            ? { lat: queryObject.latitude, lng: queryObject.longitude }
            : null,
        limit: this.limit,
        monthlyRate: queryObject.monthlyRate,
        offset: this.offset,
        term: queryObject.q,
        workingHour: queryObject.workingDay
          ? [...queryObject.workingHour, queryObject.workingDay]
          : queryObject.workingHour,
      }
    },

    isShowHourSalary() {
      const employment = this.filterQueryObject.employment
      return Array.isArray(employment) && (_includes(employment, PartTime) || _includes(employment, Temp))
    },

    isShowMonthlySalary() {
      const employment = this.filterQueryObject.employment
      return Array.isArray(employment) && _includes(employment, FullTime)
    },

    numberOfSelectedFilters() {
      const filterObject = this.filterQueryObject
      let number = 0
      number += Array.isArray(filterObject.employment) ? filterObject.employment.length : 0
      number += Array.isArray(filterObject.district) ? filterObject.district.length : 0
      number += Array.isArray(filterObject.jobType) ? filterObject.jobType.length : 0
      number += Array.isArray(filterObject.workingHour) ? filterObject.workingHour.length : 0

      const keys = [
        'fromWorkingDaysPerWeek',
        'fromWorkingHoursPerDay',
        'hourlyRate',
        'latitude',
        'monthlyRate',
        'workingDay',
      ]
      keys.forEach((key) => {
        if (filterObject[key]) {
          number += 1
        }
      })
      return number
    },

    /**
     * District filter options
     * @returns {[{items: [{title: string, value: *}], title: string}]}
     */
    optionDistrictList() {
      // Get list of regions w/ districts
      return getRegionDistrictGroups().map(({ districts, region }) => ({
        // Map districts into list items
        items: districts.map((district) => ({
          title: this.$t(getDistrictLocaleKey(district)),
          value: district,
        })),
        title: this.$t(getDistrictRegionLocaleKey(region)),
      }))
    },

    /**
     * Employment filter options
     * @returns {[{title: string, value: string}]}
     */
    optionEmploymentTypeList() {
      return employmentTypes.map(({ name }) => ({
        title: this.$t(getEmploymentTypeLocaleKey(name)),
        value: name,
      }))
    },

    optionHourSalaryList() {
      return [
        {
          title: '$50+',
          value: 50,
        },
        {
          title: '$55+',
          value: 55,
        },
        {
          title: '$60+',
          value: 60,
        },
        {
          title: '$70+',
          value: 70,
        },
      ]
    },

    /**
     * Job type filter options
     * @returns {[{items: [{isSubtitle: boolean, title: string, value: *}], title: string}]}
     */
    optionJobTypeList() {
      // Get list of job categories w/ job types
      return (
        getJobCategoryTypeGroups()
          // Filter out "Others" category
          .filter(({ category }) => category !== Others)
          .map(({ category, jobTypes }) => ({
            // Map job types into list items
            items: jobTypes.map((jobType) => ({
              isSubtitle: !!jobType.isSubtitle,
              title: this.$t(getJobTypeLocaleKey(jobType)),
              value: {
                ...jobType,
                category,
              },
            })),
            title: this.$t(getJobCategoryLocaleKey(category)),
          }))
      )
    },

    optionMonthlySalaryList() {
      return [
        {
          title: '$12K+',
          value: 12000,
        },
        {
          title: '$15K+',
          value: 15000,
        },
        {
          title: '$17K+',
          value: 17000,
        },
        {
          title: '$20K+',
          value: 20000,
        },
      ]
    },

    optionWorkingDayList() {
      return [
        {
          title: this.$t('search.filterTimeWeekdays'),
          value: 'Weekday',
        },
        {
          title: this.$t('search.filterTimeWeekend'),
          value: 'Weekend',
        },
      ]
    },

    optionWorkingHoursList() {
      return [
        {
          icon: 'svg-day-time',
          title: this.$t('search.filterDayTime'),
          value: 'DayTime',
        },
        {
          icon: 'svg-afternoon',
          title: this.$t('search.filterAfternoon'),
          value: 'Afternoon',
        },
        {
          icon: 'svg-evening',
          title: this.$t('search.filterEvening'),
          value: 'NightTime',
        },
        {
          icon: 'svg-overnight',
          title: this.$t('search.filterOvernight'),
          value: 'Overnight',
        },
      ]
    },

    salaryTitle() {
      if (this.isShowMonthlySalary) {
        return this.$t('search.filterSalaryMonthlyRate')
      } else if (this.isShowHourSalary) {
        const employment = this.filterQueryObject.employment
        return _includes(employment, PartTime)
          ? this.$t('search.filterSalaryPartTimeHourlyRate')
          : this.$t('search.filterSalaryTempHourlyRate')
      }
      return ''
    },
  },
  methods: {
    /**
     * Reset filter in route's query
     * @param {string} path - Url path
     * @param {object} query - Url query
     * @param {string} routerFun - router method
     */
    resetFilters(path, query = {}, routerFun = 'push') {
      this.$router[routerFun]({
        path,
        query,
      })
    },

    updateFilter(queryKey, value, path, source) {
      this.updateFilterWithPath({
        path: this.localePathName(path),
        queryKey,
        source,
        value,
      })
    },

    updateFilterQuery(filterQueryObject, queryKey, value) {
      const query = {
        ...filterQueryObject,
        [queryKey]: value,
      }

      // Reset salary when employment Type switch
      if (queryKey === 'employment') {
        query.hourlyRate = null
        query.monthlyRate = null
      }
      return query
    },

    /**
     * Update filter in route's query
     * @param {{path: *, queryKey: *, value: *, source: *}} option
     * @param {string} option.path - Url path
     * @param {string} option.queryKey - Filter query key. Refer to filterQueryObject()
     * @param {string|Array} option.value - filter value
     * @param {string} option.source - filter apply source
     * @param {string} option.routerFun - router method
     */
    updateFilterWithPath(option = {}) {
      const path = option.path
      const queryKey = option.queryKey
      const value = option.value
      const routerFun = option.routerFun || 'push'
      const source = option.source
      const query = this.updateFilterQuery(this.filterQueryObject, queryKey, value)
      query.source = source

      // update path
      this.$router[routerFun]({
        path,
        query: removeEmptyField(query),
      })
    },
  },
}
