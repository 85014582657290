import _cloneDeep from 'lodash/cloneDeep'
/**
 * Search *Vuex* module mutations
 */
export default {
  addCache(state, cache) {
    if (cache.data && cache.path) {
      state.cache[cache.path] = {
        data: _cloneDeep(cache.data),
        timestamp: Date.now(),
      }
    }
  },
}
