import JobAdCard from '../../components/job/jobCard/jobAdCard'

export default {
  components: {
    'job-ad-card': JobAdCard,
  },
  props: {
    biddingId: {
      default: '',
      type: String,
    },
    biddingSession: {
      default: '',
      type: String,
    },
    requestId: {
      default: '',
      type: String,
    },
  },
}
