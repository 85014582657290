/**
 * Mixin for error box
 */
export default {
  computed: {
    errorMessage() {
      return this.$store.getters['error/errorMessage']
    },
    isShowError() {
      return this.$store.getters['error/isShowError']
    },
  },
  methods: {
    dismissError() {
      this.$store.dispatch('error/dismissError')
    },
    showError(errMsg) {
      this.$store.dispatch('error/showError', errMsg)
    },
  },
}
