import { formatArticlesListPath } from '../libs/article/list'

/**
 * NuxtJS middleware to ensure
 * correct articles list page path
 */
export default function ({ params, redirect, route }) {
  // If page number route param equals "1",
  // remove page number from route
  if (params.page === '1') {
    redirect(formatArticlesListPath(route.path, 1))
  }
}
