import { districts, getDistrictLocaleKey, getDistrictRegionLocaleKey, getRegionDistrictGroups } from './district'
import { employmentTypes, getEmploymentTypeLocaleKey } from './employmentType'
import { getJobCategoryLocaleKey, getJobCategoryTypeGroups, getJobTypeLocaleKey, jobTypes } from './jobType'
import _concat from 'lodash/concat'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _isEqual from 'lodash/isEqual'
import { cleanStringForURL } from './'
import localeEn from '../../locales/en'
import localeZh from '../../locales/zh-hant'
import { transformValueToArray } from '../data/transform'

/**
 * Format search param entry data
 * @param {string} paramType
 * @param {[string]} paramValue
 * @param {string} paramTitleKey
 * @param {Dictionary<string>} localeMap
 * @param {string} localeCode
 */
const getSearchParamEntry = (paramType, paramValue, paramTitleKey, localeMap, localeCode) => {
  if (paramType && paramTitleKey && localeCode && Array.isArray(paramValue) && paramValue.length) {
    // Try to get localised display name
    // based on localisation key
    const displayName = cleanStringForURL(_get(localeMap, paramTitleKey))
    if (displayName) {
      return {
        displayName,
        localeCode,
        paramTitleKey,
        paramType,
        paramValue,
      }
    }
  }
  return null
}

/**
 * Get reversed map of employment type
 * display names to search params
 * @param {Dictionary<string>} localeMap
 * @param {string} localeCode
 */
const getEmploymentTypeSearchParams = (localeMap, localeCode) =>
  employmentTypes.reduce((map, { name } = {}) => {
    const searchParam = getSearchParamEntry(
      'employment',
      transformValueToArray(name),
      getEmploymentTypeLocaleKey(name),
      localeMap,
      localeCode,
    )
    if (searchParam) {
      map[searchParam.displayName] = searchParam
    }
    return map
  }, {})

/**
 * Get reversed map of district display names to search params
 * @param {Dictionary<string>} localeMap
 * @param {string} localeCode
 */
const getDistrictSearchParams = (localeMap, localeCode) =>
  districts.reduce((map, district = {}) => {
    const searchParam = getSearchParamEntry(
      'district',
      transformValueToArray(district.shortCode),
      getDistrictLocaleKey(district),
      localeMap,
      localeCode,
    )
    if (searchParam) {
      map[searchParam.displayName] = searchParam
    }
    return map
  }, {})

/**
 * Get reversed map of region display names to search params,
 * each containing a list of district Short Code in a region
 * @param {Dictionary<string>} localeMap
 * @param {string} localeCode
 */
const getDistrictRegionSearchParams = (localeMap, localeCode) =>
  getRegionDistrictGroups().reduce((map, region) => {
    const searchParam = getSearchParamEntry(
      'district',
      region.districts.map((d) => d.shortCode).filter((sc) => sc),
      getDistrictRegionLocaleKey(region.region),
      localeMap,
      localeCode,
    )
    if (searchParam) {
      map[searchParam.displayName] = searchParam
    }
    return map
  }, {})

/**
 * Get reversed map of job type display names to search params
 * @param {Dictionary<string>} localeMap
 * @param {string} localeCode
 */
const getJobTypeSearchParams = (localeMap, localeCode) =>
  jobTypes.reduce((map, jobType = {}) => {
    const searchParam = getSearchParamEntry(
      'jobType',
      transformValueToArray(jobType.shortCode),
      getJobTypeLocaleKey(jobType),
      localeMap,
      localeCode,
    )
    if (searchParam) {
      map[searchParam.displayName] = searchParam
    }
    return map
  }, {})

/**
 * Get reversed map of job category display names to search params,
 * each containing a list of job type IDs (Short Code) in a job category
 * @param {Dictionary<string>} localeMap
 * @param {string} localeCode
 */
const getJobCategorySearchParams = (localeMap, localeCode) =>
  getJobCategoryTypeGroups().reduce((map, category) => {
    const searchParam = getSearchParamEntry(
      'jobType',
      category.jobTypes.map((jt) => jt.shortCode).filter((shortCode) => shortCode),
      getJobCategoryLocaleKey(category.category),
      localeMap,
      localeCode,
    )
    if (searchParam) {
      map[searchParam.displayName] = searchParam
    }
    return map
  }, {})

export const searchEventTypeNameMap = {
  district: 'district',
  jobType: 'job_type',
  salary: 'salary',
  workingDay: 'weekday',
  workingHour: 'shift_type',
  workTime: 'worktime',
}

// e.g. {'seoName': {displayName, localeCode, paramTitleKey, paramType, paramValue}}
// Assume district, employment, job cat, job type have no name conflict
// FIXME Job Cat / job type if they have same name, Job Cat will override job type
export const SeoNameSearchCriteriaMap = {
  ...getDistrictSearchParams(localeZh, 'zh-hant'),
  ...getDistrictSearchParams(localeEn, 'en'),
  ...getDistrictRegionSearchParams(localeZh, 'zh-hant'),
  ...getDistrictRegionSearchParams(localeEn, 'en'),
  ...getEmploymentTypeSearchParams(localeZh, 'zh-hant'),
  ...getEmploymentTypeSearchParams(localeEn, 'en'),
  ...getJobTypeSearchParams(localeZh, 'zh-hant'),
  ...getJobTypeSearchParams(localeEn, 'en'),
  ...getJobCategorySearchParams(localeZh, 'zh-hant'),
  ...getJobCategorySearchParams(localeEn, 'en'),
}

/**
 * Generate path from search param
 * @param {*} params
 * @param {*} localeCode
 */
export function getParamSearchPath(params, localeCode) {
  if (_isEmpty(params)) {
    return ''
  }
  return `/find-jobs/${serializeSearchSlug(params, localeCode)}/`
}

/**
 * Restore search param from URL param slots
 */
export function getSearchParamFromURL(params = {}) {
  // Because company id does not exist in SeoNameSearchCriteriaMap,
  // so it is unnecessary to run the deserializeSearchSlug function below
  if (params.companyId) {
    return {
      company: params.companyId,
    }
  }
  return deserializeSearchSlug(params.slug)
}

// Get the filter query based on the slug
function deserializeSearchSlug(slug) {
  const data = {}
  if (slug) {
    slug
      .split('｜')
      .map((v) => SeoNameSearchCriteriaMap[v])
      .filter((v) => v)
      .forEach((v) => {
        data[v.paramType] = _concat(data[v.paramType] ? data[v.paramType] : [], v.paramValue)
        return 0
      })
  }
  return data
}

function serializeSearchSlug(searchParams, localeCode) {
  const allValues = Object.values(SeoNameSearchCriteriaMap)
  return Object.entries(searchParams)
    .map(([k, p]) => {
      const selected = allValues.find((v) => {
        return Array.isArray(p) && _isEqual(v.paramValue.sort(), p.sort()) && v.localeCode === localeCode
      })
      if (selected) {
        return selected.displayName
      }
    })
    .filter((v) => v)
    .sort() // FIXME sorting order, should have a more meaningful sorting
    .join('｜')
}
