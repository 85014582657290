<template>
  <button class="b b-hdr-menu" tabindex="0" :title="$t('header.accountTitle')" @click="handleClick">
    <template v-if="isLoggedIn">
      <!-- Seeker logo -->
      <div class="b__logo-main">
        <div v-if="isShowNotificationIcon" class="notice__marker" />
        <div class="b__logo">
          <svg-avatar v-if="!thumbnail" />
          <img
            v-if="thumbnail"
            loading="lazy"
            :alt="seekerName || 'Seeker logo'"
            :title="seekerName || 'Seeker logo'"
            :src="thumbnail"
          />
        </div>
      </div>
      <!-- Drop down arrow -->
      <svg-arrow-down class="b__arrow" />
    </template>
    <template v-else>
      <h3 class="b__title b__title-red">{{ this.$t('login.actionMain') }}</h3>
    </template>
  </button>
</template>

<script>
import AuthMixin from '../../mixins/auth'
import NotificationMarkerMixin from '../../mixins/notificationMarker'
import ProfileMixin from '../../mixins/profile'
import SvgArrowDown from '../../assets/svg/mini_arrow_down.svg?inline'
import SvgAvatar from '../../assets/svg/avatar.svg?inline'

export default {
  components: {
    'svg-arrow-down': SvgArrowDown,
    'svg-avatar': SvgAvatar,
  },
  computed: {
    /**
     * Seeker name
     */
    seekerName() {
      return this.name || this.telephone
    },
  },
  methods: {
    handleClick() {
      if (!this.isLoggedIn) {
        this.redirectToLogin()
      } else {
        this.$emit('click')
      }
    },
    redirectToLogin() {
      // Skip these path
      if (
        !this.$router.currentRoute.path.includes('/login') &&
        !this.$router.currentRoute.path.includes('/login-mfa')
      ) {
        this.$router.push({
          path: this.localePathName('/login'),
          query: {
            target: this.$router.currentRoute.fullPath,
          },
        })
      }
    },
  },
  mixins: [AuthMixin, ProfileMixin, NotificationMarkerMixin],
}
</script>

<style lang="postcss" scoped>
.b.b-hdr-menu {
  @apply flex items-center pl-2 pr-1;
  @apply text-black bg-pink-xlight;
}
.b__logo {
  @apply mr-2 rounded-full bg-grey-mlight overflow-hidden;
  @apply border border-solid border-pink-disabled;
  width: 34px;
  height: 34px;

  & img {
    @apply w-full h-full object-cover;
  }
}
.b__title {
  @apply text-left truncate;
  max-width: 200px;
  line-height: 26px;
}
.b__title-red {
  @apply text-red-500;
}
.b__arrow {
  @apply w-b-sm h-b-sm ml-1;
  @apply text-red-50 fill-current;
  transform-origin: center center;
  transition: transform 0.2s ease;
}
.b__logo-main {
  @apply relative;
}
.notice__marker {
  @apply absolute;
  @apply -ml-1;
}

@screen mobile {
  .b.b--hdr_menu {
    @apply shadow-none;
  }
  .b__arrow {
    @apply hidden;
  }
  .b__logo-main {
    @apply -mr-3;
  }
  .b__title {
    @apply text-xs;
  }
}
</style>
