import { getArticlePageLocalePaths } from '../../libs/locale'
import { pageLocales } from '../../locales/pages'

/**
 * Localisation Vuex module state
 */
export default function () {
  return {
    /**
     * Localised path mapping for pages
     * using URI-decoded $route.path as key.
     *
     * Multi-locale article pages
     * are included on default.
     */
    pageLocalePaths: getArticlePageLocalePaths(pageLocales),
  }
}
