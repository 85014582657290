<template>
  <div class="ftr-social-links">
    <a
      v-for="link in socialLinks"
      :key="`FooterSocialLink-${link.id}`"
      class="ftr-social-link"
      target="_blank"
      rel="noopener"
      :class="`ftr-social-link--${link.id}`"
      :href="link.href"
      :title="link.title"
    >
      <component :is="`svg-${link.id}`" class="ftr-social-link__icon svg-fill" />
      <p class="ftr-social-link__text">{{ link.title }}</p>
    </a>
  </div>
</template>

<script>
import SvgFb from '../../assets/svg/social/facebook.svg?inline'
import SvgIg from '../../assets/svg/social/instagram.svg?inline'
import SvgLi from '../../assets/svg/social/linkedin.svg?inline'
import SvgYt from '../../assets/svg/social/youtube.svg?inline'

export default {
  components: {
    'svg-fb': SvgFb,
    'svg-ig': SvgIg,
    'svg-li': SvgLi,
    'svg-yt': SvgYt,
  },
  data() {
    return {
      socialLinks: [
        {
          href: 'https://www.instagram.com/moovup.hk/',
          id: 'ig',
          isExternal: true,
          title: 'Moovup Instagram page',
        },
        {
          href: 'https://www.facebook.com/official.moovup',
          id: 'fb',
          isExternal: true,
          title: 'Moovup Facebook page',
        },
        {
          href: 'https://www.linkedin.com/company/moovuphk/',
          id: 'li',
          isExternal: true,
          title: 'Moovup Linkedin profile',
        },
        {
          href: 'https://www.youtube.com/channel/UCbWEfSy4SO81k2bW_ujOkSw',
          id: 'yt',
          isExternal: true,
          title: 'Moovup Youtube channel',
        },
      ],
    }
  },
}
</script>

<style lang="postcss" scoped>
.ftr-social-links {
  @apply flex flex-row flex-wrap justify-center;
}

.ftr-social-link {
  @apply flex justify-center items-center relative mb-0;

  &:not(:first-child) {
    @apply ml-4;
  }
  &:after {
    @apply absolute inset-0 rounded-sm bg-white opacity-0 z-1;
    content: '';
  }
  &:hover:after {
    @apply opacity-10;
  }
}
.ftr-social-link__icon {
  @apply relative z-0 text-grey-400;
}
.ftr-social-link__text {
  @apply sr-only;
}

.ftr-social-link--ig svg {
  @apply w-6 h-6;
}
.ftr-social-link--fb svg {
  @apply w-6 h-6;
}
.ftr-social-link--li svg {
  @apply mb-px;
  @apply w-6 h-6;
}
.ftr-social-link--yt svg {
  @apply w-6 h-6;
}
.ftr-social-link--me svg {
  @apply h-6;
  width: 52px;
}

@screen desktop {
  .ftr-social-links {
    @apply justify-start;
  }
}
</style>
