<template>
  <div>
    <m-drop-down class="hdr-main-menu" :class="menuClass" :items="items" @click="onItemClick">
      <b-hdr-menu slot="button" @click="toggleMenu" />
      <div slot="icon" class="notice__marker" />
    </m-drop-down>
    <b-hamburger-menu class="b-hamburger-menu" :items="items" @click="onItemClick" />
  </div>
</template>

<script>
import AuthMixin from '../../../mixins/auth'
import DropDownMenu from '../../menu/dropDownMenu'
import HamburgerMenuButton from '../../button/hamburgerMenuButton'
import HeaderMenuButton from '../../button/headerMenuButton'
import MenuMixin from '../../../mixins/menu/section'
import NotificationMarkerMixin from '../../../mixins/notificationMarker'
import { sendLogoutEvent } from '../../../libs/tracking/utils/event'

export default {
  components: {
    'b-hamburger-menu': HamburgerMenuButton,
    'b-hdr-menu': HeaderMenuButton,
    'm-drop-down': DropDownMenu,
  },
  computed: {
    /**
     * Header menu items
     */
    items() {
      if (!this.isLoggedIn) {
        return []
      }
      return [
        {
          'data-action': 'my_profile',
          title: this.$t('profile.myProfile'),
        },
        {
          'data-action': 'my_application',
          title: this.$t('application.myApplications'),
        },
        {
          'data-action': 'my_notification',
          icon: this.isShowNotificationIcon,
          title: this.$t('notification.title'),
        },
        {
          'data-action': 'my_job_alert',
          title: this.$t('jobAlert.title'),
        },
        {
          'data-action': 'logout',
          title: this.$t('logout.actionMain'),
        },
      ]
    },
    menuClass() {
      return {
        'hdr-main-menu__btn-signin': !this.isLoggedIn,
        'hdr-main-menu__icon--open': this.isActive,
        'hdr-main-menu__list--open': this.isActive,
      }
    },
  },
  data() {
    return {
      isActive: false,
    }
  },
  methods: {
    closeMenu() {
      this.isActive = false
    },
    /**
     * Handle header menu item click action
     * based on provided item index
     */
    onItemClick(index) {
      this.closeMenu()
      const item = this.items[index]
      if (item) {
        const eventName = item['data-action']
        switch (eventName) {
          // Logout
          case 'logout':
            sendLogoutEvent(this.$eventTracker)
            this.logout()
            break
          default:
            this.clickAccountSections(eventName)
            break
        }
      }
    },
    toggleMenu() {
      this.isActive = !this.isActive
    },
  },
  mixins: [AuthMixin, NotificationMarkerMixin, MenuMixin],
}
</script>

<style lang="postcss" scoped>
::v-deep .b {
  height: 28px;
}
::v-deep .b__logo {
  height: 24px;
  width: 24px;
}
.notice__marker {
  @apply ml-1;
}
.hdr-main-menu::v-deep .b.b-hdr-menu {
  @apply px-3;
  background-color: transparent;
}
.m.m--dropdown {
  &::v-deep .m__list {
    @apply left-auto right-0;
    width: 180px;
  }
}
@screen mobile {
  .b.b-hdr-menu {
    @apply hidden;
  }
  .hdr-main-menu::v-deep .m__button {
    @apply mr-2;
  }
  /* Click event */
  .hdr-main-menu__icon--open {
    &::v-deep .b__arrow {
      transform: rotate(180deg);
    }
  }
  :not(.hdr-main-menu__list--open)::v-deep .m__list {
    @apply opacity-0 pointer-events-none;
    transform: translateY(-3rem);
  }
}
@screen desktop {
  .b-hamburger-menu {
    @apply hidden;
  }
  ::v-deep .b {
    height: 40px;
  }
  ::v-deep .b__logo {
    height: 36px;
    width: 36px;
  }
  /* Hover event */
  .m.m--dropdown:hover {
    &::v-deep .b__arrow {
      transform: rotate(180deg);
    }
  }
  .hdr-main-menu__btn-signin {
    &::v-deep .b.b-hdr-menu {
      @apply bg-white-plain border-red-50 border;
      & h3 {
        @apply text-grey-900;
      }
    }
  }
  .hdr-main-menu:not(.hdr-main-menu__btn-signin) {
    &::v-deep .b.b-hdr-menu {
      @apply shadow-none;
    }
  }
  .hdr-main-menu {
    &::v-deep .m__list {
      @apply pt-0;
    }
  }
}
</style>
