import _cloneDeep from 'lodash/cloneDeep'

// Unit in ms
const threshold = process.env.CACHE_THRESHOLD || 300000

/**
 * Search *Vuex* module getter
 */
export default {
  getCache(state) {
    return (fullPath) => {
      return {
        data: _cloneDeep(state.cache[fullPath].data),
        timestamp: state.cache[fullPath].timestamp,
      }
    }
  },

  hasCache(state) {
    return (fullPath) => {
      const cacheObj = state.cache[fullPath]
      return !!cacheObj && Date.now() - cacheObj.timestamp <= threshold
    }
  },
}
