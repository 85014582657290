import { formatArticle, getArticlePublishedFilters } from './'
import { formatLocalePath, getPageNameByLocalePath } from '../locale'
import { pageLocales } from '../../locales/pages'

/**
 * Maximum number of articles in a page
 * @type {number}
 */
export const articlesListPageLimit = 10

/**
 * Format articles list page path,
 * support omitting page number for page 1
 * @param {string} path
 * @param {number} pageNumber
 * @returns {string}
 */
export function formatArticlesListPath(path, pageNumber) {
  if (path && Number.isInteger(pageNumber)) {
    // Format localised path,
    // then remove trailing page number param
    const pathPrefix = formatLocalePath(path).replace(/\/\d+\/$/, '/')

    // Unless in page 1,
    // append trailing page number
    if (pageNumber > 1) {
      return `${pathPrefix}${pageNumber}/`
    }

    // Otherwise return formatted path directly
    return pathPrefix
  }
  return ''
}

/**
 * Extract positive integer page number
 * from route params,
 * fallback to page 1
 * @param {{page: string}} params
 * @returns {number}
 */
export function getArticlesListPageNumber({ page } = {}) {
  const pageNumber = parseInt(page)
  if (Number.isInteger(pageNumber) && pageNumber >= 1) {
    return pageNumber
  }
  return 1
}

/**
 * Fetch articles list in provided directory and filters
 * @param {Function} $content
 * @param {string} articlesDir
 * @param {*?} articlesFilters
 * @param {number?} pageLimit
 * @param {number?} pageOffset
 * @param {[string]?} omitKeys
 * @returns {Promise<{articlesTotal: number, articles: Array}>}
 */
export async function fetchArticlesListData(
  $content,
  articlesDir,
  articlesFilters = {},
  pageLimit,
  pageOffset = 0,
  omitKeys = [],
) {
  let articles = []
  let articlesTotal = 0
  if (articlesDir && typeof $content === 'function') {
    const res = await $content(articlesDir, { deep: true })
      // Apply article filters
      .where({
        ...articlesFilters,
        ...getArticlePublishedFilters(),
      })
      // Order articles by created time
      .sortBy('createdAt', 'desc')
      // Omit unnecessary keys from article data
      .without(omitKeys)
      // Fetch articles
      .fetch()

    if (Array.isArray(res)) {
      // Filter out empty / invalid entries
      const allArticles = res.filter((article) => article && article.dir !== articlesDir)

      // Set total number of articles
      articlesTotal = allArticles.length

      // Apply pagination
      if (Number.isInteger(pageOffset)) {
        articles = allArticles.slice(pageOffset)
      }
      if (Number.isInteger(pageLimit)) {
        articles = articles.slice(0, pageLimit)
      }
    }
  }
  return {
    articles,
    articlesTotal,
  }
}

/**
 * Fetch articles list config data,
 * then filter by page name and locale code
 * @param {Function} $content
 * @param {string} pageName
 * @param {string} localeCode
 * @returns {Promise}
 */
export async function fetchArticlesListConfig($content, pageName, localeCode) {
  if (pageName && localeCode && typeof $content === 'function') {
    // Fetch config for all articles lists,
    // then get list config based on page name and locale code
    const listConfig = await $content('config/list').fetch()
    if (listConfig && listConfig[pageName] && listConfig[pageName][localeCode]) {
      return listConfig[pageName][localeCode]
    }
  }
}

/**
 * Handle listing articles data
 * in asyncData hook
 * @param {*} context
 * @param {string} articlesDir
 * @param {*?} articlesFilters
 * @param {number?} pageLimit
 * @param {number?} pageOffset
 * @param {*?} defaults
 * @param {[string]?} omitKeys
 * @returns {Promise<{articles: Array}>}
 */
export async function listAsyncArticlesData(
  { $content, app, payload, route = {} } = {},
  articlesDir,
  articlesFilters,
  pageLimit,
  pageOffset,
  defaults = {},
  omitKeys = [],
) {
  let articles = []
  let articlesTotal = 0
  let articlesListConfig

  if (payload && Array.isArray(payload.articles) && Number.isInteger(payload.articlesTotal)) {
    // Use payload data if available
    articles = payload.articles
    articlesTotal = payload.articlesTotal
    articlesListConfig = payload.articlesListConfig
  } else {
    // Otherwise if articles directory available,
    // list articles recursively in provided directory
    const fetchRes = await fetchArticlesListData(
      $content,
      articlesDir,
      articlesFilters,
      pageLimit,
      pageOffset,
      omitKeys,
    )
    articles = fetchRes.articles
    articlesTotal = fetchRes.articlesTotal

    // Get articles list page name
    // based on path (w/o page number param)
    const articlesListPageName = getPageNameByLocalePath(pageLocales, formatArticlesListPath(route.path, 1))

    // If page name and locale code available,
    // try to get articles list config
    if (articlesListPageName && app && app.i18n && app.i18n.locale) {
      articlesListConfig = await fetchArticlesListConfig($content, articlesListPageName, app.i18n.locale)
    }
  }

  return {
    /** Formatted articles data */
    articles: articles.map((article) => formatArticle(article, defaults)),

    /** Articles list config */
    articlesListConfig,

    /** Total number of articles listed */
    articlesTotal,
  }
}
