import { decodePathURI, formatLocalePath } from '../locale'
import _cloneDeep from 'lodash/cloneDeep'
import { isEnvProduction } from '../env'

/**
 * Format article image URL,
 * prepend article assets URL to relative path
 * @param {string} path
 * @returns {string}
 */
export function formatArticleImageUrl(path) {
  if (path && !path.startsWith('http')) {
    return `${process.env.SEEKER_WEB__CONTENT_ASSETS_URL}${path}`
  }
  return path
}

/**
 * Format article image tag
 * @param {*} tag
 * @returns {*}
 */
export function formatArticleImageTag(tag) {
  if (tag && tag.props) {
    const children = [tag]
    tag.props.src = formatArticleImageUrl(tag.props.src)

    // NOTE: image lazy loading is disabled for article pages
    // due to the content layout shift it causes
    // tag.props.loading = 'lazy'

    // // If image alt text available,
    // // use it as caption
    // if (tag.props.alt) {
    //   children.push({
    //     children: [ { type: 'text', value: tag.props.alt } ],
    //     props: {},
    //     tag: 'figcaption',
    //     type: 'element',
    //   })
    // }

    // Wrap image w/ <figure> container element
    return {
      children,
      props: {},
      tag: 'figure',
      type: 'element',
    }
  }

  return tag
}

/**
 * Format article iframe tag
 * @param {*} tag
 * @returns {*}
 */
export function formatArticleIframeTag(tag) {
  if (tag && tag.props) {
    // Get iframe aspect ratio
    let aspectRatio = 1
    if (
      Number.isFinite(tag.props.width) &&
      tag.props.width > 0 &&
      Number.isFinite(tag.props.height) &&
      tag.props.height > 0
    ) {
      aspectRatio = tag.props.height / tag.props.width
    }

    // Wrap iframe w/ <div> container element
    return {
      children: [
        tag,
        // Add aspect ratio placeholder <span> element
        {
          children: [],
          props: {
            class: 'article-iframe__placeholder',
            style: `padding-top: ${aspectRatio * 100}%`,
          },
          tag: 'span',
          type: 'element',
        },
      ],
      props: {
        class: 'article-iframe',
      },
      tag: 'div',
      type: 'element',
    }
  }

  return tag
}

/**
 * Format article body content tag
 * @param {*} tag
 * @returns {*}
 */
export function formatArticleTag(tag) {
  let tagData
  if (tag) {
    tagData = _cloneDeep(tag)

    // Recursively format children tags
    if (Array.isArray(tagData.children)) {
      tagData.children = tagData.children.map(formatArticleTag)
    }

    if (tagData.type === 'element' && tagData.props) {
      // Format image tag
      if (tagData.tag === 'img') {
        return formatArticleImageTag(tagData)
      }

      // Format banner tag
      if (tagData.tag === 'banner') {
        tagData.props['desktop-background-image'] = formatArticleImageUrl(tagData.props['desktop-background-image'])
        tagData.props['mobile-background-image'] = formatArticleImageUrl(tagData.props['mobile-background-image'])
      }

      // Format iframe tag
      if (tagData.tag === 'iframe') {
        return formatArticleIframeTag(tagData)
      }

      // Try to decode internal link paths
      if (tagData.tag === 'nuxt-link' && tagData.props.to) {
        tagData.props.to = decodePathURI(tagData.props.to)
      }
    }
  }

  return tagData
}

/**
 * Format article data
 * @param {*} article
 * @param {*} defaults
 * @returns {*}
 */
export function formatArticle(article, defaults) {
  let articleData
  if (article) {
    // Apply default values as fallback,
    // before cloning article data
    articleData = _cloneDeep({
      ...defaults,
      ...article,
    })

    // Format article content directory into path
    if (articleData.dir) {
      articleData.url = formatLocalePath(articleData.dir)
    }

    // Format article cover image URL
    if (articleData.coverImage) {
      articleData.coverImage = formatArticleImageUrl(articleData.coverImage)
    }

    // Remove last updated time
    // unless it's after created time
    if (!articleData.lastUpdatedAt || articleData.lastUpdatedAt <= articleData.createdAt) {
      delete articleData.lastUpdatedAt
    }

    // Format article body content tags
    if (articleData.body) {
      articleData.body = formatArticleTag(articleData.body)
    }
  }

  return articleData
}

/**
 * Get metadata description from article data
 * @param {string} description
 * @param {string} excerpt
 * @returns {string}
 */
export function getArticleMetaDescription({ description, excerpt } = {}) {
  return description || excerpt || ''
}

/**
 * Return article published state filter
 * based on published only flag
 * @returns {{}|{published: boolean}}
 */
export function getArticlePublishedFilters() {
  if (isEnvProduction()) {
    return { published: true }
  }
  return {}
}

/**
 * Handle getting article data
 * in asyncData hook
 * @param {*} context
 * @param {string} articlePath
 * @param {*} defaults
 * @param {[string]} omitKeys
 * @returns {Promise<{article: *}>}
 */
export async function getAsyncArticleData({ $content, payload } = {}, articlePath, defaults = {}, omitKeys = []) {
  let article = null
  if (payload && payload.article) {
    // Use payload data if available
    article = payload.article
  } else if (articlePath && typeof $content === 'function') {
    // Otherwise if article path available,
    // fetch article by path
    article = await $content(articlePath)
      // Apply article filters
      .where(getArticlePublishedFilters())
      // Omit unnecessary keys from article data
      .without(omitKeys)
      .fetch()
  }

  return {
    /** Formatted article data */
    article: formatArticle(article, defaults),
  }
}
