import { GET_PROFILE_QUERY } from '../../api/octo/graphql/profile'
import { postSeekerGQL } from '../../api/octo/graphql'

export default {
  /**
   * Get profile from Octo *graphql* endpoint
   * @param {string} token
   */
  async getProfile({ commit, dispatch }, token) {
    if (token) {
      // If token available,
      // send get profile request to Octo
      const res = await postSeekerGQL(GET_PROFILE_QUERY, {}, token)
      if (res.data && res.data.portfolio) {
        const profile = res.data.portfolio
        commit('setProfile', profile)
        // Trigger queue callbacks
        dispatch('triggerReadyQueueCallbacks')
      }
    }
  },

  /**
   * Trigger callbacks in profile ready queue
   */
  triggerReadyQueueCallbacks({ commit, state }) {
    if (state.readyQueue.length) {
      // Trigger the callbacks
      state.readyQueue.forEach((callback) => callback())

      // Clear ready queue
      commit('clearReadyQueue')
    }
  },

  /**
   * On client side,
   * trigger provided callback
   * once profile is ready
   * @param {*} context
   * @param {Function} callback
   */
  whenProfileReady({ commit, state }, callback) {
    if (typeof callback === 'function') {
      if (state.profile && state.profile._id) {
        // If profile ready,
        // trigger callback directly
        callback()
      } else {
        // Otherwise add callback to ready queue
        commit('addReadyCallback', callback)
      }
    }
  },
}
