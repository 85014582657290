import { captureException, configureScope } from '@sentry/browser'
import {
  GET_ALL_DELETED_JOB_IDS_QUERY,
  GET_ALL_JOB_IDS_QUERY,
  GET_FEATURED_COMPANY_STATIC_QUERY,
  GET_HOMEPAGE_STATIC_QUERY,
  GET_JOBS_BY_IDS_QUERY,
  QUERY_JOBS,
  QUERY_JOBS_COUNT,
} from './queries'
import { postOcto } from '..'

/**
 * POST *Graphql* request to Octo endpoint,
 * return extracted JSON data
 * @param {string} query
 * @param {Dictionary<*>} variables
 * @param {string} token
 */
export async function postSeekerGQL(query, variables, token) {
  const res = await postOcto(
    'seeker',
    {
      query,
      variables,
    },
    token,
  )

  // Graphql result is wrapped in a *data* layer by default
  if (res && res.data && res.data.data) {
    // If the result is empty,
    // the request has triggered schema error in BE,
    // send exception to sentry
    if (!Object.keys(res.data.data).length) {
      const errorMsg = 'GraphQL schema error'

      configureScope((scope) => {
        scope.setExtra('query', query)
      })
      captureException(new Error(errorMsg))
      return {
        error: {
          payload: errorMsg,
        },
      }
    }

    // Otherwise return result
    return {
      data: res.data.data,
    }
  }

  return res
}

/**
 * Get all deleted job IDs from Octo seeker *Graphql* endpoint,
 * supports optional starting timestamp
 * @param {string} token
 * @param {string} since
 * @returns {Promise<[string]}
 */
export function getAllDeletedJobIDs(token, since) {
  return postSeekerGQL(
    GET_ALL_DELETED_JOB_IDS_QUERY,
    {
      since,
    },
    token,
  )
}

/**
 * Get all active job IDs from Octo seeker *Graphql* endpoint,
 * supports optional starting timestamp
 * @param {string} token
 * @param {string} since
 * @returns {Promise<[string]>}
 */
export function getAllJobIDs(token, since) {
  return postSeekerGQL(
    GET_ALL_JOB_IDS_QUERY,
    {
      since,
    },
    token,
  )
}

/**
 * Get all job count from Octo seeker *Graphql* endpoint
 * @param {string} token
 */
export function getAllJobCount(token) {
  return postSeekerGQL(QUERY_JOBS_COUNT, {}, token)
}

/**
 * Get homepage static data from Octo seeker *Graphql* endpoint
 * @param {string} token
 */
export function getHomepageStatic(token) {
  return postSeekerGQL(GET_HOMEPAGE_STATIC_QUERY, {}, token)
}

/**
 * Get featured company static data from Octo seeker *Graphql* endpoint
 * @param {string} token
 */
export function getFeaturedCompanyStatic(token) {
  return postSeekerGQL(GET_FEATURED_COMPANY_STATIC_QUERY, {}, token)
}

/**
 * Get jobs from Octo seeker *Graphql* endpoint,
 * by job IDs
 * @param {[string]} jobIDs
 * @param {string} token
 * @returns {Promise<Array>}
 */
export function getJobsByIDs(jobIDs, token) {
  return postSeekerGQL(
    GET_JOBS_BY_IDS_QUERY,
    {
      ids: jobIDs,
    },
    token,
  )
}

/**
 * Search for jobs from Octo seeker *Graphql* endpoint,
 * by provided param type and value
 * @param {string} paramType
 * @param {string|[string]} paramValue
 * @param {number} limit
 * @param {number} offset
 * @param {string} token
 * @returns {Promise}
 */
export function searchJobsByParam(paramType, paramValue, limit, offset, token) {
  if (paramType && paramValue) {
    return postSeekerGQL(
      QUERY_JOBS,
      {
        limit,
        offset,
        [paramType]: paramValue,
      },
      token,
    )
  }
  return null
}
