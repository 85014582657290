/**
 * Loading *Vuex* module state
 */
export default function () {
  return {
    /**
     * Error message
     */
    errMsg: '',
    /**
     * Whether show error
     */
    isShowError: false,
    /**
     * Timer for dismiss
     */
    timer: null,
  }
}
