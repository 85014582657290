import errorKeys from '../libs/errorKeys'

export default {
  account: {
    restore: {
      actionMain: 'Restore Account',
      actionOptional: 'Logout Now',
      backToHome: 'Back To Homepage',
      restoreDesc:
        'Please logout your account first, and use guest mode to view jobs. \n\nNotice: Your account will be deleted permanently on {dateToBeDeleted}, including your personal information, phone number and profile photo. During the waiting period, your account will be marked for closure but it still exists. If you wish to restore your account, just sign in again and confirm restoration within 90 days.',
      restoreTitle: 'Account Deletion In Progress',
      successDesc:
        'Thank you for using Moovup. Your account has been restored. You can now login to your previous account to apply jobs.',
      successTitle: 'Account Restoration Successful',
    },
  },
  allowance: {
    meal: 'Meal',
    other: 'Other',
    travel: 'Travel',
  },
  appInstall: {
    rejectedContent: 'Download Moovup app to get the latest application status',
  },
  application: {
    appliedAt: 'Applied at',
    detailTitle: 'Application Detail',
    myApplicationAppliedLocation: 'Applied Locations',
    myApplications: 'My Application',
    myApplicationStatus: 'Status',
    myApplicationStatusApplied: 'Submitted application',
    myApplicationStatusContacted: 'Employer may contact you by phone',
    myApplicationStatusJobClosed:
      'This job post is closed. If you have not been contacted shortly, please consider applying for other jobs',
    myApplicationStatusRejected: 'Sorry, your application is unsuccessful',
    myApplicationStatusReviewed: 'Your application has been reviewed',
  },
  applicationStatus: {
    All: 'All',
    Rejected: 'Rejected',
    Reviewed: 'Reviewed',
  },
  apply: {
    applied: 'Applied',
    appliedLocationTitle: 'Applied Locations',
    apply: 'Apply',
    applyJobDesFormat: "You are applying {companyName} 's position {jobTitle}",
    applyJobSuccessFormat: "You are successfully applied {companyName} 's position {jobTitle}",
    chooseLocation: 'Choose Job Location',
    expired: 'Invalid',
    fillCV: 'Write Profile',
    selectedLocations: 'Selected Locations',
    selectLocationFormat: 'Selected: {selectedNum}/{totalNum}',
    success: 'Applied Successfully',
    title: 'Apply Job',
    viewApplication: 'Review the Application',
    viewOtherJob: 'Browse Another Job',
  },
  article: {
    readAll: 'Read all',
    updatedOn: 'Updated on',
  },
  articles: {
    nextPage: 'Next page',
    prevPage: 'Prev page',
  },
  company: {
    emptyAboutCompany: 'Not available',
    featuredCompany: 'Featured company',
  },
  country: {
    hk: 'Hong Kong (English)',
  },
  dayOfWeek: {
    0: 'Mon',
    1: 'Tue',
    2: 'Wed',
    3: 'Thu',
    4: 'Fri',
    5: 'Sat',
    6: 'Sun',
    multiple: '{start} to {end}',
    negotiable: 'Day Negotiable',
  },
  district: {
    Aberdeen: 'Aberdeen',
    Central: 'Central',
    'DBay & Disney': 'DBay & Disney',
    'Fanling & Sheung Shui': 'Fanling & Sheung Shui',
    'Hong Kong Island': 'Hong Kong Island',
    Hunghom: 'Hunghom',
    'Island East': 'Island East',
    'Island South': 'Island South',
    'Island West': 'Island West',
    Kowloon: 'Kowloon',
    'Kowloon Bay': 'Kowloon Bay',
    'Kowloon City': 'Kowloon City',
    'Kowloon Tong': 'Kowloon Tong',
    'Kwai Chung': 'Kwai Chung',
    'Kwun Tong': 'Kwun Tong',
    label: 'District',
    Lantau: 'Lantau',
    'Ma On Shan': 'Ma On Shan',
    'Mei Foo': 'Mei Foo',
    'Mid-Levels & Peak': 'Mid Levels / Peak',
    'New Territories East': 'NT East',
    'New Territories West': 'NT West',
    'Pok Fu Lam': 'Pok Fu Lam',
    'Sai Kung': 'Sai Kung',
    'Sham Shui Po': 'Sham Shui Po',
    Shatin: 'Shatin',
    'Tai Po': 'Tai Po',
    'Tin Shui Wai': 'Tin Shui Wai',
    'Tseung Kwan O': 'Tseung Kwan O',
    'Tsing Yi': 'Tsing Yi',
    'Tsuen Wan': 'Tsuen Wan',
    'Tuen Mun': 'Tuen Mun',
    'Tung Chung and Airport': 'Tung Chung & Airport',
    'Wan Chai / Causeway Bay': 'Wan Chai / Causeway Bay',
    'West Kowloon': 'West Kowloon',
    'Wong Tai Sin': 'Wong Tai Sin',
    'Yau Tsim Mong': 'Yau Tsim Mong',
    'Yuen Long': 'Yuen Long',
  },
  education: {
    'Associate Degree': 'Associate Degree',
    'Diploma / Certificate': 'Diploma / Certificate',
    'ERB / Other Training Certificate': 'ERB or other Training Certificate',
    'Form 3': 'Form 3',
    'Higher Diploma / Certificate': 'Higher Diploma /  Certificate',
    'HKDSE or equivalent': 'HKDSE / Equivalent',
    postLevel: 'Post Secondary',
    'Primary Education': 'Primary',
    primaryLevel: 'Primary Education',
    secondaryLevel: 'Secondary Education',
    'Tertiary Education': 'Tertiary Education',
    'Vocational Education': 'Vocational Education',
  },
  employer: {
    bidding: 'Job Ad Booster',
    faq: 'FAQ',
    homepage: 'Home page',
    login: 'Login',
    recruitmentAdvice: 'Recruitment Advice',
    recruitmentSolutions: 'Recruitment Solutions',
    temp: 'Temp Worker',
  },
  employerSellingPage: {
    appDownloads: 'App downloads',
    completelyFree: '100% free',
    contactAndHireRightCandidate: 'Contact and hire the right candidate',
    contactCandidatesAndTakeQuickActions:
      'Contact candidates via WhatsApp or phone call and take quick hiring actions.',
    downloadMoovupApp: 'Download Moovup app',
    easyToHire: 'Easy way to recruit',
    employerLogin: 'Employer login',
    exposeYourJobCardApplicant1: 'Mei Ling',
    exposeYourJobCardApplicant2: 'Ming Wong',
    exposeYourJobCardApplicant3: 'Ravi',
    exposeYourJobCardAppliedJob: 'Applied: Barista',
    exposeYourJobCardContact: 'Contact',
    exposeYourJobPost: 'Expose your job post to our large front-line job seeker base',
    faq: 'FAQ',
    faqContent1a:
      'We are a job platform for front-line industries in Hong Kong. We use Al and machine learning algorithms to match job seekers with employer job ads.',
    faqContent1b:
      ' Our mission is to enable the working class to move up in life.We are passionate about connecting job seekers with the most opportunities and to empower and enable them to find new possibilities.',
    faqContent2a: '100% free! No hidden fees!',
    faqContent2b:
      'Post a job and start hiring for free. We also offer additional paid features depending on your need.',
    faqContent3: 'Moovup covers 16 categories:',
    faqContent4a:
      'Any company with a valid Hong Kong business registration certificate can register as an employer. You can register using your mobile number or email (for email registration, please see below full employer FAQ for the other requirements).',
    faqContent4b:
      'If you are an NGO (and not able to upload the HK business registration certificate), you can upload the government document that confirms your tax exemption status under Section 88 of the Inland Revenue Ordinance.',
    faqContent5a: 'Hire talents in 3 simple steps:',
    faqContent5b: 'Step 1: Register and post jobs for free',
    faqContent5c: 'Step 2: Review and shortlist preferred applicants',
    faqContent5d: 'Step 3: Contact candidates via WhatsApp or phone call and take quick hiring actions',
    faqContent6a: 'Yes, Moovup offers the following additional paid features:',
    faqContent6b:
      'Job AD booster feature: your job post will be displayed at a prominent position in the Moovup website and mobile apps. This helps you reach more applicants by boosting the exposure and click rate of your job post. The fee is based on clicks. No click, no fee. Learn more about the Job AD booster feature.',
    faqContent6c:
      'Ad Banner: employers can advertise through the ad banners on the landing page of our website and apps to attract more job seekers. For more information, please contact {0}.',
    faqContent6Email: 'sales@moovup.com',
    faqContent7:
      'We use Amazon Web Services (AWS) to store our users’ data. Amazon Web Services is one of the most highly regarded and secure cloud computing platforms in the world. For more information regarding Amazon Web Services, please visit {0}.',
    faqContent7Email: 'https://aws.amazon.com',
    faqContent8a: 'Please refer to the full employer FAQ for details.',
    faqContent8b:
      'Otherwise, please contact cs@moovup.com and our team will be happy to answer any questions that you may have.',

    faqTitle1: 'What is Moovup?',
    faqTitle2: 'What is the pricing for posting a job?',
    faqTitle3: 'What industries do you cover?',
    faqTitle4: 'What are the requirements for registration as an employer?',
    faqTitle5: 'How does Moovup work?',
    faqTitle6: 'Does Moovup offer additional paid features?',
    faqTitle7: 'How secure is the data storage?',
    faqTitle8: 'What if I have more questions?',
    frontLineJobPlatform: 'Recruitment platform for front-line jobs',
    frontLineWorker: 'Front-line worker',
    hiring: 'Start hiring',
    hour: 'Hour',
    jobAdBooster: 'Job Ad Booster',
    jobDuty: 'Job Duty',
    jobSeeker: "I'm a job seeker",
    metaTitle: 'Post Job Ads for Free | Front-line Workers and Jobs | Moovup',
    monthlyActiveSeekers: 'Monthly active front-line job seekers',
    noCost: 'No cost',
    noHiddenFees: 'No hidden fees',
    oneMillion: '1M',
    postAJobForFree: 'Post a job for free',
    postJobAndLetSeekerKnowVacancies:
      'Post a job in minutes and let thousands of front-line jobseekers know about your vacancies.',
    postJobForFreeAtAnytime: 'Post your job for free anytime, anywhere',
    postJobForFreeCardCompany: 'Moovup Cafe',
    postJobForFreeCardDaysOrWeeks: '{0} Days/wk',
    postJobForFreeCardJobDuty:
      'We are looking for an experienced barista to join our growing team. This is an exciting position for individuals looking to grow into a leadership role.',
    postJobForFreeCardJobTitle: 'Barista',

    postJobForFreeCardLocation: 'Central',
    postJobForFreeCardWorkingTime: '60-100',
    readFAQ: 'Read all employer FAQ',
    receiveJobApplication: 'Receive job applications, review and shortlist your preferred candidates.',
    registerWithPhoneOrEmail:
      'Register with mobile number or email, and manage your job posts on any device, wherever you are.',
    startHiring: 'Start hiring',
    startHiringNow: 'Start hiring now',
    step1: 'Step 1',
    step2: 'Step 2',
    step3: 'Step 3',
    testimonyAuthor1: 'Paul Hotchan',
    testimonyAuthor1Title: 'Head of Talent Acquisition',
    testimonyAuthor2: 'Alina Chow',
    testimonyAuthor2Title: 'Senior Talent Acquisition Manager',
    testimonyAuthor3: 'Kan',
    testimonyAuthor3Title: ' Executive Chef',
    testimonyCompany1: 'The Hong Kong Jockey Club',
    testimonyCompany2: 'DFI Retail Group',
    testimonyCompany3: 'Gotthard Base',
    testimonyText1:
      'Moovup’s innovative approach, extensive candidate database, and ability to reach front-line candidates have been invaluable to our recruitment efforts at the Hong Kong Jockey Club. Additionally, their engaging events create an online-to-offline experience that resonates with the next generation of employees we hope to attract. We look forward to continuing our successful partnership.',
    testimonyText2:
      'Moovup provides one-stop recruitment services, enabling employers to reach potential candidates in a more efficient way. Candidates can easily search and apply our jobs on the portal which greatly enhance their candidate experience.',
    testimonyText3:
      'Thank you on behalf of my friend! He could not find help for his food court in Tseung Kwan O for a long time. After posting the job on your Moovup website, he found a suitable candidate and there were quite a number of interviewees. This has brought new hope to his life. Separately, our Chinese restaurant was also able to find a dishwasher lady through Moovup. Thank you very much and keep up the good work.',
    titleFaq: '{0}',
    titleFaq2: 'FAQ',
    titleFocusFrontLine: 'We focus on {0}',
    titleFocusFrontLine2: 'front-line industries',
    titleHire: 'Hire in 3 {0} steps',
    titleHire2: 'simple',
    titleTestimonies: 'Testimonies from {0}',
    titleTestimonies2: 'our employers',
    titleTrustedBy: 'Trusted by {0} employers',
    titleTrustedBy2: '50K+',
    twoHundredThousand: '200K',
    vacancies: 'Vacancies',
  },
  employment: {
    FullTime: 'Full Time',
    PartTime: 'Part Time',
    Temp: 'Temp',
  },
  error: {
    [errorKeys.APPLY_LOC_MAX]: 'You can only choose five locations',
    [errorKeys.CONFIRM_SIGNUP_FAILED]: "We couldn't register your account, please try again later.",
    [errorKeys.E100005]: 'Failed to Apply. Please try again later.',
    [errorKeys.E100026]: "Company doesn't exist",
    [errorKeys.E100027]: 'Operation deined',
    [errorKeys.E100056]: 'Your daily/monthly application limit has been reached. Please try again later.',
    [errorKeys.EMAIL_INVALID]: 'Invalid email address',
    [errorKeys.EMAIL_REGISTERED]: 'Email address already registered',
    [errorKeys.EMAIL_RESENT]: 'Verification email resent',
    [errorKeys.FILE_NOT_SELECTED]: 'Please select a file',
    [errorKeys.FILE_SIZE_EXCEED_LIMIT]: 'File size exceeds limit',
    [errorKeys.FILE_TYPE_NOT_ACCEPTED]: 'Invalid file format',
    [errorKeys.LOGIN_FAILED]: 'The email / password you entered are incorrect',
    [errorKeys.LIST_COUNT_BELOW_ONE]: 'At least 1 option required',
    [errorKeys.LOGIN_OTP_INVALID]: 'The verification code is invalid, please try again',
    [errorKeys.PASSWORD_ALREADY_SET]: 'You have already set password, please try to log in.',
    [errorKeys.PASSWORD_INVALID]: 'Incorrect password format (Minimum 6 alphanumeric characters)',
    [errorKeys.PASSWORDS_NOT_MATCH]: "Passwords don't match",
    [errorKeys.REQUEST_ERROR]: "There's a network error, please try again later.",
    [errorKeys.REQUIRED]: 'Required',
    [errorKeys.SET_PASSWORD_FAILED]: "We couldn't set your password, please try again later.",
    [errorKeys.TELEPHONE_INVALID]: 'Invalid telephone number (Minimum 8 numbers)',
    [errorKeys.TOKEN_EXPIRED]: 'Session is expired',
    [errorKeys.UPLOAD_FAILED]: "We couldn't upload the file, please try again later.",
    content404: 'Sorry! The page you are looking for could not be found.',
    home: 'Back to Home',
    title404: 'Page not found',
  },
  footer: {
    allRights: 'All Rights Reserved',
    copyright: 'Copyright',
    employer: 'Employers',
    followUs: 'Follow Moovup',
    seeker: 'Job Seekers',
  },
  general: {
    add: 'Add',
    allCategory: 'All Job Category',
    back: 'Back',
    cancel: 'Cancel',
    close: 'Close',
    colon: ': ',
    comma: ', ',
    confirm: 'Confirm',
    continue: 'Continue',
    delete: 'Delete',
    download: 'Download',
    edit: 'Edit',
    home: 'Home',
    hour: 'Hour',
    loadMore: 'Load More',
    month: 'Mo',
    more: 'Go explore',
    new: 'New',
    next: 'Next',
    noCompanyRecord: 'No matched companies',
    noRecord: 'No matched jobs',
    notAvailable: 'N/A',
    ok: 'OK',
    or: 'or',
    quitNow: 'Quit now',
    readMore: 'Read more',
    removeProfilePicture: 'Remove Profile Picture',
    reset: 'Reset',
    save: 'Save',
    select: 'Select',
    selected: 'Selected',
    uploadNow: 'Upload now',
  },
  header: {
    accountTitle: 'Account',
  },
  homepage: {
    link: 'Moovup homepage',
    metaDescription:
      "Hong Kong's front-line jobs search & match platform. Search and apply {numberOfJobs} vacancies, including retail, food & beverage, logistic & transport, production & packaging, event & promotion and customer services jobs in Hong Kong. Create you online resume for job matching and start your free job hunting at Moovup.",
    recList: 'Trending jobs',
  },
  input: {
    change: 'Change',
    companyName: 'Brand / Display Name',
    contactPerson: 'Name',
    email: 'Email Address',
    fileFormats: 'Please only upload {formats} format.',
    fileSize: 'Max file upload size is 10MB.',
    jobType: 'Job Category',
    legalName: 'Legal Business Name',
    password: 'Password',
    passwordConfirm: 'Confirm Password',
    telephone: 'Mobile Number',
    upload: 'Upload',
  },
  jobAlert: {
    createAlertContent: 'Download Moovup app to get the latest jobs',
    createTitle: 'Notify me of new openings with matching criteria',
    subscribe: 'Subscribe',
    title: 'My Job Alert',
  },
  jobCategory: {
    'Beauty & Fitness': 'Beauty & Fitness',
    Cleaning: 'Cleaning',
    Construction: 'Construction',
    'Customer Services': 'Customer Services',
    Education: 'Education',
    'Events & Promotion': 'Events & Promotion',
    'Food & Beverage': 'Food & Beverage',
    'Health Services': 'Health Services',
    'Hotel & Club House': 'Hotel & Club House',
    'Logistics & Transport': 'Logistics & Transport',
    Maintenance: 'Maintenance',
    nearby: 'Nearby Jobs',
    Office: 'Office',
    Others: 'Others',
    'Production & Packaging': 'Production & Packaging',
    'Property Mgt & Security': 'Property Management',
    'Retail Shop': 'Retail Shop',
    'Sales / Agents': 'Sales / Agents',
  },
  jobDetail: {
    allowance: 'Allowance & Benefit',
    careerLevelEntry: 'Entry Level',
    careerLevelMiddle: 'Middle Level',
    careerLevelSenior: 'Senior Level',
    careerLevelTop: 'Top Level',
    cert: 'Certs & Licenses:',
    companyAbout: 'About Company',
    companyMoreJobs: 'Other Vacancies',
    daysPerWeek: '{days} day/wk | {days} days/wk',
    daysPerWeekFull: '{days} day per week | {days} days per week',
    description:
      '{employerName} is hiring {employmentType} {jobTitle}, {salary}, working in {district}, {workingDays}{workingHours}, apply this job or other {jobType} jobs on Moovup.',
    downloadApp: 'Download to Apply',
    education: 'Education:',
    emptyAboutCompany: 'Not available',
    endDate: 'End date',
    hoursPerDay: '{hours} hr/day | {hours} hrs/day',
    hoursPerDayFull: '{hours} hour per day | {hours} hours per day',
    jobAdsLabel: 'Promoted',
    jobDuty: 'Job Duty',
    languageBasic: 'Basic',
    languageExcellent: 'Excellent',
    languages: 'Language:',
    location: 'Work Address',
    mapLink: 'View work location in Google Maps',
    // eslint-disable-next-line no-template-curly-in-string
    metaSalaryPerHour: '${number}/hour',
    // eslint-disable-next-line no-template-curly-in-string
    metaSalaryPerMonth: '${number}k/month',
    postedAMonthAgo: 'A month ago',
    postedAWeekAgo: 'A week ago',
    postedJustNow: 'Just now',
    postedMoreThanThreeMonthsAgo: 'More than 3 months ago',
    postedThisWeek: 'This week',
    postedThreeMonthsAgo: '3 months ago',
    postedTwoMonthsAgo: '2 months ago',
    relatedJobs: 'Similar Jobs',
    requirement: 'Requirement',
    rotationShift: 'Rotation required',
    saturdayRequired: 'Alternate Saturday work is required',
    showMore: 'Show all',
    skill: 'Skill:',
    spokenSkills: 'Spoken Skill',
    startDate: 'Start date',
    timetable: 'Timetable',
    title:
      "{employmentType} {jobTitle} | {employerName} hiring {employmentType} vacancies in {district} | Moovup - Hong Kong's front-line jobs search & match platform",
    vacancy: '1 Vacancy | {n} Vacancies',
    writtenSkills: 'Written Skill',
  },
  jobRelated: {
    company: 'Other Vacancies',
    related: 'Similar Jobs',
  },
  jobType: {
    'Beauty & Fitness': {
      'Beauty Consultant': 'Beauty Consultant',
      Cosmetologist: 'Cosmetologist',
      'Fitness Trainer': 'Fitness Trainer',
      'Makeup Artist': 'Makeup Artist',
      Manicurist: 'Manicurist',
      Masseur: 'Masseur',
      'Other Beauty & Fitness Jobs': 'Other Beauty & Fitness Jobs',
    },
    Cleaning: {
      Airport: 'Airport',
      Hospitals: 'Hospitals',
      'Hotels and Club Houses': 'Hotels and Club Houses',
      'Office Buildings': 'Office Buildings',
      'Other Cleaning Jobs': 'Other Cleaning Jobs',
      'Other Premises': 'Other Premises',
      'Public Areas': 'Public Areas',
      'Residential Buildings': 'Residential Buildings',
      Restaurants: 'Restaurants',
      Schools: 'Schools',
      'Shops and Malls': 'Shops and Malls',
    },
    Construction: {
      'Bar Bending and Fixing': 'Bar Bending and Fixing',
      'Bricklaying / Plastering / Tiling': 'Bricklaying / Plastering / Tiling',
      Carpentry: 'Carpentry',
      'Civil Engineering': 'Civil Engineering',
      Concreting: 'Concreting',
      'Drainlaying / Piplaying': 'Drainlaying / Piplaying',
      'Electrical Work': 'Electrical Work',
      'Equipment Operation': 'Equipment Operation',
      'Metal Work & Welding': 'Metal Work & Welding',
      'Other Construction Jobs': 'Other Construction Jobs',
      'Painting & Decoration': 'Painting & Decoration',
      Plumbing: 'Plumbing',
      Scaffolding: 'Scaffolding',
      Surveying: 'Surveying',
      'Works Supervisor': 'Works Supervisor',
    },
    'Customer Services': {
      'Customer Services': 'Customer Services',
      'Tele Customer Services': 'Tele Customer Services',
    },
    Education: {
      'After-school Tutoring': 'After-school Tutoring',
      'Arts & Crafts, Hobbies': 'Arts & Crafts, Hobbies',
      Chinese: 'Chinese',
      English: 'English',
      'Exam Personnel': 'Exam Personnel',
      Kindergarten: 'Kindergarten',
      Maths: 'Maths',
      Music: 'Music',
      'Other Education Jobs': 'Other Education Jobs',
      'Other Subjects': 'Other Subjects',
      Science: 'Science',
      Sports: 'Sports',
      STEM: 'STEM',
      'Teacher Assistant': 'Teacher Assistant',
    },
    'Events & Promotion': {
      'Event Helper': 'Event Helper',
      Fundraiser: 'Fundraiser',
      'Other Events & Promotion Jobs': 'Other Events & Promotion Jobs',
      'Promoter - Events': 'Promoter - Events',
      'Promoter - In-store': 'Promoter - In-store',
      'Survey and Research': 'Survey and Research',
    },
    'Food & Beverage': {
      Barista: 'Barista',
      Bartender: 'Bartender',
      'Chef / Assistant - Chinese Cuisine': 'Chef / Assistant - Chinese Cuisine',
      'Chef / Assistant - Dessert / Bakery': 'Chef / Assistant - Dessert / Bakery',
      'Chef / Assistant - Japanese & Korean': 'Chef / Assistant - Japanese & Korean',
      'Chef / Assistant - Others': 'Chef / Assistant - Others',
      'Chef / Assistant - Western Cuisine': 'Chef / Assistant - Western Cuisine',
      Dishwashing: 'Dishwashing',
      'Drinks / Snacks Stalls': 'Drinks / Snacks Stalls',
      'Drinks Counter': 'Drinks Counter',
      'Fine Dining Chef / Assistant - Chinese Cuisine': 'Fine Dining Chef / Assistant - Chinese Cuisine',
      'Fine Dining Chef / Assistant - Dessert / Bakery': 'Fine Dining Chef / Assistant - Dessert / Bakery',
      'Fine Dining Chef / Assistant - Japanese & Korean': 'Fine Dining Chef / Assistant - Japanese & Korean',
      'Fine Dining Chef / Assistant - Other Cuisines': 'Fine Dining Chef / Assistant - Other Cuisines',
      'Fine Dining Chef / Assistant - Western Cuisine': 'Fine Dining Chef / Assistant - Western Cuisine',
      'Fine Dining Waiter & Attendant - Chinese Cuisine': 'Fine Dining Waiter & Attendant - Chinese Cuisine',
      'Fine Dining Waiter & Attendant - Dessert / Bakery': 'Fine Dining Waiter & Attendant - Dessert / Bakery',
      'Fine Dining Waiter & Attendant - Japanese & Korean': 'Fine Dining Waiter & Attendant - Japanese & Korean',
      'Fine Dining Waiter & Attendant - Others': 'Fine Dining Waiter & Attendant - Others',
      'Fine Dining Waiter & Attendant - Western Cuisine': 'Fine Dining Waiter & Attendant - Western Cuisine',
      'Other F&B Jobs': 'Other F&B Jobs',
      'Subtitle - Chef / Assistant': 'Chef / Assistant',
      'Subtitle - Drinks': 'Drinks',
      'Subtitle - Fine Dining Chef / Assistant': 'Fine Dining Chef / Assistant',
      'Subtitle - Fine Dining Waiter & Attendant': 'Fine Dining Waiter & Attendant',
      'Subtitle - Others': 'Others',
      'Subtitle - Waiter & Attendant': 'Waiter & Attendant',
      'Waiter & Attendant - Chinese Cuisine': 'Waiter & Attendant - Chinese Cuisine',
      'Waiter & Attendant - Dessert': 'Waiter & Attendant - Dessert',
      'Waiter & Attendant - Others': 'Waiter & Attendant - Others',
      'Waiter & Attendant - Western Cuisine': 'Waiter & Attendant - Western Cuisine',
      'Waiter & Attendent - Japanese & Korean': 'Waiter & Attendant - Japanese & Korean',
    },
    'Health Services': {
      'Care-related Support Worker': 'Care-related Support Worker',
      'Clinic Assistant / Admin': 'Clinic Assistant / Admin',
      Midwife: 'Midwife',
      Nurse: 'Nurse',
      'Nursing Assistant': 'Nursing Assistant',
      'Other Health Services Jobs': 'Other Health Services Jobs',
      'Postnatal Care': 'Postnatal Care',
    },
    'Hotel & Club House': {
      Butler: 'Butler',
      'Clubhouse Assistant': 'Clubhouse Assistant',
      Concierge: 'Concierge',
      'Front Desk / Guest Relation': 'Front Desk / Guest Relation',
      Housekeeping: 'Housekeeping',
      'Life Guard': 'Life Guard',
      'Linen Attendant': 'Linen Attendant',
      'Other Hotel & Club House Jobs': 'Other Hotel & Club House Jobs',
      'Recreation Attendant': 'Recreation Attendant',
    },
    'Logistics & Transport': {
      'Airport / Shipping - Baggage Attendant': 'Airport / Shipping - Baggage Attendant',
      'Airport / Shipping - Cargo Operation': 'Airport / Shipping - Cargo Operation',
      'Airport / Shipping - Driver': 'Airport / Shipping - Driver',
      'Airport / Shipping - Machine Operator': 'Airport / Shipping - Machine Operator',
      Courier: 'Courier',
      'Courier - Driver': 'Courier - Driver',
      'Food Delivery': 'Food Delivery',
      'Food Delivery - Driver': 'Food Delivery - Driver',
      'Logistics Assistant': 'Logistics Assistant',
      'Other Logistics & Transport Jobs': 'Other Logistics & Transport Jobs',
      'Private Driver': 'Private Driver',
      'Public Transportation - Driver': 'Public Transportation - Driver',
      'Public Transportation - Station Attendant': 'Public Transportation - Station Attendant',
      'Subtitle - Airport / Shipping': 'Airport / Shipping',
      'Subtitle - Others': 'Others',
      'Subtitle - Public Transportation': 'Public Transportation',
      'Valet Parking Attendant': 'Valet Parking Attendant',
      'Warehouse Assistant': 'Warehouse Assistant',
    },
    Maintenance: {
      'Air Conditioning': 'Air Conditioning',
      'Building Maintenance': 'Building Maintenance',
      Cars: 'Cars',
      Drainage: 'Drainage',
      'Electrical Technician': 'Electrical Technician',
      Gardening: 'Gardening',
      'Other Maintenance Jobs': 'Other Maintenance Jobs',
      'Pest Control': 'Pest Control',
      'Plumbing / Electrician': 'Plumbing / Electrician',
      Renovation: 'Renovation',
    },
    Office: {
      Accounts: 'Accounts',
      Clerk: 'Clerk',
      'Data Entry': 'Data Entry',
      Design: 'Design',
      'HR & Admin': 'HR & Admin',
      Intern: 'Intern',
      Marketing: 'Marketing',
      Merchandiser: 'Merchandiser',
      'Office Assistant': 'Office Assistant',
      'Other Office Jobs': 'Other Office Jobs',
      'Procurement - Food & Beverage': 'Procurement - Food & Beverage',
      'Procurement - Non-food': 'Procurement - Non-food',
      Receptionist: 'Receptionist',
      Secretary: 'Secretary',
    },
    Others: {
      'Other Job': 'Others',
    },
    'Production & Packaging': {
      'Food Packaging': 'Food Packaging',
      'Food Production': 'Food Production',
      'Laundry Factory': 'Laundry Factory',
      Manufacturing: 'Manufacturing',
      'Medicine Packaging': 'Medicine Packaging',
      'Medicine Production': 'Medicine Production',
      Others: 'Others',
    },
    'Property Mgt & Security': {
      Airport: 'Airport',
      'Hotels and Club Houses': 'Hotels and Club Houses',
      'Office Buildings': 'Office Buildings',
      'Other Premises': 'Other Premises',
      'Other Security Jobs': 'Other Security Jobs',
      'Property Management': 'Property Management',
      'Public Areas': 'Public Areas',
      'Residential Buildings': 'Residential Buildings',
      Schools: 'Schools',
      'Security Box Carrier': 'Security Box Carrier',
      'Security Car Driver': 'Security Car Driver',
      'Shops and Malls': 'Shops and Malls',
    },
    'Retail Shop': {
      Cashier: 'Cashier',
      'Other Retail Jobs': 'Other Retail Jobs',
      'Shopkeeper - Clothing': 'Shopkeeper - Clothing',
      'Shopkeeper - Electric Products': 'Shopkeeper - Electric Products',
      'Shopkeeper - Food': 'Shopkeeper - Food',
      'Shopkeeper - Household Products': 'Shopkeeper - Household Products',
      'Shopkeeper - Luxury': 'Shopkeeper - Luxury',
      'Shopkeeper - Others': 'Shopkeeper - Others',
      'Shopkeeper - Personal Care Products': 'Shopkeeper - Personal Care Products',
      'Stockkeeper - Clothing': 'Stockkeeper - Clothing',
      'Stockkeeper - Electric Products': 'Stockkeeper - Electric Products',
      'Stockkeeper - Food': 'Stockkeeper - Food',
      'Stockkeeper - Household Products': 'Stockkeeper - Household Products',
      'Stockkeeper - Luxury': 'Stockkeeper - Luxury',
      'Stockkeeper - Others': 'Stockkeeper - Others',
      'Stockkeeper - Personal Care Products': 'Stockkeeper - Personal Care Products',
      'Subtitle - Others': 'Others',
      'Subtitle - Shopkeeper': 'Shopkeeper',
      'Subtitle - Stockkeeper': 'Stockkeeper',
    },
    'Sales / Agents': {
      'Business Promotion': 'Business Promotion',
      'Food Wholesales': 'Food Wholesales',
      'General Sales': 'General Sales',
      Insurance: 'Insurance',
      'Real Estate': 'Real Estate',
    },
  },
  language: {
    cantonese: 'Cantonese',
    chinese: 'Chinese',
    english: 'English',
    mandarin: 'Mandarin',
  },
  link: {
    aboutMoovup: 'About Moovup',
    aboutUs: 'Free job search app',
    back: 'Go back',
    careerBlogs: 'Career blogs',
    communityGuideline: 'Community Guidelines',
    contact: 'Contact us',
    downloadApp: 'Download Moovup app',
    employerFAQ: 'Employer FAQ',
    jobAlert: 'Job alert',
    jobSearch: 'Job search',
    latestNews: 'Latest news',
    myResume: 'My profile (online resume)',
    ourService: 'Our services',
    postJob: 'For Employers',
    privacyPolicy: 'Privacy policy',
    recruitmentAdvice: 'Recruitment Advice',
    recruitmentDay: 'Recruitment Day',
    recruitmentPlatform: 'Recruitment platform / Post jobs',
    recruitmentSolutions: 'Recruitment solutions',
    temporaryMatching: 'Temporary worker matching',
    tnc: 'Terms & conditions',
    userBenefitProgramme: 'User Benefit',
    videos: 'Videos',
  },
  login: {
    actionMain: 'Log in/Signup',
    actionOptional: 'Register for Free',
    otpMsg: 'Enter the Verification Code',
    resend: 'Resend Code',
    sendVoice: 'Try Voice Call',
    sentMsg: 'Sent Verification Code to',
    title: 'Job Seeker Signup/Login',
  },
  logout: {
    actionMain: 'Log Out',
  },
  metadata: {
    description:
      "Hong Kong's front-line jobs search & match platform. Search and apply over 4,000 retail, food & beverage, logistic & transport, production & packaging, event & promotion and customer services jobs in Hong Kong. Create your online resume for job matching and start your free job hunting at Moovup. ",
    job: ' job opening',
    keyword:
      'job, jobs, job search, find job, post job, work, 搵工, 求職, 招聘, 工作, recruit, recruitment, jobseekers, employment, employment agency, employer, employee, part time, full time, 求職網, 搵工網, 職位空缺, 全職, 兼職, 履歷表, career news, resume, cover letter, CV sample',
    title: "Hong Kong's front-line jobs search & match platform - Moovup",
    titleSubfix: 'Moovup',
  },
  notification: {
    notificationAllJobs: 'All Jobs',
    notificationNoUpdate: 'You have no unread notifications',
    notificationTypeJobAlertMatched: "You've new job alert",
    notificationTypeJobClosed:
      'This job post is closed. If you have not been contacted shortly, please consider applying for other jobs',
    qrCodeAlertReminder:
      'Scan QR code to download Moovup app - \nyou can create a job alert and receive updates\non new job openings every morning.',
    qrCodeNotificationReminder:
      'Scan QR code to download Moovup app - \n\nyou can get the latest application status and openings.',
    title: 'Notification',
  },
  profile: {
    addLicense: 'Add License',
    addSkill: 'Add Skill',
    changeTelPopupContent:
      "To change your mobile number, download the latest version of the Moovup app and go to 'Settings'.",
    createMyProfile: 'Create My Profile',
    createProfile: 'Create a profile',
    createProfileHintContent: 'Get your profile noticed by employers in 5 simple steps',
    createProfileHintTitle: 'Jobs are waiting for you!',
    createProfilePopupContent: 'Create one now!',
    editProfile: 'Edit My Profile',
    education: 'Education',
    fillInName: 'Fill in your name',
    headshotDesc: 'Upload a professional photo which can show your full frontal face',
    headshotWarning: '🤗 Avoid using filter, group picture, complex background',
    inputLanguage: 'Input Language',
    inputLicensesHint: 'E.g. Driving Licenses, IELTS, First Aid',
    inputSkillHint: 'E.g. Sewing, Design',
    introductionHint: 'Tell me something about yourself',
    languageSkill: 'Language Skills',
    licenseNSkill: 'Licenses and Skills',
    messageToEmployer: 'Message To Employer',
    messageToEmployerHintContent: 'Tailor for specific job, show your personality, be professional and concise',
    messageToEmployerHintTitle: 'Something more to tell the employer?',
    messageToEmployerHintTypo: '🤗 Remember to double check for typos',
    messageToEmployerPlaceholder: 'Tell me something about yourself',
    messageToEmployerPromptContent: 'Writing a message to employer to enhance your first impression',
    messageToEmployerPromptTitle: 'Something more to tell the employer?',
    myProfile: 'My Profile',
    newToWork: 'No Work Experience',
    otherSkill: 'Other Skills',
    qualificationTitle: 'Qualification and Licenses',
    skipWritingMessageToEmployer: 'Skip for later',
    spokenSkills: 'Spoken Language',
    upload: 'Upload',
    workExperienceTitle: 'Work Experience',
    writeMessageToEmployer: 'Write a message',
    writtenSkills: 'Written Language',
  },
  search: {
    advance: 'Advanced',
    filterAfternoon: 'Afternoon',
    filterDayTime: 'Daytime',
    filterDistrict: 'District',
    filterEmploymentType: 'Employment',
    filterEvening: 'Evening',
    filterJobCategory: 'Category',
    filterOvernight: 'Overnight',
    filterSalaryMonthlyRate: 'Salary (Full Time)',
    filterSalaryPartTimeHourlyRate: 'Salary (Part Time)',
    filterSalaryTempHourlyRate: 'Salary (Temp)',
    filterTimeWeekdays: 'Weekdays',
    filterTimeWeekend: 'Weekend',
    filterViewResult: 'View {count} result | View {count} results',
    filterWorkingDay: 'Working Days',
    filterWorkingTime: 'Working Time',
    hint: 'Search',
    jobAlertMaxDayPerWeek: '{number}hr/day',
    jobAlertMaxHourPerDay: '{number}day/week',
    // eslint-disable-next-line no-template-curly-in-string
    jobAlertMoneyPerHour: '${number}+/hr',
    // eslint-disable-next-line no-template-curly-in-string
    jobAlertMoneyPerMonth: '${number}K+/Mo',
    metaDescription: ' | Moovup | Create online CV and apply now!',
    metaDistrictDescription:
      'Search and apply over {numberOfJobs} Job Vacancies in {district}, Hong Kong. Create the online resume for job matching and receive latest job notification on Moovup website or mobile app.',
    metaDistrictTitle:
      "{numberOfJobs} {district} vacancies, {searchDate} | Moovup - Hong Kong's front-line jobs search & match platform",
    metaEmploymentOrJobTypeDescription:
      'Search and apply over {numberOfJobs} {employmentType} {jobType} Job Vacancies in Hong Kong. Create the online resume for job matching and receive latest job notification on Moovup website or mobile app.',
    metaEmploymentOrJobTypeTitle:
      "{numberOfJobs} {employmentType} {jobType} vacancies | Moovup - Hong Kong's front-line jobs search & match platform",
    metaKeyword:
      '全職, 兼職, 臨時工, 搵工, 求職, 招聘, 工作, recruit, recruitment, jobseekers, employment, employment agency, employer, employee, part time, full time, 求職網, 搵工網, 職位空缺',
    metaKeywordDistrict:
      '屯門, 元朗, 天水圍, 油尖旺, 灣仔, 銅鑼灣, 新界西, 新界東, 香港島, 九龍, 大嶼山, 機場, 搵工, 求職, 招聘, 工作, recruit, recruitment, jobseekers, employment, employment agency, employer, employee, part time, full time, 求職網, 搵工網, 職位空缺, 全職, 兼職, 臨時工',
    metaTitleSubfix: 'Moovup',
    nearByDisable:
      'If you would like to search nearby jobs, please allow accessing your location.\nSteps:\n1) Go to Browser setting > Privacy and security  > Site setting  >  Location\n2) Allow accessing your location\n',
    resultCompanyJobsTitle: '{total} Job Vacancy of {company} | {total} Job Vacancies of {company}',
    resultParamTitle: '{title} Job Vacancies | {total} {title} Job Vacancy | {total} {title} Job Vacancies',
    resultTitle: '{total} Job Vacancy | {total} Job Vacancies',
    selectAll: 'Not Specified',
  },
  seeker: {
    title: 'Job Seeker',
  },
  sideMenu: {
    imEmployer: 'I am an Employer',
    learnMore: 'Learn More',
    login: 'Login',
    personalizedJobExperience: 'To start your personalized job seeking experience',
    postJob: 'Post a Job',
  },
  signup: {
    consentAgree: 'I agree to the',
    consentAnd: 'and',
  },
  skill: {
    chinese_word_processing: 'Chinese Word Processing',
    driving: 'Driving',
    microsoft_excel: 'Microsoft Excel',
    microsoft_word: 'Microsoft Word',
  },
  workexp: {
    addWorkExp: 'Add Work Experience',
    companyName: 'Company Name',
    employmentType: 'Employment Type:',
    endDate: 'End Date',
    fillWorkExp: 'Fill in Work Experience',
    jobCategory: 'Job Category',
    jobDescription: 'Job Description',
    jobDescriptionHint: '🤗 Detailed job description (e.g. job duties) can help you reach right employers',
    jobPosition: 'Job Position',
    month: 'Month',
    now: 'Now',
    positionName: 'Position Name',
    removeWorkExperienceContent: 'Are you sure to delete this experience?',
    removeWorkExperienceTitle: 'Delete Experience',
    startDate: 'Start Date',
    workExperienceHintContent: 'You can always add work experience later in your profile',
    workExperienceHintTitle: 'Skip for now?',
    year: 'Year',
  },
}
