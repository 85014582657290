/**
 * Mixin for handling platform
 */
export default {
  computed: {
    isMobile() {
      return this.$store.getters['device/isMobile']
    },
  },
}
