import { unregisterAllServiceWorkers } from '../libs/messaging/firebase'

/**
 * NuxtJS plugin for push messaging
 */
export default function () {
  // On client side,
  // unregister all service workers in this domain
  if (process.client) {
    unregisterAllServiceWorkers()
  }
}
