<template>
  <div class="app">
    <loading :class="loadingClass" />
    <app-header :has-search-bar="showSearchBar" @on-search="onTextSearch" />
    <div id="pageScrollContainer" class="page">
      <nuxt class="page__main" />
      <app-footer />
    </div>
    <error-box :message="errorMessage" :show="isShowError" :action="$t('general.ok')" @action="dismissError" />
  </div>
</template>

<script>
import AuthMonitorMixin from '../mixins/auth/monitor'
import DefaultMixin from '../mixins/layout/default'
import ErrorBox from '../components/error/errorBox'
import ErrorBoxMixin from '../mixins/error/errorBox'
import TrackingMixin from '../mixins/layout/tracking'

export default {
  components: {
    'error-box': ErrorBox,
  },
  data() {
    return {
      showSearchBar: false,
    }
  },
  mixins: [AuthMonitorMixin, DefaultMixin, ErrorBoxMixin, TrackingMixin],
  name: 'DefaultNoSearchActionLayout',
}
</script>

<style lang="postcss" scoped>
.ftr {
  @apply mt-10;
}
.loading {
  @apply hidden;
}

@screen desktop {
  .ftr {
    @apply mt-12;
  }
}

@screen mobile {
  .page:not(.page--full) {
    @apply h-full;
  }
}
</style>
