import GtagTracker from './gtag'

export default class GoogleAdTracker extends GtagTracker {
  // eslint-disable-next-line no-useless-constructor
  constructor(gtag, id) {
    super(gtag, id)
  }

  deferInit() {
    // Only initialize once
    if (!this._deferInitDone) {
      this._gtag('config', this._id, {
        /**
         * Disable ad personalisation,
         * ref: https://developers.google.com/analytics/devguides/collection/gtagjs/display-features#disable_advertising_personalization
         */
        allow_ad_personalization_signals: false,
        send_page_view: false,
        transport_type: 'beacon',
      })
      super.deferInit()
    }
  }

  sendConversion(conversionId) {
    this._gtag('event', 'conversion', {
      send_to: `${this._id}/${conversionId}`,
    })
  }

  setUserId(userId) {
    this._userId = userId
  }

  setAppVersion(version) {
    this._version = version
  }
}
