import _get from 'lodash/get'
import AuthMixin from '../auth'
import ErrorBoxMixin from '../../mixins/error/errorBox'
import errorKeys from '../../libs/errorKeys'
import FormMixin from '.'
import { postSeekerGQL } from '../../api/octo/graphql'

/**
 * Mixin for forms connecting to *graphql* endpoint
 */
export default {
  computed: {
    /**
     * Returns graphql variables
     * based on current operation
     */
    gqlVariables() {
      return {}
    },
  },
  data() {
    return {
      /**
       * Current graphql operation name
       */
      gqlOperation: 'default',

      /**
       * Graphql queries,
       * supports multiple operations in one form
       */
      gqlQueries: {
        default: '',
      },
    }
  },
  methods: {
    /**
     * Globally redirect user based on the given formError set from the GQL response
     */
    _redirectIfNeeded() {
      if (this.formError === errorKeys.E100049) {
        this.$router.push({
          path: this.localePathName('/account-restore'),
        })
      }
    },
    /**
     * Wrapper function for submitting form
     * as *graphql* request,
     * handles graphql errors
     * @param {string} operation
     */
    async submitGql(operation, skipValidation = false) {
      // Set operation name if provided
      if (operation) {
        this.gqlOperation = operation
      }

      // Execute submit function and await for result
      const res = await this.submitForm(this.submitGqlFunc, { skipValidation })

      const errorCode = _get(res, 'error.payload.errors[0].extensions.error_code')
      if (errorCode) {
        // Map first graphql error code into message
        this.formError = errorKeys[`E${errorCode}`]
        this._redirectIfNeeded()
      }
      return res
    },
    /**
     * Submit graphql request to
     * Octo employer *graphql* endpoint
     */
    submitGqlFunc() {
      const query = this.gqlQueries[this.gqlOperation]
      if (query) {
        return postSeekerGQL(query, this.gqlVariables, this.token)
      }
    },
  },
  mixins: [AuthMixin, ErrorBoxMixin, FormMixin],
}
