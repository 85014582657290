<template>
  <component
    :is="buttonComponent"
    class="b b--main"
    tabindex="0"
    v-bind="buttonProps"
    @click="onClick"
    @click.native="onLinkClick"
  >
    <span class="b__title">{{ title }}</span>
  </component>
</template>

<script>
import ButtonMixin from '../../mixins/button'

export default {
  mixins: [ButtonMixin],
}
</script>

<style lang="postcss" scoped>
.b.b--main {
  @apply text-red-50 bg-red-500;

  &.b--inactive {
    @apply text-red-100 bg-red-300 shadow-none;
  }
}
.b__title {
  @apply text-cbody2 font-cbody2;
}

@screen desktop {
  .b__title {
    @apply text-cbody1 font-cbody1;
  }
}
</style>
