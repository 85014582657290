<template>
  <component
    :is="buttonComponent"
    class="b b--opt"
    tabindex="0"
    v-bind="buttonProps"
    @click="onClick"
    @click.native="onLinkClick"
  >
    <span class="b__title">{{ title }}</span>
    <slot v-if="icon" name="icon" />
  </component>
</template>

<script>
import ButtonMixin from '../../mixins/button'

export default {
  mixins: [ButtonMixin],
  props: {
    icon: {
      default: false,
      type: Boolean,
    },
  },
}
</script>

<style lang="postcss" scoped>
.b.b--opt {
  @apply px-6 text-indigo bg-transparent shadow-none;

  &:not(.b--row) {
    height: 2em;
  }
  &:not(.b--row) .b__title {
    @apply leading-loose;
  }
  &.b--inactive {
    @apply text-grey-medium;
  }
}

@screen desktop {
  .b:not(.b--row) .b__title {
    @apply text-sm;
  }
}
</style>
