import { getJSON, postJSON } from '..'

/**
 * Get *Bearer* type authorization header based on provided token
 * FIXME: extract authorization header logic to *Vuex* store
 * @param {string} token
 */
export function getAuthorizationHeader(token) {
  return token ? { Authorization: `Bearer ${token}` } : {}
}

/**
 * Send GET request to Octo endpoint,
 * return JSON data
 * @param {string} path
 * @param {*} bodyJson
 * @param {string} token
 * @param {number} options.timeout
 */
export function getOcto(path, bodyJson, token, options) {
  const octoUrl = process.env.SEEKER_WEB__OCTO_URL || process.env.SEEKER_WEB__OCTO_INTERNAL_URL || ''
  return getJSON(`${octoUrl}${path}`, bodyJson, getAuthorizationHeader(token), options)
}

/**
 * Send POST request to Octo endpoint,
 * return JSON data
 * @param {string} path
 * @param {*} bodyJson
 * @param {string} token
 */
export function postOcto(path, bodyJson, token) {
  const octoUrl = process.env.SEEKER_WEB__OCTO_URL || process.env.SEEKER_WEB__OCTO_INTERNAL_URL || ''
  return postJSON(`${octoUrl}${path}`, bodyJson, getAuthorizationHeader(token), { credentials: process.env.SEEKER_WEB__OCTO_CORS_CREDENTIALS })
}

/**
 * Get new anonymous access token
 * from Octo *REST* endpoint
 * @returns {Promise<string>}
 */
export function getAnonymousToken() {
  return postOcto('create-anonymous')
}
