import _cloneDeep from 'lodash/cloneDeep'
import _isNil from 'lodash/isNil'
import _omitBy from 'lodash/omitBy'

export function removeEmptyField(object) {
  object = _omitBy(object, _isNil) // Remove null or undefined
  object = _omitBy(object, (x) => x === '') // Remove empty string
  object = _omitBy(object, (x) => Array.isArray(x) && x.length === 0) // Remove empty array
  return object
}

/**
 * Deep clone provided value
 * unless it's a *Blob*
 */
export function cloneValue(value) {
  if (typeof Blob !== 'undefined' && value instanceof Blob) {
    return value
  }
  return _cloneDeep(value)
}
