import _groupBy from 'lodash/groupBy'
import _sortBy from 'lodash/sortBy'

const NewTerritoriesWest = 'New Territories West'
// const NewTerritoriesWestID = '606e2062-bdc2-4db6-ae8f-cd08561b3677'
// const NewTerritoriesWestShortCode = '000003'
const KwaiChung = 'Kwai Chung'
const KwaiChungID = '8baa7f7b-9d2c-4347-be15-af812c11088e'
const KwaiChungShortCode = '000030'
const TsingYi = 'Tsing Yi'
const TsingYiID = '19d69cc1-1c6d-4b78-905d-0c326a95eba1'
const TsingYiShortCode = '000031'
const TsuenWan = 'Tsuen Wan'
const TsuenWanID = '4c2aac41-a5ba-43d2-a9a0-7b3cf2ca94d9'
const TsuenWanShortCode = '000032'
const TuenMun = 'Tuen Mun'
const TuenMunID = 'def5f7ab-0526-40d4-b9d5-8facc88dd355'
const TuenMunShortCode = '000033'
const TinShuiWai = 'Tin Shui Wai'
const TinShuiWaiID = '0e165f23-94c3-450f-82c7-a8d1429b0391'
const TinShuiWaiShortCode = '000034'
const YuenLong = 'Yuen Long'
const YuenLongID = '2aa8d2db-f267-47f4-beec-97b731a4c984'
const YuenLongShortCode = '000035'

// New Territories East
const NewTerritoriesEast = 'New Territories East'
// const NewTerritoriesEastID = '2395e420-e619-4fa8-b794-b4e782117345'
// const NewTerritoriesEastShortCode = '000004'
const TseungKwanO = 'Tseung Kwan O'
const TseungKwanOID = 'cef000e4-0307-4324-b6cf-fb2af0c8887f'
const TseungKwanOShortCode = '000024'
const SaiKung = 'Sai Kung'
const SaiKungID = 'f83df9d5-6655-46f4-887f-3b30fe1c62ad'
const SaiKungShortCode = '000025'
const Shatin = 'Shatin'
const ShatinID = 'f17ee2fa-e153-4e9c-bdb5-ea9dc3534cb9'
const ShatinShortCode = '000026'
const MaOnShan = 'Ma On Shan'
const MaOnShanID = 'a795fc5d-1fa0-4898-8cbd-d65e09a3a7ed'
const MaOnShanShortCode = '000027'
const TaiPo = 'Tai Po'
const TaiPoID = '63eb31bc-fc1d-4436-8b50-18bb90bf3be9'
const TaiPoShortCode = '000028'
const FanlingAndheungShui = 'Fanling & Sheung Shui'
const FanlingAndheungShuiID = 'c555aebf-0ee6-4789-8dca-d1dce37d2b73'
const FanlingAndheungShuiShortCode = '000029'

// Kowloon
const Kowloon = 'Kowloon'
// const KowloonID = '89e75185-04d9-44b1-958c-781e335115b0'
// const KowloonShortCode = '000002'
const Hunghom = 'Hunghom'
const HunghomID = '0b70e127-865d-45e3-9c42-8eb3a98eb5b7'
const HunghomShortCode = '000015'
const KowloonTong = 'Kowloon Tong'
const KowloonTongID = 'fd7091fc-08e4-4a9e-a248-2f940444021b'
const KowloonTongShortCode = '000019'
const KowloonCity = 'Kowloon City'
const KowloonCityID = '0976e47c-9855-4e6e-8a27-f245c16f2e82'
const KowloonCityShortCode = '000020'
const KowloonBay = 'Kowloon Bay'
const KowloonBayID = 'd274c0d9-1648-4cd6-84f3-a33676cc192b'
const KowloonBayShortCode = '000022'
const KwunTong = 'Kwun Tong'
const KwunTongID = '3f2347a5-f705-4c3d-9969-1bd368efc9ff'
const KwunTongShortCode = '000023'
const MeiFoo = 'Mei Foo'
const MeiFooID = '9dd9e458-5d4a-4221-b570-e8a99187d83f'
const MeiFooShortCode = '000018'
const ShamShuiPo = 'Sham Shui Po'
const ShamShuiPoID = '37ddc1e5-f7c0-4121-84af-84a7f602adf6'
const ShamShuiPoShortCode = '000017'
const WestKowloon = 'West Kowloon'
const WestKowloonID = 'b225f834-fda0-4e64-aeee-a6893108b4f1'
const WestKowloonShortCode = '000016'
const WongTaiSin = 'Wong Tai Sin'
const WongTaiSinID = 'fc3849e7-0b87-4fc1-bbee-450458735389'
const WongTaiSinShortCode = '000021'
const YauTsimMong = 'Yau Tsim Mong'
const YauTsimMongID = '25e38c34-43eb-40eb-852f-2ccc1b438087'
const YauTsimMongShortCode = '000014'

// Hong Kong Island
const HongKongIsland = 'Hong Kong Island'
// const HongKongIslandID = '5a99a112-fa33-419e-b1a7-6595f72cc29e'
// const HongKongIslandShortCode = '000001'
const Aberdeen = 'Aberdeen'
const AberdeenID = '69e07902-e089-4d3a-9b90-37d8f0e942f3'
const AberdeenShortCode = '000011'
const Central = 'Central'
const CentralID = '1d09ae94-3797-4166-9a4f-e3c9932d8f00'
const CentralShortCode = '000006'
const IslandEast = 'Island East'
const IslandEastID = 'f27f4613-077b-4c13-bc13-b75848887a57'
const IslandEastShortCode = '000012'
const IslandSouth = 'Island South'
const IslandSouthID = 'dc7bc95e-182e-4594-909d-18e3c6f9a610'
const IslandSouthShortCode = '000013'
const IslandWest = 'Island West'
const IslandWestID = 'd9eefd02-1d1d-4456-89e4-8bff7cfa62cb'
const IslandWestShortCode = '000009'
const MidLevelsAndPeak = 'Mid-Levels & Peak'
const MidLevelsAndPeakID = '23ae6d02-e7ab-4ea4-a5f9-98a7c01ce06d'
const MidLevelsAndPeakShortCode = '000008'
const PokFuLam = 'Pok Fu Lam'
const PokFuLamID = '879d7821-b180-4852-8d55-b62d288c89a9'
const PokFuLamShortCode = '000010'
const WanChaiOrCausewayBay = 'Wan Chai / Causeway Bay'
const WanChaiOrCausewayBayID = 'c4522f49-d6f4-4a85-a65f-0c9ecfd15279'
const WanChaiOrCausewayBayShortCode = '000007'

// Lantau
const Lantau = 'Lantau'
// const LantauID = '1f495f5b-3bc8-4765-a471-056138e87f89'
// const LantauShortCode = '000005'
const TungChungAndAirport = 'Tung Chung and Airport'
const TungChungAndAirportID = '9d22456e-9bef-4f23-bb79-c843b984d1f2'
const TungChungAndAirportShortCode = '000036'
const DBayAndDisney = 'DBay & Disney'
const DBayAndDisneyID = 'db063c1b-6688-421a-9986-5f75ccb9e9b7'
const DBayAndDisneyShortCode = '000037'

/**
 * Sort order of district regions
 * @type {[string]}
 */
export const regionsSortOrder = [NewTerritoriesWest, NewTerritoriesEast, Kowloon, HongKongIsland, Lantau]

/**
 * List of all districts
 * @type {[{_id: string, index: number, name: string, region: string, shortCode: string}]}
 */
export const districts = [
  // NT West
  { _id: KwaiChungID, index: 0, name: KwaiChung, region: NewTerritoriesWest, shortCode: KwaiChungShortCode },
  { _id: TsingYiID, index: 1, name: TsingYi, region: NewTerritoriesWest, shortCode: TsingYiShortCode },
  { _id: TsuenWanID, index: 2, name: TsuenWan, region: NewTerritoriesWest, shortCode: TsuenWanShortCode },
  { _id: TuenMunID, index: 3, name: TuenMun, region: NewTerritoriesWest, shortCode: TuenMunShortCode },
  { _id: TinShuiWaiID, index: 4, name: TinShuiWai, region: NewTerritoriesWest, shortCode: TinShuiWaiShortCode },
  { _id: YuenLongID, index: 5, name: YuenLong, region: NewTerritoriesWest, shortCode: YuenLongShortCode },
  // NT East
  { _id: TseungKwanOID, index: 0, name: TseungKwanO, region: NewTerritoriesEast, shortCode: TseungKwanOShortCode },
  { _id: SaiKungID, index: 1, name: SaiKung, region: NewTerritoriesEast, shortCode: SaiKungShortCode },
  { _id: ShatinID, index: 2, name: Shatin, region: NewTerritoriesEast, shortCode: ShatinShortCode },
  { _id: MaOnShanID, index: 3, name: MaOnShan, region: NewTerritoriesEast, shortCode: MaOnShanShortCode },
  { _id: TaiPoID, index: 4, name: TaiPo, region: NewTerritoriesEast, shortCode: TaiPoShortCode },
  {
    _id: FanlingAndheungShuiID,
    index: 5,
    name: FanlingAndheungShui,
    region: NewTerritoriesEast,
    shortCode: FanlingAndheungShuiShortCode,
  },
  // Kowloon
  { _id: HunghomID, index: 0, name: Hunghom, region: Kowloon, shortCode: HunghomShortCode },
  { _id: KowloonTongID, index: 1, name: KowloonTong, region: Kowloon, shortCode: KowloonTongShortCode },
  { _id: KowloonCityID, index: 2, name: KowloonCity, region: Kowloon, shortCode: KowloonCityShortCode },
  { _id: KowloonBayID, index: 3, name: KowloonBay, region: Kowloon, shortCode: KowloonBayShortCode },
  { _id: KwunTongID, index: 4, name: KwunTong, region: Kowloon, shortCode: KwunTongShortCode },
  { _id: MeiFooID, index: 5, name: MeiFoo, region: Kowloon, shortCode: MeiFooShortCode },
  { _id: ShamShuiPoID, index: 6, name: ShamShuiPo, region: Kowloon, shortCode: ShamShuiPoShortCode },
  { _id: WestKowloonID, index: 7, name: WestKowloon, region: Kowloon, shortCode: WestKowloonShortCode },
  { _id: WongTaiSinID, index: 8, name: WongTaiSin, region: Kowloon, shortCode: WongTaiSinShortCode },
  { _id: YauTsimMongID, index: 9, name: YauTsimMong, region: Kowloon, shortCode: YauTsimMongShortCode },
  // HK Island
  { _id: AberdeenID, index: 0, name: Aberdeen, region: HongKongIsland, shortCode: AberdeenShortCode },
  { _id: CentralID, index: 1, name: Central, region: HongKongIsland, shortCode: CentralShortCode },
  { _id: IslandEastID, index: 2, name: IslandEast, region: HongKongIsland, shortCode: IslandEastShortCode },
  { _id: IslandSouthID, index: 3, name: IslandSouth, region: HongKongIsland, shortCode: IslandSouthShortCode },
  { _id: IslandWestID, index: 4, name: IslandWest, region: HongKongIsland, shortCode: IslandWestShortCode },
  {
    _id: MidLevelsAndPeakID,
    index: 5,
    name: MidLevelsAndPeak,
    region: HongKongIsland,
    shortCode: MidLevelsAndPeakShortCode,
  },
  { _id: PokFuLamID, index: 6, name: PokFuLam, region: HongKongIsland, shortCode: PokFuLamShortCode },
  {
    _id: WanChaiOrCausewayBayID,
    index: 7,
    name: WanChaiOrCausewayBay,
    region: HongKongIsland,
    shortCode: WanChaiOrCausewayBayShortCode,
  },
  // Lantau
  {
    _id: TungChungAndAirportID,
    index: 0,
    name: TungChungAndAirport,
    region: Lantau,
    shortCode: TungChungAndAirportShortCode,
  },
  { _id: DBayAndDisneyID, index: 1, name: DBayAndDisney, region: Lantau, shortCode: DBayAndDisneyShortCode },
]

/**
 * Find district data by ID or short code
 * TODO: Only used for backward compatible. Replace by findDistrictByShortCode() int the future
 * @param {string} value
 * @returns {{_id: string, index: number, name: string, region: string, shortCode: string}}
 */
export function findDistrictByIDOrShortCode(value) {
  if (value) {
    return districts.find((d) => d._id === value || d.shortCode === value)
  }
  return null
}

/**
 * Find district data by Short Code
 * @param {string} districtShortCode
 * @returns {{_id: string, index: number, name: string, region: string, shortCode: string}}
 */
export function findDistrictByShortCode(districtShortCode) {
  if (districtShortCode) {
    return districts.find((d) => d.shortCode === districtShortCode)
  }
  return null
}

/**
 * Find district field by Short code and field name
 * @param {string} districtShortCode
 * @param {string} fieldName
 */
export function findDistrictFieldByShortCode(districtShortCode, fieldName) {
  const district = findDistrictByShortCode(districtShortCode)
  if (district && district[fieldName]) {
    return district[fieldName]
  }
  return null
}

/**
 * Find regions w/ districts data,
 * based on provided district Short Codes
 * @param {[string]} districtShortCodes
 * @returns {[{districts: [{_id: string, index: number, name: string, region: string, shortCode: string}],
 *             region: string}]}
 */
export function findRegionsByDistrictShortCodes(districtShortCodes = []) {
  if (Array.isArray(districtShortCodes) && districtShortCodes.length) {
    // Find district entries by ID,
    // then extract region names
    const regionNames = districtShortCodes.map((shortCode) => findDistrictFieldByShortCode(shortCode, 'region'))

    // Get all regions w/ districts
    // filter regions data by name
    return getRegionDistrictGroups().filter(({ region }) => regionNames.includes(region))
  }
  return []
}

/**
 * Get list of all regions w/ districts data
 * @returns {[{districts: [{_id: string, index: number, name: string, region: string, shortCode: string}],
 *             region: string}]}
 */
export function getRegionDistrictGroups() {
  // Group districts by region
  const regionDistrictMap = _groupBy(districts, 'region')

  // Return list of regions w/ districts data
  // based on defined order
  return regionsSortOrder
    .filter((region) => Array.isArray(regionDistrictMap[region]))
    .map((region) => ({
      districts: _sortBy(regionDistrictMap[region], 'index'),
      region,
    }))
}

/**
 * Format district data into localisation key
 * @param {string} name
 * @returns {string}
 */
export function getDistrictLocaleKey({ name } = {}) {
  if (name) {
    return `district.${name}`
  }
  return ''
}

/**
 * Format region name into localisation key
 * @param {string} region
 * @returns {string}
 */
export function getDistrictRegionLocaleKey(region) {
  if (region) {
    return `district.${region}`
  }
  return ''
}

/**
 * Sort districts
 * TODO: confirm sort order
 * @param {Array} districtsList
 * @returns {[{_id: string, index: number, name: string, region: string, shortCode: string}]}
 */
export function sortDistricts(districtsList = []) {
  return _sortBy(districtsList, 'name')
}
