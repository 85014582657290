<template>
  <div class="ftr-us">
    <span class="ftr-us__title">
      {{ $t('footer.followUs') }}
    </span>
    <div class="ftr-us__main">
      <app-footer-social-links />
      <div class="ftr-us__qrcode">
        <span class="ftr-us__download">{{ $t('link.downloadApp') }}</span>
        <img class="ftr-us__qrcode-img" loading="lazy" :alt="qrCodeTitle" :src="imgSrc" :title="qrCodeTitle" />
      </div>
      <app-footer-store-links class="ftr-us__store" />
      <div v-show="languageLink.length" class="ftr-us__links">
        <svg-language class="ftr-us__lang-icon svg-fill" />
        <app-link v-for="(link, i) in languageLink" :key="`FooterTopLink${i}`" class="ftr__link" v-bind="link" />
      </div>
    </div>
  </div>
</template>

<script>
import AppFooterSocialLinks from './appFooterSocialLinks'
import AppFooterStoreLinks from './appFooterStoreLinks'
import AppLink from '../appLink'
import SvgLanguage from '../../assets/svg/language.svg?inline'

export default {
  components: {
    'app-footer-social-links': AppFooterSocialLinks,
    'app-footer-store-links': AppFooterStoreLinks,
    'app-link': AppLink,
    'svg-language': SvgLanguage,
  },
  computed: {
    languageLink() {
      return this.altLocaleLinks
    },
    qrCodeTitle() {
      return this.$t('metadata.title')
    },
  },
  data() {
    return {
      imgSrc: `${process.env.SEEKER_WEB__S3_PREFIX}/img/footer-qrcode.png`,
    }
  },
}
</script>

<style lang="postcss" scoped>
.ftr-us {
  @apply flex flex-col items-start;
  & .ftr-us__links {
    @apply flex items-center;
  }
  & .ftr-social-links {
    @apply justify-start;
  }
  & .ftr-us__store {
    @apply flex flex-row;
  }
  & .ftr-us__qrcode {
    @apply flex flex-col;
    @apply mb-4;
  }
}
.ftr-us__title {
  @apply text-cplain-action2 font-cplain-action2 font-bold mb-4;
}
.ftr-us__download {
  @apply text-cbody2 font-cbody2 mb-4;
}

.ftr-us__qrcode-img {
  width: 96px;
  height: 96px;
}

.ftr-us__store-apple {
  width: 144px;
  height: 48px;
}
.ftr-us__store-google {
  width: 162px;
  height: 48px;
}
.ftr-us__lang-icon {
  @apply mr-2 text-grey-900;
  width: 18px;
  height: 16px;
}

@screen mobile {
  .ftr-us {
    & .ftr-us__links {
      @apply mb-7;
    }
  }
  .ftr-us {
    & .ftr-us__qrcode {
      @apply hidden;
    }
  }
  .ftr-social-links {
    @apply mb-6;
  }
  .ftr-us__store {
    @apply mb-8;
  }
}

@screen desktop {
  .ftr-social-links {
    @apply mb-4;
  }
  .ftr-us {
    & .ftr-us__store {
      @apply hidden;
    }
  }
}
</style>
