<template>
  <span class="emp-type" :class="empTypeClass">{{ empTypeLabel }}</span>
</template>

<script>
import { findEmploymentTypeFieldByName, getEmploymentTypeLocaleKey } from '../libs/mapping/employmentType'

export default {
  computed: {
    empTypeClass() {
      const shortCode = findEmploymentTypeFieldByName(this.name, 'shortCode')
      if (shortCode) {
        return `emp-type--${shortCode}`
      }
      return ''
    },
    empTypeLabel() {
      return this.$t(getEmploymentTypeLocaleKey(this.name)) || ''
    },
  },
  props: {
    name: {
      required: true,
      type: String,
    },
  },
}
</script>

<style lang="postcss" scoped>
.emp-type {
  @apply inline-block rounded leading-emp-type text-center text-black;
  width: 5.5em;

  &.emp-type--ct {
    @apply bg-blue;
  }
  &.emp-type--ft {
    @apply bg-orange;
  }
  &.emp-type--pt {
    @apply bg-yellow;
  }
}
</style>
