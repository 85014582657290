/**
 * Join a range into string
 * @param {string} separator Separator string (optional)
 */
export default function getRangeStr(from, to, separator = '-') {
  let range = `${from || ''}`
  // Omit end value if same as start
  if (to !== from) {
    range += `${from && to ? separator : ''}${to || ''}`
  }
  return range
}
