import _padStart from 'lodash/padStart'
import addHours from 'date-fns/addHours'
import format from 'date-fns/format'
import lightFormat from 'date-fns/lightFormat'

/**
 * Reduce function for grouping neighbouring days of week
 * together into sorted lists,
 * require parent day list to be sorted
 * @param {[[number]]} groups Groups of neighbours
 * @param {number} day
 */
const reduceDaysOfWeekByNeighbour = (groups, day) => {
  let neighbourFound = false

  // If day right after last day of the last group,
  // append day to that group
  if (groups.length) {
    const group = groups[groups.length - 1]
    if (day - group[group.length - 1] === 1) {
      group.push(day)
      neighbourFound = true
    }
  }

  // If neighbouring day not found,
  // create new day group
  if (!neighbourFound) {
    groups.push([day])
  }

  return groups
}

/**
 * Get *Date* object based on
 * provided hour offset from now
 * @param {number} hours
 */
export function hoursFromNow(hours = 0) {
  // If hour offset not *0*,
  // add to current date
  if (hours !== 0) {
    return addHours(new Date(), hours)
  }
  // Otherwise return current *Date*
  return new Date()
}

const dateFormatSEOEnglish = 'MMMM yyyy'
const dateFormatSEOChinese = "y'年'M'月'"

const seoDateFormatMap = {
  en: dateFormatSEOEnglish,
  'zh-hant': dateFormatSEOChinese,
}

/**
 * Format *Date* object into SEO date format,
 * @param {Date} date
 */
export function formatSEODate(date, locale) {
  if (isDateValid(date)) {
    const dateFormat = seoDateFormatMap[locale] || dateFormatSEOChinese
    return format(date, dateFormat)
  }
  return ''
}

/**
 * Format *Date* object into provided format
 * @param {Date} date
 */
export function formatDate(date, dateFormat = 'yyyy/MM/dd') {
  if (isDateValid(date)) {
    return lightFormat(date, dateFormat)
  }
  return ''
}

/**
 * Format *Date* object into provided format,
 * with support on timezones and locales
 * @param {Date} date
 */
export function formatLocaleDate(date, dateFormat = `yyyy-MM-dd'T'HH:mm:ssXXX`) {
  if (isDateValid(date)) {
    return format(date, dateFormat)
  }
  return ''
}

/**
 * Format *Date* object into UTC time
 * in *YYYY-MM-DDTHH:mm:ssZ* format
 * @param {Date} date
 */
export function formatUTCDate(date) {
  if (isDateValid(date)) {
    // Convert date into UTC value
    // by adding timezone offset
    const dateUTC = new Date(date.getTime() + date.getTimezoneOffset() * 60000)

    // Format offseted date into string
    // with hardcoded timezone *Z*
    return formatLocaleDate(dateUTC, `yyyy-MM-dd'T'HH:mm:ss'Z'`)
  }
  return ''
}

/**
 * Format timestamp into *HH:mm* string
 * @param {number} time Number of minutes from midnight
 */
export function formatHHmm(time) {
  if (time >= 0) {
    // Get hour in 24-hr format
    const hours = parseInt((time / 60) % 24)
    // Get minute offset
    const minutes = time % 60

    // Return HH:mm time string with padded leading zeroes
    return `${padTimeZero(hours)}:${padTimeZero(minutes)}`
  }
  return ''
}

/**
 * Group a list of days of week (0-6)
 * into groups of neighbouring days,
 * e.g. [0, 1], [3, 4, 5]
 * @param {[number]} days List of days of week
 * @returns {[[number]]}
 */
export function groupNeighbourDaysOfWeek(days) {
  if (Array.isArray(days)) {
    // Remarks: Avoid to modify argument directly. Prevent the following vue error
    // [Vue warn]: You may have an infinite update loop in a component render function.
    const tempDays = [...days]
    // Sort days first before grouping neighbours
    return tempDays.sort().reduce(reduceDaysOfWeekByNeighbour, [])
  }
  return []
}

/**
 * Check if date is valid,
 * ref: https://stackoverflow.com/a/1353711
 * @param {Date} date
 */
export function isDateValid(date) {
  return date instanceof Date && !isNaN(date)
}

/**
 * Pad leading zero to format 2-digit time string
 * @param {number} time
 */
export function padTimeZero(time) {
  return _padStart(time, 2, '0')
}

/**
 * Ensure timestamp is down to milliseconds
 * @param {number} timestamp
 */
export function getMSecTimestamp(timestamp) {
  return timestamp < 10000000000 ? timestamp * 1000 : timestamp
}
