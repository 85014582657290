/**
 * Notification *Vuex* module mutations
 */
export default {
  readNotification(state) {
    state.isShowIndicator = false
  },

  showNotification(state) {
    state.isShowIndicator = true
  },
}
