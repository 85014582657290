<template>
  <div class="app">
    <app-header @on-search="onTextSearch" />
    <div id="pageScrollContainer" class="page">
      <nuxt class="page__main" />
      <app-footer />
    </div>
  </div>
</template>

<script>
import { getPageMetadata, getPageUrlTags } from '../libs/metadata'
import AppFooter from '../components/footer/appFooter'
import AppHeader from '../components/header/appHeader'
import AuthMonitorMixin from '../mixins/auth/monitor'
import { sendJobQueryEvent } from '../libs/tracking/utils/event'
import TrackingMixin from '../mixins/layout/tracking'

export default {
  components: {
    'app-footer': AppFooter,
    'app-header': AppHeader,
  },
  computed: {
    /** Default title and description tags */
    defaultTags() {
      return getPageMetadata(this.$t('metadata.title'), this.$t('metadata.description'))
    },

    /** Page URL <link> and <meta> tags */
    urlTags() {
      return getPageUrlTags(
        // Base URL
        process.env.SEEKER_WEB__BASE_URL,
        // Current locale path
        this.$route.path,
        // Default locale path
        this.switchLocalePath(this.$i18n.defaultLocale),
      )
    },
  },
  head() {
    // Generate localization metadata
    const i18nTags = this.$nuxtI18nSeo()

    return {
      htmlAttrs: i18nTags.htmlAttrs,
      link: this.urlTags.link,
      meta: [
        ...this.defaultTags.meta,
        ...this.urlTags.meta,
        // Skip alternative locale meta tags
        // ...i18nTags.meta,
      ],
      title: this.defaultTags.title,
    }
  },
  methods: {
    onTextSearch(searchText) {
      sendJobQueryEvent(this.$eventTracker, {
        searchTerm: searchText,
        source: 'free_text_search',
      })
      this.$router.push({
        path: this.localePathName('/find-jobs'),
        query: {
          q: searchText,
        },
      })
    },
  },
  mixins: [AuthMonitorMixin, TrackingMixin],
  name: 'ContentLayout',
}
</script>

<style lang="postcss" scoped>
.app {
  @apply bg-white;
}
</style>
