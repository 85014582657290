import _groupBy from 'lodash/groupBy'
import _sortBy from 'lodash/sortBy'
import { cleanStringForURL } from './'

export const RetailShop = 'Retail Shop'
export const FoodBeverage = 'Food & Beverage'
export const EventsPromotion = 'Events & Promotion'
export const Education = 'Education'
export const Office = 'Office'
export const LogisticsTransport = 'Logistics & Transport'
export const CustomerServices = 'Customer Services'
export const BeautyFitness = 'Beauty & Fitness'
export const HotelClubHouse = 'Hotel & Club House'
export const HealthServices = 'Health Services'
export const Maintenance = 'Maintenance'
export const SalesAgents = 'Sales / Agents'
export const PropertyMgtSecurity = 'Property Mgt & Security'
export const ProductionPackaging = 'Production & Packaging'
export const Construction = 'Construction'
export const Cleaning = 'Cleaning'
export const Others = 'Others'

/**
 * Sort order of job categories
 * @type {[string]}
 */
export const jobCategoriesSortOrder = [
  RetailShop,
  FoodBeverage,
  EventsPromotion,
  Education,
  Office,
  LogisticsTransport,
  CustomerServices,
  BeautyFitness,
  HotelClubHouse,
  HealthServices,
  Maintenance,
  SalesAgents,
  PropertyMgtSecurity,
  ProductionPackaging,
  Construction,
  Cleaning,
  Others,
]

/**
 * List of all job types
 * @type {[{_id: string, category: string, index: number, isSubtitle: boolean, name: string, shortCode: string}]}
 */
export const jobTypes = [
  // Retail Shops
  { category: RetailShop, index: 0, isSubtitle: true, name: 'Subtitle - Shopkeeper' },
  {
    _id: 'b0af3799-6f38-4267-916d-6054d3c462ed',
    category: RetailShop,
    index: 1,
    name: 'Shopkeeper - Clothing',
    shortCode: 'aa',
  },
  {
    _id: '94875a06-f548-485e-955f-c846cafba8cc',
    category: RetailShop,
    index: 2,
    name: 'Shopkeeper - Food',
    shortCode: 'ab',
  },
  {
    _id: '7f6d939e-843e-4ce0-b080-d6a13863cf18',
    category: RetailShop,
    index: 3,
    name: 'Shopkeeper - Household Products',
    shortCode: 'ac',
  },
  {
    _id: '84326375-47cf-471b-8738-a60bea2a20d6',
    category: RetailShop,
    index: 4,
    name: 'Shopkeeper - Personal Care Products',
    shortCode: 'ad',
  },
  {
    _id: '9a7d9773-6ea2-48e2-813f-a806cb627263',
    category: RetailShop,
    index: 5,
    name: 'Shopkeeper - Electric Products',
    shortCode: 'ae',
  },
  {
    _id: '108f3491-7ce4-4367-a7e8-696d732256cf',
    category: RetailShop,
    index: 6,
    name: 'Shopkeeper - Luxury',
    shortCode: 'af',
  },
  {
    _id: 'c2e733b1-0553-4e50-ab2f-68d350cca9b8',
    category: RetailShop,
    index: 7,
    name: 'Shopkeeper - Others',
    shortCode: 'ag',
  },
  { category: RetailShop, index: 8, isSubtitle: true, name: 'Subtitle - Stockkeeper' },
  {
    _id: '9147c686-b1ec-462b-9537-ea0b8918fc53',
    category: RetailShop,
    index: 9,
    name: 'Stockkeeper - Clothing',
    shortCode: 'ah',
  },
  {
    _id: '4d5eed63-000f-4edb-b8ef-97dd4de089ad',
    category: RetailShop,
    index: 10,
    name: 'Stockkeeper - Food',
    shortCode: 'ai',
  },
  {
    _id: '4f426aaf-991e-428a-aa96-04aa2abf451a',
    category: RetailShop,
    index: 11,
    name: 'Stockkeeper - Household Products',
    shortCode: 'aj',
  },
  {
    _id: '85244005-3583-4919-866c-82d0885ea896',
    category: RetailShop,
    index: 12,
    name: 'Stockkeeper - Personal Care Products',
    shortCode: 'ak',
  },
  {
    _id: 'aad11374-2648-4a95-b813-6fc0744dd218',
    category: RetailShop,
    index: 13,
    name: 'Stockkeeper - Electric Products',
    shortCode: 'al',
  },
  {
    _id: '91eb6341-79bf-428e-ad65-71f1682d74c4',
    category: RetailShop,
    index: 14,
    name: 'Stockkeeper - Luxury',
    shortCode: 'am',
  },
  {
    _id: '38cb6a7d-3dc8-410d-881a-dc95264f20d6',
    category: RetailShop,
    index: 15,
    name: 'Stockkeeper - Others',
    shortCode: 'an',
  },
  { category: RetailShop, index: 16, isSubtitle: true, name: 'Subtitle - Others' },
  { _id: 'ddb338a0-cb86-4117-bf02-2ff348688596', category: RetailShop, index: 17, name: 'Cashier', shortCode: 'ao' },
  {
    _id: 'c9087054-36a4-42bc-8554-29b282196864',
    category: RetailShop,
    index: 18,
    name: 'Other Retail Jobs',
    shortCode: 'ap',
  },
  // Food & Beverage
  { category: FoodBeverage, index: 0, isSubtitle: true, name: 'Subtitle - Chef / Assistant' },
  {
    _id: '8d624096-4b32-4322-9c08-dd0212af042f',
    category: FoodBeverage,
    index: 1,
    name: 'Chef / Assistant - Chinese Cuisine',
    shortCode: 'aq',
  },
  {
    _id: 'aa69d2f8-1b0e-4eea-8efc-e1db1c7566d2',
    category: FoodBeverage,
    index: 2,
    name: 'Chef / Assistant - Western Cuisine',
    shortCode: 'ar',
  },
  {
    _id: '5db180f2-2c13-4ba1-a2ba-f508b8261e43',
    category: FoodBeverage,
    index: 3,
    name: 'Chef / Assistant - Japanese & Korean',
    shortCode: 'as',
  },
  {
    _id: 'abb379da-61e5-49fb-88ac-265d47cadaad',
    category: FoodBeverage,
    index: 4,
    name: 'Chef / Assistant - Dessert / Bakery',
    shortCode: 'at',
  },
  {
    _id: 'df6ded17-f03d-497e-933d-c5489183e931',
    category: FoodBeverage,
    index: 5,
    name: 'Chef / Assistant - Others',
    shortCode: 'au',
  },
  { category: FoodBeverage, index: 6, isSubtitle: true, name: 'Subtitle - Fine Dining Chef / Assistant' },
  {
    _id: '64603304-3311-4fa3-a84a-b2b031574f63',
    category: FoodBeverage,
    index: 7,
    name: 'Fine Dining Chef / Assistant - Chinese Cuisine',
    shortCode: 'av',
  },
  {
    _id: 'ade0007f-a7ce-4445-83fa-c76c0306518d',
    category: FoodBeverage,
    index: 8,
    name: 'Fine Dining Chef / Assistant - Western Cuisine',
    shortCode: 'aw',
  },
  {
    _id: '69c4ff30-213c-4143-af38-718f63ab97ae',
    category: FoodBeverage,
    index: 9,
    name: 'Fine Dining Chef / Assistant - Japanese & Korean',
    shortCode: 'ax',
  },
  {
    _id: '3323f5a6-59c9-4c48-ade8-663823c5a6d0',
    category: FoodBeverage,
    index: 10,
    name: 'Fine Dining Chef / Assistant - Dessert / Bakery',
    shortCode: 'ay',
  },
  {
    _id: 'cf33a834-f92f-405e-9e17-117c0cafaea5',
    category: FoodBeverage,
    index: 11,
    name: 'Fine Dining Chef / Assistant - Other Cuisines',
    shortCode: 'az',
  },
  { category: FoodBeverage, index: 12, isSubtitle: true, name: 'Subtitle - Drinks' },
  { _id: 'da772e17-28a6-4e65-9f5f-7592ddb4a425', category: FoodBeverage, index: 13, name: 'Barista', shortCode: 'a0' },
  {
    _id: '60ad0cc4-550e-47f5-b61e-d074f22ce4d1',
    category: FoodBeverage,
    index: 14,
    name: 'Bartender',
    shortCode: 'a1',
  },
  { category: FoodBeverage, index: 15, isSubtitle: true, name: 'Subtitle - Waiter & Attendant' },
  {
    _id: 'bc333e20-136e-4d0a-979c-94b9e5df3da7',
    category: FoodBeverage,
    index: 16,
    name: 'Waiter & Attendant - Chinese Cuisine',
    shortCode: 'a2',
  },
  {
    _id: '4f3e5438-708d-4af5-90db-1541c528e313',
    category: FoodBeverage,
    index: 17,
    name: 'Waiter & Attendant - Western Cuisine',
    shortCode: 'a3',
  },
  {
    _id: '78d56153-67c0-4dd2-b109-652b47623ea2',
    category: FoodBeverage,
    index: 18,
    name: 'Waiter & Attendent - Japanese & Korean',
    shortCode: 'a4',
  },
  {
    _id: 'c756ae05-be26-4218-9c97-ec38d0246ddd',
    category: FoodBeverage,
    index: 19,
    name: 'Waiter & Attendant - Dessert',
    shortCode: 'a5',
  },
  {
    _id: '2a48fb1a-ce26-405c-9753-3772c1b98a8d',
    category: FoodBeverage,
    index: 20,
    name: 'Waiter & Attendant - Others',
    shortCode: 'a6',
  },
  { category: FoodBeverage, index: 21, isSubtitle: true, name: 'Subtitle - Fine Dining Waiter & Attendant' },
  {
    _id: '39e22ad1-8f28-4ee5-b3bf-565f29d9906f',
    category: FoodBeverage,
    index: 22,
    name: 'Fine Dining Waiter & Attendant - Chinese Cuisine',
    shortCode: 'a7',
  },
  {
    _id: '8b163045-4dc4-4fc6-b4d3-52712f93971d',
    category: FoodBeverage,
    index: 23,
    name: 'Fine Dining Waiter & Attendant - Western Cuisine',
    shortCode: 'a8',
  },
  {
    _id: 'ea3127a7-7c04-465e-b40d-8544cba4e99e',
    category: FoodBeverage,
    index: 24,
    name: 'Fine Dining Waiter & Attendant - Japanese & Korean',
    shortCode: 'a9',
  },
  {
    _id: 'e87944ae-4172-4d56-8393-4e9ddb9ffad3',
    category: FoodBeverage,
    index: 25,
    name: 'Fine Dining Waiter & Attendant - Dessert / Bakery',
    shortCode: 'ba',
  },
  {
    _id: '548cbe1a-99f6-4e9a-a70c-a363ebf8ab15',
    category: FoodBeverage,
    index: 26,
    name: 'Fine Dining Waiter & Attendant - Others',
    shortCode: 'bb',
  },
  { category: FoodBeverage, index: 27, isSubtitle: true, name: 'Subtitle - Others' },
  {
    _id: '08b20cfd-b8a8-4495-8597-2a71f059fd30',
    category: FoodBeverage,
    index: 28,
    name: 'Drinks / Snacks Stalls',
    shortCode: 'bc',
  },
  {
    _id: 'a0c58b77-c99b-42b3-98c4-8b15d8c15b43',
    category: FoodBeverage,
    index: 29,
    name: 'Drinks Counter',
    shortCode: 'bd',
  },
  {
    _id: '2794aada-4f9c-4043-8c8e-41e9b2a7cb9a',
    category: FoodBeverage,
    index: 30,
    name: 'Dishwashing',
    shortCode: 'be',
  },
  {
    _id: '88f99294-66c5-40a0-babe-d4ce02386fe9',
    category: FoodBeverage,
    index: 31,
    name: 'Other F&B Jobs',
    shortCode: 'bf',
  },
  // Events & Promotion
  {
    _id: '2a3dc848-5306-486a-9ee0-0a3feb4f655a',
    category: EventsPromotion,
    index: 0,
    name: 'Promoter - Events',
    shortCode: 'bg',
  },
  {
    _id: '9dbc36ac-3956-45fe-b2dc-8833e070a099',
    category: EventsPromotion,
    index: 1,
    name: 'Promoter - In-store',
    shortCode: 'bh',
  },
  {
    _id: '07192d83-14d2-4764-b35e-22e93aebcd84',
    category: EventsPromotion,
    index: 2,
    name: 'Event Helper',
    shortCode: 'bi',
  },
  {
    _id: '944b5dfc-0627-4717-8317-1a27821fa0e2',
    category: EventsPromotion,
    index: 3,
    name: 'Survey and Research',
    shortCode: 'bj',
  },
  {
    _id: '3e0b4756-443d-4095-9b16-bc1cd4c39c6f',
    category: EventsPromotion,
    index: 4,
    name: 'Fundraiser',
    shortCode: 'bk',
  },
  {
    _id: 'b0e8db2d-aa7a-4ba2-a68f-1f2da56d121d',
    category: EventsPromotion,
    index: 5,
    name: 'Other Events & Promotion Jobs',
    shortCode: 'bl',
  },
  // Education
  { _id: '6c31f038-e813-4d86-9568-b1220eeaa260', category: Education, index: 0, name: 'Chinese', shortCode: 'bm' },
  { _id: 'e0bc7cc1-b74b-4d5b-bb5b-9fd482d52108', category: Education, index: 1, name: 'English', shortCode: 'bn' },
  { _id: '6061e18e-dd07-40da-8b98-cb2a44fa71b9', category: Education, index: 2, name: 'Science', shortCode: 'bo' },
  { _id: '345ea682-4727-4c47-940e-93d636a4fe6c', category: Education, index: 3, name: 'Maths', shortCode: 'bp' },
  { _id: 'fe6ce634-b66c-4628-8f19-c25acb9fbe02', category: Education, index: 4, name: 'STEM', shortCode: 'bq' },
  { _id: 'c2a51cd8-65f4-4ac0-a11c-03bad01ea20a', category: Education, index: 5, name: 'Music', shortCode: 'br' },
  { _id: '15c1e95a-6da3-4e5a-8099-9322e972b257', category: Education, index: 6, name: 'Sports', shortCode: 'bs' },
  {
    _id: '522b2f7e-2487-4d86-82b4-f22d97479799',
    category: Education,
    index: 7,
    name: 'Other Subjects',
    shortCode: 'bt',
  },
  {
    _id: '67b63013-c76d-48b0-80fd-7f48f36c971c',
    category: Education,
    index: 8,
    name: 'Arts & Crafts, Hobbies',
    shortCode: 'bu',
  },
  { _id: '97121bb8-3769-4e8e-a6ca-c864824fc465', category: Education, index: 9, name: 'Kindergarten', shortCode: 'bv' },
  {
    _id: 'ac685eaf-a574-418b-8177-f5b7dc70599d',
    category: Education,
    index: 10,
    name: 'After-school Tutoring',
    shortCode: 'bw',
  },
  {
    _id: 'b597db89-5398-4f7d-83b2-77d948d6082a',
    category: Education,
    index: 11,
    name: 'Teacher Assistant',
    shortCode: 'bx',
  },
  {
    _id: 'fb02adb5-787f-4625-a348-345560749295',
    category: Education,
    index: 12,
    name: 'Exam Personnel',
    shortCode: 'by',
  },
  {
    _id: 'd5a31c2b-465b-4ff6-bcf5-424b74a689ba',
    category: Education,
    index: 13,
    name: 'Other Education Jobs',
    shortCode: 'bz',
  },
  // Office
  { _id: '90201e57-00ec-4957-a673-bed12cab3f1b', category: Office, index: 0, name: 'Intern', shortCode: 'b0' },
  { _id: 'ff78ae93-1e26-45ab-acf3-0bffa0aac333', category: Office, index: 1, name: 'Clerk', shortCode: 'b1' },
  { _id: 'f193540d-5bda-4530-8d00-a3ef83bff42a', category: Office, index: 2, name: 'Receptionist', shortCode: 'b2' },
  { _id: '53239412-d7cf-45d0-b555-1b2d8ed9f26a', category: Office, index: 3, name: 'Secretary', shortCode: 'b3' },
  {
    _id: '250fc82e-06ca-4da7-9eae-900e3be2ea0b',
    category: Office,
    index: 4,
    name: 'Office Assistant',
    shortCode: 'b4',
  },
  { _id: 'b6707269-eb3b-4858-a22a-92cc07215191', category: Office, index: 5, name: 'Accounts', shortCode: 'b5' },
  { _id: '1c8c7852-5fed-497c-9b69-d51d8c4ee2a1', category: Office, index: 6, name: 'HR & Admin', shortCode: 'b6' },
  { _id: '2abd860a-8d65-4fcf-b6f7-fea0a2b74ca2', category: Office, index: 7, name: 'Marketing', shortCode: 'b7' },
  { _id: 'c97452bf-7b3d-4a90-a09e-b89701782419', category: Office, index: 8, name: 'Design', shortCode: 'b8' },
  { _id: 'f57f3af7-5242-441d-9fc3-abf89be6e13b', category: Office, index: 9, name: 'Data Entry', shortCode: 'b9' },
  {
    _id: 'ed1deb4e-990d-44ec-b033-d104920322ab',
    category: Office,
    index: 10,
    name: 'Procurement - Non-food',
    shortCode: 'ca',
  },
  {
    _id: '68d32a83-63ed-47fd-88df-c95771edcff8',
    category: Office,
    index: 11,
    name: 'Procurement - Food & Beverage',
    shortCode: 'cb',
  },
  { _id: 'c552b5d7-95a7-4e41-94b8-613bc5a9679a', category: Office, index: 12, name: 'Merchandiser', shortCode: 'cc' },
  {
    _id: '55a2587b-e416-4ad6-b831-b70eae34d604',
    category: Office,
    index: 13,
    name: 'Other Office Jobs',
    shortCode: 'cd',
  },
  // Logistics & Transport
  {
    _id: 'dd6e3896-5f89-4e80-a8be-7c40b34f7466',
    category: LogisticsTransport,
    index: 0,
    name: 'Warehouse Assistant',
    shortCode: 'db',
  },
  {
    _id: 'a41e4031-8eb2-4b68-8502-1ae6db0fcb99',
    category: LogisticsTransport,
    index: 1,
    name: 'Logistics Assistant',
    shortCode: 'dc',
  },
  {
    _id: 'b5ea9d98-7df3-472b-b5d5-21b97aa2f247',
    category: LogisticsTransport,
    index: 2,
    name: 'Courier',
    shortCode: 'dd',
  },
  {
    _id: '9e938b88-2bb6-4339-8874-0f2c0bc92562',
    category: LogisticsTransport,
    index: 3,
    name: 'Courier - Driver',
    shortCode: 'de',
  },
  {
    _id: '690fe6e3-a231-4a53-b6be-defa564b6ba3',
    category: LogisticsTransport,
    index: 4,
    name: 'Food Delivery',
    shortCode: 'df',
  },
  {
    _id: 'd9928c0f-4005-46eb-8ef5-963b2263da41',
    category: LogisticsTransport,
    index: 5,
    name: 'Food Delivery - Driver',
    shortCode: 'dg',
  },
  {
    _id: 'ada5c0cf-7f4a-4ae5-bee8-9554576858ea',
    category: LogisticsTransport,
    index: 6,
    name: 'Private Driver',
    shortCode: 'dh',
  },
  {
    _id: 'bc794fae-0631-408b-b3d6-25b2e66f33a8',
    category: LogisticsTransport,
    index: 7,
    name: 'Valet Parking Attendant',
    shortCode: 'di',
  },
  { category: LogisticsTransport, index: 8, isSubtitle: true, name: 'Subtitle - Public Transportation' },
  {
    _id: 'b4c15102-aa42-4e42-ab5d-5c2b9bcd8174',
    category: LogisticsTransport,
    index: 9,
    name: 'Public Transportation - Driver',
    shortCode: 'dj',
  },
  {
    _id: '45c0dbea-6dd8-4f15-9783-9d90f8f72509',
    category: LogisticsTransport,
    index: 10,
    name: 'Public Transportation - Station Attendant',
    shortCode: 'dk',
  },
  { category: LogisticsTransport, index: 11, isSubtitle: true, name: 'Subtitle - Airport / Shipping' },
  {
    _id: '9e301c7b-339b-4540-ad5e-3de74d43e784',
    category: LogisticsTransport,
    index: 12,
    name: 'Airport / Shipping - Cargo Operation',
    shortCode: 'dl',
  },
  {
    _id: 'a692f297-cf83-4dc2-a1a6-6bc21399bb6b',
    category: LogisticsTransport,
    index: 13,
    name: 'Airport / Shipping - Driver',
    shortCode: 'dm',
  },
  {
    _id: '5b083472-5978-497a-99d4-4d3e23e28208',
    category: LogisticsTransport,
    index: 14,
    name: 'Airport / Shipping - Machine Operator',
    shortCode: 'dn',
  },
  {
    _id: '3ed9adcf-d691-4299-9747-b8622eb94a65',
    category: LogisticsTransport,
    index: 15,
    name: 'Airport / Shipping - Baggage Attendant',
    shortCode: 'do',
  },
  { category: LogisticsTransport, index: 16, isSubtitle: true, name: 'Subtitle - Others' },
  {
    _id: 'bee5825a-b750-4c4b-ad87-eab76ae0d952',
    category: LogisticsTransport,
    index: 17,
    name: 'Other Logistics & Transport Jobs',
    shortCode: 'dp',
  },
  // Customer Services
  {
    _id: 'ff895083-84e9-487b-8641-540bf46c82f7',
    category: CustomerServices,
    index: 0,
    name: 'Customer Services',
    shortCode: 'ce',
  },
  {
    _id: 'cee5a0ed-e15a-426b-b609-b88b201c3af2',
    category: CustomerServices,
    index: 1,
    name: 'Tele Customer Services',
    shortCode: 'cf',
  },
  // Beauty & Fitness
  {
    _id: 'f2090a58-14db-4178-8daf-612b64f13f18',
    category: BeautyFitness,
    index: 0,
    name: 'Beauty Consultant',
    shortCode: 'dq',
  },
  {
    _id: 'e90c55d0-dd66-4162-bffd-30e4589e8de4',
    category: BeautyFitness,
    index: 1,
    name: 'Cosmetologist',
    shortCode: 'dr',
  },
  { _id: '64572360-5c80-47b5-805f-e234fc354d99', category: BeautyFitness, index: 2, name: 'Masseur', shortCode: 'ds' },
  {
    _id: '8224c09e-1962-45e1-8a2f-a3a3c10e8712',
    category: BeautyFitness,
    index: 3,
    name: 'Manicurist',
    shortCode: 'dt',
  },
  {
    _id: '5d1ddb7f-4811-4525-9688-b18c97e39d01',
    category: BeautyFitness,
    index: 4,
    name: 'Makeup Artist',
    shortCode: 'du',
  },
  {
    _id: '9d43d830-b235-47a2-be88-f9afe6bcb3a4',
    category: BeautyFitness,
    index: 5,
    name: 'Fitness Trainer',
    shortCode: 'dv',
  },
  {
    _id: '53461a31-22d2-4667-b682-cabfc4484b4a',
    category: BeautyFitness,
    index: 6,
    name: 'Other Beauty & Fitness Jobs',
    shortCode: 'dw',
  },
  // Hotel & Club House
  {
    _id: '5c416771-573a-487a-ad41-60a47939d85d',
    category: HotelClubHouse,
    index: 0,
    name: 'Housekeeping',
    shortCode: 'cg',
  },
  {
    _id: '88745a5d-3b26-45f1-a798-c92f4c68bc96',
    category: HotelClubHouse,
    index: 1,
    name: 'Linen Attendant',
    shortCode: 'ch',
  },
  {
    _id: '61307014-8b20-4dea-ad41-7bbd74fc719e',
    category: HotelClubHouse,
    index: 2,
    name: 'Clubhouse Assistant',
    shortCode: 'ci',
  },
  {
    _id: 'e9faf4df-1cc4-4a17-92f6-2987da3f6dc6',
    category: HotelClubHouse,
    index: 3,
    name: 'Recreation Attendant',
    shortCode: 'cj',
  },
  {
    _id: '0872503d-ed2a-4e2f-b11a-a1e46fc0a7cf',
    category: HotelClubHouse,
    index: 4,
    name: 'Life Guard',
    shortCode: 'ck',
  },
  {
    _id: '39f6fb19-8571-4fdd-96e8-b7f5ccf44ea3',
    category: HotelClubHouse,
    index: 5,
    name: 'Front Desk / Guest Relation',
    shortCode: 'cl',
  },
  {
    _id: 'efc823c0-8566-41a1-978f-06b53553f26f',
    category: HotelClubHouse,
    index: 6,
    name: 'Concierge',
    shortCode: 'cm',
  },
  { _id: 'b89ae81f-8ab6-4ec3-9a25-41afc1c97276', category: HotelClubHouse, index: 7, name: 'Butler', shortCode: 'cn' },
  {
    _id: '4adcb335-49a8-41ca-8760-8e7b0ed44441',
    category: HotelClubHouse,
    index: 8,
    name: 'Other Hotel & Club House Jobs',
    shortCode: 'co',
  },
  // Health Services
  {
    _id: '531add49-d19d-487f-8f6c-bd8d18d23ee2',
    category: HealthServices,
    index: 0,
    name: 'Clinic Assistant / Admin',
    shortCode: 'dx',
  },
  { _id: '8028bc50-eef3-47b6-87c7-675b56aab8b0', category: HealthServices, index: 1, name: 'Nurse', shortCode: 'dy' },
  {
    _id: 'a5adfe1d-bdc3-4405-af24-08edd48ed3ae',
    category: HealthServices,
    index: 2,
    name: 'Nursing Assistant',
    shortCode: 'dz',
  },
  {
    _id: '3bbe2ec7-2ebc-4824-b87b-e6edc50e3456',
    category: HealthServices,
    index: 3,
    name: 'Postnatal Care',
    shortCode: 'd0',
  },
  { _id: '5f120553-5ffe-4bf4-ad09-10d5e7f404c7', category: HealthServices, index: 4, name: 'Midwife', shortCode: 'd1' },
  {
    _id: 'bdfbad1b-9176-4df8-a650-5cb847cf264b',
    category: HealthServices,
    index: 5,
    name: 'Care-related Support Worker',
    shortCode: 'd2',
  },
  {
    _id: 'dea19d6d-2831-4fad-acfa-d03661a6788c',
    category: HealthServices,
    index: 6,
    name: 'Other Health Services Jobs',
    shortCode: 'd3',
  },
  // Maintenance
  {
    _id: '30cfae0c-21c0-404d-9c33-a0b67caa6ab4',
    category: Maintenance,
    index: 0,
    name: 'Plumbing / Electrician',
    shortCode: 'cp',
  },
  {
    _id: '180277a5-e56f-478a-a81d-f25ee7f4bb77',
    category: Maintenance,
    index: 1,
    name: 'Electrical Technician',
    shortCode: 'cq',
  },
  {
    _id: '5c2ec013-7ef4-4d1d-bbe5-f1553dd9411c',
    category: Maintenance,
    index: 2,
    name: 'Air Conditioning',
    shortCode: 'cr',
  },
  { _id: 'c7fd6eee-96dd-4d45-8319-5fbb340d503b', category: Maintenance, index: 3, name: 'Drainage', shortCode: 'cs' },
  {
    _id: 'a182124f-6557-45a8-90fb-8f625e101b1d',
    category: Maintenance,
    index: 4,
    name: 'Pest Control',
    shortCode: 'ct',
  },
  { _id: '0482c11a-5feb-480a-9fe8-021ec1184082', category: Maintenance, index: 5, name: 'Gardening', shortCode: 'cu' },
  { _id: 'd145a82c-322b-4634-9a9b-245ab89c58b3', category: Maintenance, index: 6, name: 'Renovation', shortCode: 'cv' },
  {
    _id: '638ce32b-45f5-49e8-a7f2-107200c28e5b',
    category: Maintenance,
    index: 7,
    name: 'Building Maintenance',
    shortCode: 'cw',
  },
  { _id: '3c377f83-165f-4657-b6ef-a7fbfdcce42b', category: Maintenance, index: 8, name: 'Cars', shortCode: 'cx' },
  {
    _id: 'f685e43b-0157-43ef-8012-40654ac92c47',
    category: Maintenance,
    index: 9,
    name: 'Other Maintenance Jobs',
    shortCode: 'cy',
  },
  // Sales / Agents
  {
    _id: '1d586118-3737-434a-bef2-f74e4817b1f8',
    category: SalesAgents,
    index: 0,
    name: 'Real Estate',
    shortCode: 'd4',
  },
  { _id: '72ce1e9f-d0ea-4965-bd24-4c9eb309f478', category: SalesAgents, index: 1, name: 'Insurance', shortCode: 'd5' },
  {
    _id: '717c9f8a-3de0-4741-b8f9-caf0d2d95aab',
    category: SalesAgents,
    index: 2,
    name: 'Business Promotion',
    shortCode: 'd6',
  },
  {
    _id: '4c16b39f-5a50-4e6d-a002-f4fbc164654d',
    category: SalesAgents,
    index: 3,
    name: 'Food Wholesales',
    shortCode: 'd7',
  },
  {
    _id: 'ba2ec59f-8a05-4ecc-8b79-b6503da878ab',
    category: SalesAgents,
    index: 4,
    name: 'General Sales',
    shortCode: 'd8',
  },
  // Property Mgt & Security
  {
    _id: 'd5646cbf-1489-48fa-9729-98e4b17611d9',
    category: PropertyMgtSecurity,
    index: 0,
    name: 'Shops and Malls',
    shortCode: 'cz',
  },
  {
    _id: '37b8a690-7abe-4f05-b595-ebc7c0b84152',
    category: PropertyMgtSecurity,
    index: 1,
    name: 'Hotels and Club Houses',
    shortCode: 'c0',
  },
  {
    _id: 'c373ec99-9358-4b5f-928c-3bafed66d219',
    category: PropertyMgtSecurity,
    index: 2,
    name: 'Office Buildings',
    shortCode: 'c1',
  },
  {
    _id: '1d82e750-16ba-4975-a15d-6922c8db941d',
    category: PropertyMgtSecurity,
    index: 3,
    name: 'Residential Buildings',
    shortCode: 'c2',
  },
  {
    _id: 'f55d450d-e8e0-4f7d-bce4-438c7b71f2f7',
    category: PropertyMgtSecurity,
    index: 4,
    name: 'Airport',
    shortCode: 'c3',
  },
  {
    _id: 'bcfc0e4e-860b-4d0f-b2c9-ecae763d9086',
    category: PropertyMgtSecurity,
    index: 5,
    name: 'Schools',
    shortCode: 'c4',
  },
  {
    _id: 'f10b6d1d-574c-4593-b6cf-d24db9cb37a4',
    category: PropertyMgtSecurity,
    index: 6,
    name: 'Public Areas',
    shortCode: 'c5',
  },
  {
    _id: 'efdf6dd5-b958-401b-91ab-f0b7264d3a2f',
    category: PropertyMgtSecurity,
    index: 7,
    name: 'Other Premises',
    shortCode: 'c6',
  },
  {
    _id: 'd85a1652-866b-4640-869f-57ffae8fc0bf',
    category: PropertyMgtSecurity,
    index: 8,
    name: 'Security Car Driver',
    shortCode: 'c7',
  },
  {
    _id: '9a1bd0ce-c47b-4b58-8dc6-f4c515b00dc9',
    category: PropertyMgtSecurity,
    index: 9,
    name: 'Security Box Carrier',
    shortCode: 'c8',
  },
  {
    _id: '87d64177-cbcf-40c6-a1e0-b1b5acb0caac',
    category: PropertyMgtSecurity,
    index: 10,
    name: 'Other Security Jobs',
    shortCode: 'c9',
  },
  {
    _id: '18da43a3-7ecf-4396-95c1-9152fa2d2f1b',
    category: PropertyMgtSecurity,
    index: 11,
    name: 'Property Management',
    shortCode: 'da',
  },
  // Production & Packaging
  {
    _id: '80ab566c-b2f8-48a6-8cee-fc2a720e4173',
    category: ProductionPackaging,
    index: 0,
    name: 'Food Production',
    shortCode: 'd9',
  },
  {
    _id: '3478839c-a3a6-4900-a243-72c54809bd8e',
    category: ProductionPackaging,
    index: 1,
    name: 'Food Packaging',
    shortCode: 'ea',
  },
  {
    _id: '9fd7f379-93c5-44ff-89f2-9390c1faa8a7',
    category: ProductionPackaging,
    index: 2,
    name: 'Medicine Production',
    shortCode: 'eb',
  },
  {
    _id: 'c4676207-9d79-45db-bbb7-37b9d78e7ed8',
    category: ProductionPackaging,
    index: 3,
    name: 'Medicine Packaging',
    shortCode: 'ec',
  },
  {
    _id: '872a2115-a376-428c-abe5-7dd940487309',
    category: ProductionPackaging,
    index: 4,
    name: 'Manufacturing',
    shortCode: 'ed',
  },
  {
    _id: 'ecff9662-2d51-40a9-902c-613c13fe06a8',
    category: ProductionPackaging,
    index: 5,
    name: 'Laundry Factory',
    shortCode: 'ee',
  },
  {
    _id: '0c171ad0-7229-427c-a8ca-5f23e1d2da31',
    category: ProductionPackaging,
    index: 6,
    name: 'Others',
    shortCode: 'ef',
  },
  // Construction
  {
    _id: '86c58fc9-2ef5-434b-806f-0face6e0fade',
    category: Construction,
    index: 0,
    name: 'Civil Engineering',
    shortCode: 'eg',
  },
  {
    _id: '843ccfd5-6094-47a8-a050-f863d311af49',
    category: Construction,
    index: 1,
    name: 'Works Supervisor',
    shortCode: 'eh',
  },
  {
    _id: '9dbbfd08-b6ec-40f3-ab3c-2c23bdd9288d',
    category: Construction,
    index: 2,
    name: 'Scaffolding',
    shortCode: 'ei',
  },
  {
    _id: 'b798ba47-22d5-4440-9359-3b6713e6f442',
    category: Construction,
    index: 3,
    name: 'Equipment Operation',
    shortCode: 'ej',
  },
  {
    _id: '9ff0f8e9-1b36-4354-b4fb-b94f311e7e2f',
    category: Construction,
    index: 4,
    name: 'Painting & Decoration',
    shortCode: 'ek',
  },
  {
    _id: '616a93ff-0919-4c22-b5dd-a0edd98a79b9',
    category: Construction,
    index: 5,
    name: 'Bricklaying / Plastering / Tiling',
    shortCode: 'el',
  },
  {
    _id: 'a7f0601c-f2ff-4017-b0d8-d44651b9bae7',
    category: Construction,
    index: 6,
    name: 'Metal Work & Welding',
    shortCode: 'em',
  },
  { _id: 'e065f83d-dc57-4d04-89cc-29758cecc125', category: Construction, index: 7, name: 'Carpentry', shortCode: 'en' },
  {
    _id: '8a12fbfa-0ccb-4407-a98f-08d796bc4ade',
    category: Construction,
    index: 8,
    name: 'Bar Bending and Fixing',
    shortCode: 'eo',
  },
  { _id: '0be83585-2830-4b56-a0e3-7d8f16fcd5ec', category: Construction, index: 9, name: 'Plumbing', shortCode: 'ep' },
  {
    _id: '76de6fd1-669f-4b36-8378-37c01d666fcd',
    category: Construction,
    index: 10,
    name: 'Concreting',
    shortCode: 'eq',
  },
  {
    _id: 'b38d1d40-36a1-4f84-849c-253e4af640da',
    category: Construction,
    index: 11,
    name: 'Electrical Work',
    shortCode: 'er',
  },
  {
    _id: 'b85fb72e-7cab-46d4-89d5-1634c7d7e172',
    category: Construction,
    index: 12,
    name: 'Surveying',
    shortCode: 'es',
  },
  {
    _id: 'f063fd72-e364-45ed-ae82-90c9d1d5b5b3',
    category: Construction,
    index: 13,
    name: 'Drainlaying / Piplaying',
    shortCode: 'et',
  },
  {
    _id: 'a4b8ae57-c5b3-4d5a-9244-43fae55aa901',
    category: Construction,
    index: 14,
    name: 'Other Construction Jobs',
    shortCode: 'eu',
  },
  // Cleaning
  { _id: 'ab4ef4fb-4478-4e69-a791-61da243225c2', category: Cleaning, index: 0, name: 'Restaurants', shortCode: 'ev' },
  {
    _id: '9c0ec5bd-a2fe-4283-bb0c-52668e88384c',
    category: Cleaning,
    index: 1,
    name: 'Shops and Malls',
    shortCode: 'ew',
  },
  {
    _id: '7bb62eec-8b27-414f-8f80-653b46e7f260',
    category: Cleaning,
    index: 2,
    name: 'Hotels and Club Houses',
    shortCode: 'ex',
  },
  {
    _id: '188b5f79-487e-4e75-bf50-15bdff6b9110',
    category: Cleaning,
    index: 3,
    name: 'Office Buildings',
    shortCode: 'ey',
  },
  {
    _id: 'bd311b1e-3f05-4512-8408-e7c926c33a7f',
    category: Cleaning,
    index: 4,
    name: 'Residential Buildings',
    shortCode: 'ez',
  },
  { _id: '3eb5beea-0d62-454b-a5fd-4e332efd89a3', category: Cleaning, index: 5, name: 'Hospitals', shortCode: 'e0' },
  { _id: '321cd367-ecaa-4e6b-80c4-835d30023870', category: Cleaning, index: 6, name: 'Airport', shortCode: 'e1' },
  { _id: '58aa5e48-9815-49a1-8c7f-7021356c01ee', category: Cleaning, index: 7, name: 'Schools', shortCode: 'e2' },
  { _id: '361bd8d4-b272-4d38-8e43-4be178ffdc66', category: Cleaning, index: 8, name: 'Public Areas', shortCode: 'e3' },
  {
    _id: '04e7551a-aa4b-43e1-a5a9-edb21b609d02',
    category: Cleaning,
    index: 9,
    name: 'Other Premises',
    shortCode: 'e4',
  },
  {
    _id: 'cda9ca7d-251f-4a5b-b637-c6f0bdc04de6',
    category: Cleaning,
    index: 10,
    name: 'Other Cleaning Jobs',
    shortCode: 'e5',
  },
  // Others (No ID)
  { _id: '', category: Others, index: 0, name: 'Other Job', shortCode: '' },
]

/**
 * Find job type data by ID or short code
 * @param {string} value - job type or short code
 * TODO: Only used for backward compatible. Replace by findJobTypeByShortCode() int the future
 * @returns {{_id: string, category: string, index: number, isSubtitle: boolean, name: string, shortCode: string}}
 */
export function findJobTypeByIDOrShortCode(value) {
  if (value) {
    return jobTypes.find((jt) => jt._id === value || jt.shortCode === value)
  }
  return null
}

/**
 * Find job type data by short code
 * @param {string} shortCode
 * @returns {{_id: string, category: string, index: number, isSubtitle: boolean, name: string, shortCode: string}}
 */
export function findJobTypeByShortCode(shortCode) {
  if (shortCode) {
    return jobTypes.find((jt) => jt.shortCode === shortCode)
  }
  return null
}

/**
 * Find job type field by Short code and field name
 * @param {string} shortCode
 * @param {string} fieldName
 */
export function findJobTypeFieldByShortCode(shortCode, fieldName) {
  const jobType = findJobTypeByShortCode(shortCode)
  if (jobType && jobType[fieldName]) {
    return jobType[fieldName]
  }
  return null
}

/**
 * Find job types by job category name
 * @param {string} category
 * @returns {[{_id: string, category: string, index: number, isSubtitle: boolean, name: string, shortCode: string}]}
 */
export function findJobTypesByJobCategory(category) {
  if (category) {
    return _sortBy(
      jobTypes.filter((jt) => jt.category === category),
      'index',
    )
  }
  return []
}

/**
 * Find job categories w/ job types data,
 * based on provided job type short codes
 * @param {[string]} jobTypeShortCodes
 * @returns {[{category: string,
 *             jobTypes: [{_id: string, category: string, index: number, isSubtitle: boolean, name: string, shortCode: string}]}]}
 */
export function findJobCategoriesByJobTypeShortCodes(jobTypeShortCodes = []) {
  if (Array.isArray(jobTypeShortCodes) && jobTypeShortCodes.length) {
    // Find job type entries by short code,
    // then extract category names
    const categoryNames = jobTypeShortCodes.map((sc) => findJobTypeFieldByShortCode(sc, 'category'))

    // Get all job categories w/ job types
    // filter job categories data by name
    return getJobCategoryTypeGroups().filter(({ category }) => categoryNames.includes(category))
  }
  return []
}

/**
 * Get list of all job categories w/ job types data
 * @returns {[{category: string,
 *             jobTypes: [{_id: string, category: string, index: number, isSubtitle: boolean, name: string, shortCode: string}]}]}
 */
export function getJobCategoryTypeGroups() {
  // Group job types by category
  const jobCategoryTypeMap = _groupBy(jobTypes, 'category')

  // Return list of job categories w/ job types data
  // based on defined order
  return jobCategoriesSortOrder
    .filter((category) => Array.isArray(jobCategoryTypeMap[category]))
    .map((category) => ({
      category,
      jobTypes: _sortBy(jobCategoryTypeMap[category], 'index'),
    }))
}

/**
 * Format job type data into localisation key
 * @param {string} category
 * @param {string} name
 * @returns {string}
 */
export function getJobTypeLocaleKey({ category, name } = {}) {
  if (category && name) {
    return `jobType.${category}.${name}`
  }
  return ''
}

/**
 * Format job category name into localisation key
 * @param {string} category
 * @returns {string}
 */
export function getJobCategoryLocaleKey(category) {
  if (category) {
    return `jobCategory.${category}`
  }
  return ''
}

/**
 * Format job category name into tracking name
 * @param {string} category
 * @returns {string}
 */
export function getJobCategoryTrackingName(category) {
  if (category) {
    return cleanStringForURL(category).replace('-', '_')
  }
  return ''
}

/**
 * Sort job types
 * TODO: confirm sort order
 * @param {Array} jobTypesList
 * @returns {[{_id: string, category: string, index: number, isSubtitle: boolean, name: string, shortCode: string}]}
 */
export function sortJobTypes(jobTypesList = []) {
  return _sortBy(jobTypesList, 'name')
}

/**
 * Special patterns for finding index to split
 * job category/type string into pair
 * @type {[string]}
 */
export const splitJobTypePatterns = [SalesAgents]

/**
 * Split job category/type string into pair
 * @param {string} jobTypeString
 * @returns {{category: string, name: string}}
 */
export function splitJobTypePair(jobTypeString) {
  if (jobTypeString) {
    // Find index for splitting job category/type pair
    // using special patterns
    let index = splitJobTypePatterns.reduce((currIndex, pattern) => {
      // If index not found yet, try current pattern
      if (currIndex < 0 && jobTypeString.indexOf(pattern) === 0) {
        return pattern.length
      }
      // Otherwise return current index
      return currIndex
    }, -1)

    // If string doesn't match special patterns,
    // find index of first "/"
    if (index < 0) {
      index = jobTypeString.indexOf('/')
    }

    // If index found,
    // split string into category and type name
    if (index > -1) {
      return {
        category: jobTypeString.substr(0, index),
        name: jobTypeString.substr(index + 1),
      }
    }
  }
  return null
}

/**
 * Handle translation of job type.
 * If locale key doesn't exist,
 * fallback to job type name value
 * @param {{category: string, name: string}} jobType
 * @param {Function} checkFn
 * @param {Function} translateFn
 * @returns {string}
 */
export function translateJobTypeWithFallback(jobType, checkFn, translateFn) {
  if (jobType && jobType.name && typeof checkFn === 'function' && typeof translateFn === 'function') {
    // Get job type locale key
    const localeKey = getJobTypeLocaleKey(jobType)

    // If locale key exists,
    // return translated value
    if (checkFn(localeKey)) {
      return translateFn(localeKey)
    }

    // Otherwise return job type name
    return jobType.name
  }
  return ''
}
