<template>
  <main class="container">
    <error-content />
  </main>
</template>

<script>
import ErrorContent from '../components/error/errorContent'
import TrackingMixin from '../mixins/layout/tracking'

export default {
  components: {
    'error-content': ErrorContent,
  },
  mixins: [TrackingMixin],
  name: 'ErrorLayout',
  props: {
    /**
     * Error data provided by Nuxt,
     * ref: https://nuxtjs.org/guide/views#error-page
     */
    error: {
      default: () => {},
      type: Object,
    },
  },
}
</script>
