/**
 * Loading *Vuex* module state
 */
export default function () {
  return {
    /**
     * Loading state
     */
    isLoading: false,
  }
}
