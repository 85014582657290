<template>
  <div class="app-footer-country">
    <img
      class="app-footer-country__icon"
      loading="lazy"
      :alt="title"
      :width="24"
      :height="16"
      :src="imgSrc"
      :title="title"
    />
    <span class="app-footer-country__text"> {{ title }}</span>
  </div>
</template>

<script>
export default {
  computed: {
    title() {
      return this.$t(`country.${this.country}`)
    },
  },
  data() {
    return {
      imgSrc: `${process.env.SEEKER_WEB__S3_PREFIX}/img/flag/${this.country}.jpg`,
    }
  },
  props: {
    country: {
      default: '',
      type: String,
    },
  },
}
</script>

<style lang="postcss" scoped>
.app-footer-country {
  @apply flex flex-row items-center justify-center;
}
.app-footer-country__icon {
  width: 24px;
  height: 16px;
}
.app-footer-country__text {
  @apply text-coverline font-coverline text-grey-medium;
  @apply ml-2;
}
</style>
