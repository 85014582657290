<template>
  <client-only>
    <div v-show="deviceSystem" class="ftr-store-links">
      <template v-for="link in links">
        <a
          v-if="link.show"
          :key="`FooterStoreLink-${link.id}`"
          class="ftr-store-link"
          target="_blank"
          rel="noopener"
          :class="`ftr-store-link--${link.id}`"
          :href="link.href"
          :title="link.title"
        >
          <component :is="`svg-${link.id}`" class="ftr-store-link__icon" />
        </a>
      </template>
    </div>
  </client-only>
</template>

<script>
import Bowser from 'bowser'
import SvgAppStoreDownload from '../../assets/svg/app_store_download.svg?inline'
import SvgGooglePlayDownload from '../../assets/svg/google_play_download.svg?inline'

export default {
  components: {
    'svg-apple': SvgAppStoreDownload,
    'svg-google': SvgGooglePlayDownload,
  },
  computed: {
    links() {
      return [
        {
          href: 'https://s.moovup.com/install-mobile',
          id: 'apple',
          isExternal: true,
          show: this.deviceSystem === 'iOS' || this.deviceSystem === 'web',
          title: 'Moovup Apple Store',
        },
        {
          href: 'https://s.moovup.com/install-mobile',
          id: 'google',
          isExternal: true,
          show: this.deviceSystem === 'Android' || this.deviceSystem === 'web',
          title: 'Moovup Google Play',
        },
      ]
    },
  },
  created() {
    this.detectOS()
  },
  data() {
    return {
      deviceSystem: '',
    }
  },
  methods: {
    detectOS() {
      if (process.client) {
        const bowser = Bowser.getParser(navigator.userAgent)
        const os = bowser.parsedResult.os
        this.deviceSystem = os.name === 'iOS' || os.name === 'Android' ? os.name : 'web'
      }
    },
  },
}
</script>

<style lang="postcss" scoped>
.ftr-store-links {
  @apply flex flex-row flex-wrap justify-start;
}

.ftr-store-link {
  @apply flex justify-center items-center relative mb-0;

  &:not(:first-child) {
    @apply ml-2;
  }
  &:after {
    @apply absolute inset-0 rounded-sm bg-white opacity-0 z-1;
    content: '';
  }
  &:hover:after {
    @apply opacity-10;
  }
}
.ftr-store-link__icon {
  @apply w-full h-full;
}

.ftr-store-link--apple {
  width: 144px;
  height: 48px;
}
.ftr-store-link--google {
  width: 162px;
  height: 48px;
}

@screen desktop {
  .ftr-store-links {
    @apply justify-start;
  }
}
</style>
