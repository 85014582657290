import { requestIdleCallback } from '../timeout'

export default class EventTracker {
  constructor(trackerMap) {
    this._trackerMap = trackerMap
  }

  /**
   * Initialize event trackers synchronously,
   * i.e. process blocking
   */
  init() {
    for (const tracker of Object.values(this._trackerMap)) {
      tracker.init()
    }
  }

  /**
   * Initialize event trackers asynchronously,
   * i.e. when browser is idle
   */
  deferInit() {
    for (const tracker of Object.values(this._trackerMap)) {
      requestIdleCallback(() => {
        tracker.deferInit()
      })
    }
  }

  sendConversion(id, keys = []) {
    for (const tracker of this.getTrackers(keys)) {
      tracker.sendConversion(id)
    }
  }

  sendCustomEvent(eventName, eventVariables = {}, keys = []) {
    if (eventName && eventVariables) {
      for (const tracker of this.getTrackers(keys)) {
        tracker.sendCustomEvent(eventName, eventVariables)
      }
    }
  }

  sendPageViewEvent(route, pageTitle, pageLocation, pagePath, keys = []) {
    for (const tracker of this.getTrackers(keys)) {
      tracker.sendPageViewEvent(route, pageTitle, pageLocation, pagePath)
    }
  }

  setUserId(userId, keys = []) {
    for (const tracker of this.getTrackers(keys)) {
      tracker.setUserId(userId)
    }
  }

  setAppVersion(version, keys = []) {
    for (const tracker of this.getTrackers(keys)) {
      tracker.setAppVersion(version)
    }
  }

  setUserProperties(properties, keys = []) {
    for (const tracker of this.getTrackers(keys)) {
      tracker.setUserProperties(properties)
    }
  }

  getTrackers(keys) {
    if (Array.isArray(keys) && keys.length > 0) {
      const trackers = []
      for (const key of keys) {
        const tracker = this._trackerMap[key]
        if (tracker) {
          trackers.push(tracker)
        }
      }
      return trackers
    }
    return Object.values(this._trackerMap)
  }
}
