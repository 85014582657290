/**
 * Generate <link> tag based on provided rel and href,
 * supports optional ID and hreflang code
 * @param {string} rel
 * @param {string} href
 * @param {string} [hid]
 * @param {string} [hreflang]
 */
export function mapLinkTag(rel, href, hid, hreflang) {
  if (rel && href) {
    const tag = { href, rel }
    // Set tag ID if available
    if (hid) {
      tag.hid = hid
    }
    // Set tag hreflang if available
    if (hreflang) {
      tag.hreflang = hreflang
    }
    return tag
  }
}

/**
 * Generate <meta> tag based on provided name and content,
 * supports optional ID
 * @param {string} name
 * @param {string} content
 * @param {string} [hid]
 * @param {boolean} isProperty
 */
export function mapMetaTag(name, content, hid, isProperty = false) {
  if (name && content) {
    const tag = {
      content,
      // Use "property" attribute instead of "name" if flag set
      [isProperty ? 'property' : 'name']: name,
    }
    // Set tag ID if available
    if (hid) {
      tag.hid = hid
    }
    return tag
  }
}

/**
 * Default metadata separator
 * @type {string}
 */
export const defaultSeparator = '|'

/**
 * Add trailing slash to provided path
 * @param {string} path
 */
export function addTrailingSlash(path) {
  if (path) {
    // Add trailing slash to provided path,
    // then replace consecutive trailing slashes with one
    return `${path}/`.replace(/\/+$/g, '/')
  }
  return ''
}

/**
 * Generate page URL <link> and <meta> tags
 * @param {string} baseUrl
 * @param {string} path Current locale path
 * @param {string} defaultPath Default locale path
 */
export function getPageUrlTags(baseUrl, path, defaultPath) {
  // Create blank metadata
  const metadata = {
    link: [],
    meta: [],
  }

  // Add <link> and <meta> tags if base URL and paths available
  if (baseUrl && path && defaultPath) {
    const url = `${baseUrl}${path}`
    const defaultUrl = `${baseUrl}${defaultPath}`

    // Canonical URL link
    // NOTE: Use current locale path for canonical,
    // ref: https://hreflang.org/use-hreflang-canonical-together/
    metadata.link.push(mapLinkTag('canonical', url, 'canonical'))

    // default if zh-hk
    metadata.link.push(mapLinkTag('alternate', defaultUrl, 'alternate-zh-hk', 'zh-hk'))
    // english
    metadata.link.push(mapLinkTag('alternate', `${baseUrl}/en${defaultPath}`, 'alternate-en', 'en'))
    // Fallback locale link
    metadata.link.push(mapLinkTag('alternate', defaultUrl, 'alternate-x-default', 'x-default'))

    // Open graph URL
    metadata.meta.push(mapMetaTag('og:url', url, 'og:url', true))
    // Twitter URL
    metadata.meta.push(mapMetaTag('twitter:url', url, 'twitter:url'))
  }

  return metadata
}

/**
 * Generate metadata tags based on
 * provided title, description and images
 * @param {string} title
 * @param {string} description
 * @param {[string]} images
 * @param {string} keywords
 * @param {boolean} overrideDefaultImage Whether to override default share image
 */
export function getPageMetadata(title, description, images, keywords, overrideDefaultImage = false) {
  // Create blank metadata
  const metadata = {
    meta: [],
  }

  // Set title <title> and <meta> tags if available
  if (title) {
    // Default title
    metadata.title = title
    // Open graph title
    metadata.meta.push(mapMetaTag('og:title', title, 'og:title', true))
    // Twitter title
    metadata.meta.push(mapMetaTag('twitter:title', title, 'twitter:title'))
  }
  // Set description <meta> tags if available
  if (description) {
    // Default description
    metadata.meta.push(mapMetaTag('description', description, 'description'))
    // Open graph description
    metadata.meta.push(mapMetaTag('og:description', description, 'og:description', true))
    // Twitter description
    metadata.meta.push(mapMetaTag('twitter:description', description, 'twitter:description'))
  }
  // Set additional image <meta> tags if available
  if (Array.isArray(images)) {
    const imageOffset = overrideDefaultImage ? 0 : 1
    images
      .filter((image) => image)
      .forEach((image, i) => {
        // Always add Open graph image
        metadata.meta.push(mapMetaTag('og:image', image, `og:image:${i + imageOffset}`, true))

        // If overriding default share image,
        // set first image as Twitter image
        if (overrideDefaultImage && i === 0) {
          metadata.meta.push(mapMetaTag('twitter:image', image, 'twitter:image'))
        }
      })
  }
  // Set keywords <meta> tag if available
  if (keywords) {
    // Default keywords
    metadata.meta.push(mapMetaTag('keywords', keywords, 'keywords'))
  }

  return metadata
}

/**
 * Add separator to text if not empty
 * @param {string} text
 */
export function separate(text = '', separator = defaultSeparator) {
  return text.length ? ` ${separator} ` : ''
}
