import EventTracker from '../libs/tracking/eventTracker'
import ExternalGA4Tracker from '../libs/tracking/platform/externalGA4Tacker'
import FacebookPixelTracker from '../libs/tracking/platform/fbPixel'
import GoogleAdTracker from '../libs/tracking/platform/googleAd'
import GoogleAnalytics4Tracker from '../libs/tracking/platform/googleAnalytics4'

import { screenNameConvertFunc } from '../libs/tracking/utils'
export const EXTERNAL_GOOGLE_ANALYTICS_4 = 'external-google-analytics-4'
export const PLATFORM_FB_PIXEL = 'facebook-pixel'
export const PLATFORM_GOOGLE_AD = 'google-ad'
export const PLATFORM_GOOGLE_ANALYTICS = 'google-analytics'
export const PLATFORM_GOOGLE_ANALYTICS_4 = 'google-analytics-4'

export default function ({ app }, inject) {
  if (process.client) {
    const eventTracker = new EventTracker({
      // External Campagin GA4 Tracker (temporary)
      // Will not have custom event, only page view
      // if there is no active campaign, this value should be an empty string
      [EXTERNAL_GOOGLE_ANALYTICS_4]: new ExternalGA4Tracker(app.$gtag, process.env.SEEKER_WEB__EXTERNAL_GA4_ADS_TAG_ID),

      // Facebook Pixel
      [PLATFORM_FB_PIXEL]: new FacebookPixelTracker(process.env.SEEKER_WEB__FACEBOOK_PIXEL_ID),

      // Google Ad Tag
      [PLATFORM_GOOGLE_AD]: new GoogleAdTracker(app.$gtag, process.env.SEEKER_WEB__GOOGLE_ADS_TAG_ID),

      // GA4
      [PLATFORM_GOOGLE_ANALYTICS_4]: new GoogleAnalytics4Tracker(
        app.$gtag,
        process.env.SEEKER_WEB__GA_ID,
        screenNameConvertFunc,
      ),
    })
    eventTracker.init()
    inject('eventTracker', eventTracker)
  }
}
