<template>
  <base-jc
    :company="company"
    :job="job"
    :tracking-params="trackingParams"
    :is-fire-tracking-event="isFireTrackingEvent"
    :is-interact="isInteract"
    :observer="observer"
    :position="position"
    :session-id="sessionId"
    :source="source"
    :click="onClick"
    @impression="onImpressionEvent"
  />
</template>

<script>
import { sendJobClickEvent, sendJobImpressionEvent } from '../../../libs/tracking/utils/event'
import BaseJobCard from './baseJobCard'

export default {
  components: {
    'base-jc': BaseJobCard,
  },
  methods: {
    onClick() {
      if (this.isFireTrackingEvent) {
        sendJobClickEvent(this.$eventTracker, {
          jobId: this.job._id,
          position: this.position,
          sessionId: this.sessionId,
          source: this.source,
          sourceSection: this.sourceSection,
          trackingSearchParams: this.trackingParams,
        })
      }
    },
    onImpressionEvent() {
      if (this.isFireTrackingEvent) {
        sendJobImpressionEvent(this.$eventTracker, {
          jobId: this.job._id,
          position: this.position,
          sessionId: this.sessionId,
          source: this.source,
          sourceSection: this.sourceSection,
          trackingSearchParams: this.trackingParams,
        })
      }
    },
  },
  props: {
    company: {
      required: true,
      type: Object,
    },
    isFireTrackingEvent: {
      default: true,
      type: Boolean,
    },
    isInteract: {
      default: false,
      required: false,
      type: Boolean,
    },
    job: {
      required: true,
      type: Object,
    },
    observer: {
      default: () => ({}),
      type: Object,
    },
    position: {
      required: true,
      type: Number,
    },
    sessionId: {
      default: '',
      type: String,
    },
    source: {
      default: '',
      type: String,
    },
    sourceSection: {
      default: '',
      type: String,
    },
    /** Formatted tracking params */
    trackingParams: {
      default: () => ({}),
      type: Object,
    },
  },
}
</script>
