/**
 * Error *Vuex* module mutations
 */
export default {
  clearTimer(state) {
    if (state.timer) {
      clearTimeout(state.timer)
      state.timer = null
    }
  },
  dismissError(state) {
    state.errMsg = ''
    state.isShowError = false
  },
  showError(state, errMsg) {
    state.errMsg = errMsg
    state.isShowError = true
  },
  startTimer(state, callback) {
    state.timer = setTimeout(() => {
      callback()
    }, 4000)
  },
}
