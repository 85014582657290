export function createIntersectionObserver(
  callback,
  option = {
    root: null,
    rootMargin: '0px',
    // Since browser doesn't round decimal points
    // when calculating intersection ratio,
    // non-integer element width / height will cause
    // intersection ratio to always be < 1
    threshold: 0.95,
  },
) {
  return new IntersectionObserver(callback, option)
}

/**
 * Cleanup an intersection observer
 * @param {IntersectionObserver} observer
 */
export function cleanupIntersectionObserver(observer) {
  if (observer && typeof observer.disconnect === 'function') {
    observer.disconnect()
  }
}
