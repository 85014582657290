import _merge from 'lodash/merge'
import GtagTracker from './gtag'
import { requestIdleCallback } from '../../timeout'

/**
 * Google Analytics 4 tracker,
 * ref: https://developers.google.com/analytics/devguides/collection/ga4
 */
export default class GoogleAnalytics4Tracker extends GtagTracker {
  constructor(gtag, id, screenNameConvertFunc) {
    super(gtag, id)
    this._screenNameConvertFunc = screenNameConvertFunc
    this.appVersion = 'unknown'

    // Initial config
    this._config = {
      // Disable default page view measurement,
      // ref: https://developers.google.com/analytics/devguides/collection/ga4/disable-page-view
      send_page_view: false,
    }

    // Initialisation queue
    this._initQueue = []
  }

  deferInit() {
    // Only initialize once
    if (!this._deferInitDone) {
      super.deferInit()

      // Trigger tracker initialisation
      // by setting config for the first time
      this.setConfig(this._config)

      // Schedule executing callbacks in init queue
      this._initQueue.forEach((callback) => {
        requestIdleCallback(callback)
      })
    }
  }

  /**
   * Send custom event,
   * wrapped w/ defer init handling
   * @param {string} eventName
   * @param {*} eventVariables
   */
  sendCustomEvent(eventName, eventVariables) {
    const callback = () => {
      super.sendCustomEvent(eventName, eventVariables)
    }

    if (!this._deferInitDone) {
      // If tracker not initialised yet,
      // add send event callback to init queue
      this._initQueue.push(callback)
    } else {
      // Otherwise schedule sending event immediately
      requestIdleCallback(callback)
    }
  }

  /**
   * Send screen view event,
   * ref: https://developers.google.com/analytics/devguides/collection/ga4/screen-view
   */
  sendPageViewEvent(route, pageTitle, pageLocation, pagePath) {
    const screenName = this._screenNameConvertFunc(route, pageTitle, pagePath)
    if (screenName) {
      this.setCurrentScreen(screenName)
      this.sendCustomEvent('screen_view', {
        page_location: pageLocation,
        page_path: pagePath,
        page_title: screenName,
        screen_name: screenName,
      })
    }
  }

  setAppVersion(version) {
    this.appVersion = version
  }

  /**
   * Set config params
   * @param {*} config
   */
  setConfig(config = {}) {
    this._gtag('config', this._id, config)
  }

  setCurrentScreen(screenName) {
    this.updateConfig({
      screen_name: screenName,
    })
  }

  /**
   * Set user ID,
   * ref: https://developers.google.com/analytics/devguides/collection/ga4/cookies-user-id
   * @param {string} userId
   */
  setUserId(userId) {
    this.updateConfig({
      user_id: userId,
    })
  }

  /**
   * Set user properties,
   * ref: https://developers.google.com/analytics/devguides/collection/ga4/user-properties
   * @param {*} properties
   */
  setUserProperties(properties = {}) {
    this.updateConfig({
      user_properties: { version: this.appVersion, ...properties },
    })
  }

  /**
   * Update config params
   * @param {*} config
   */
  updateConfig(config) {
    if (!this._deferInitDone) {
      // If tracker not initialised yet,
      // store config first
      this._config = _merge(this._config, config)
    } else {
      // Otherwise trigger config update
      this.setConfig({
        ...config,
        update: true,
      })
    }
  }
}
