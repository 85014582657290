<template>
  <n-link class="hdr-masthead" :title="$t('homepage.link')" :to="localePathName('/')">
    <!-- Brand logo -->
    <svg-moovup-logo class="hdr-masthead__logo" />
    <!-- Web crawler only title -->
    <h2 class="hdr-masthead__text">Moovup</h2>
  </n-link>
</template>

<script>
import SvgMoovupLogo from '../../assets/svg/moovupLogo_v4.svg?inline'

export default {
  components: {
    'svg-moovup-logo': SvgMoovupLogo,
  },
}
</script>

<style lang="postcss" scoped>
.hdr-masthead {
  @apply flex-shrink-0 flex items-center h-full;
  padding-left: 10px;
}
.hdr-masthead__logo {
  width: 200px;
}

.hdr-masthead__text {
  @apply sr-only;
}

@screen desktop {
  .hdr-masthead__logo {
    width: 250px;
    @apply pl-4;
    padding-top: 1rem;
    padding-bottom: 0.7rem;
  }
}
</style>
