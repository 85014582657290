<template>
  <main>
    <div class="overlay" @click="onClose" />
    <div class="side-menu">
      <div class="side-menu-body">
        <div>
          <svg-close class="svg-fill b__close" />
          <!--   Seeker avatar and name   -->
          <div v-if="isLoggedIn">
            <button class="profile__info" @click="onItemClick(0)">
              <div class="profile__logo">
                <svg-avatar v-if="!thumbnail" />
                <img
                  v-if="thumbnail"
                  loading="lazy"
                  :alt="seekerName || 'Seeker logo'"
                  :title="seekerName || 'Seeker logo'"
                  :src="thumbnail"
                />
              </div>
              <!-- Seeker name -->
              <h3 class="profile__name">{{ seekerName }}</h3>
            </button>
            <template v-for="(item, i) in items">
              <button :key="`MenuItem${i}`" class="option__btn" @click="onItemClick(i)">
                <h3>{{ item.title }}</h3>
                <svg-arrow-right class="svg-fill" />
              </button>
            </template>
          </div>
          <div v-else>
            <button class="login-btn" @click="directToLogin">
              <div class="option__btn mb-2">
                <p class="login-title">{{ $t('sideMenu.login') }}</p>
                <svg-arrow-right class="svg-fill" />
              </div>
              <p class="login-btn-text">{{ $t('sideMenu.personalizedJobExperience') }}</p>
            </button>
          </div>
          <app-footer-store-links class="mt-6" />
        </div>

        <div>
          <p class="employer-title">{{ $t('sideMenu.imEmployer') }}</p>
          <a class="option__btn post-job-btn" :href="`https://employer.moovup.com/login/?locale=${currentLocale.code}`"
            >{{ $t('sideMenu.postJob') }}
            <svg-arrow-right class="svg-fill" />
          </a>
          <button class="option__btn" @click="directToEmployerRecruitmentService">
            <h3>{{ $t('sideMenu.learnMore') }}</h3>
            <svg-arrow-right class="svg-fill" />
          </button>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import AppFooterStoreLinks from '../footer/appFooterStoreLinks'
import ProfileMixin from '../../mixins/profile'
import SvgArrowRightIcon from '../../assets/svg/arrow_right.svg?inline'
import SvgAvatar from '../../assets/svg/avatar.svg?inline'
import SvgClose from '../../assets/svg/close.svg?inline'

export default {
  components: {
    'app-footer-store-links': AppFooterStoreLinks,
    'svg-arrow-right': SvgArrowRightIcon,
    'svg-avatar': SvgAvatar,
    'svg-close': SvgClose,
  },
  computed: {
    /**
     * Seeker name
     */
    seekerName() {
      return this.name || this.telephone
    },
  },
  methods: {
    directToEmployerRecruitmentService() {
      this.$router.push({
        path: this.localePathName('/employer/recruitment-services/'),
        query: {
          target: this.$router.currentRoute.fullPath,
        },
      })
    },
    directToLogin() {
      this.$router.push({
        path: this.localePathName('/login'),
        query: {
          target: this.$router.currentRoute.fullPath,
        },
      })
    },
    /**
     * close the side menu
     */
    onClose() {
      this.$emit('close')
    },
    /**
     * Emit menu item click event
     * w/ item index
     */
    onItemClick(index) {
      if (index >= 0 && index < this.items.length) {
        this.$emit('click', index)
      }
    },
  },
  mixins: [ProfileMixin],
  props: {
    /**
     * Menu items
     */
    items: {
      default: () => [],
      type: Array,
    },
  },
}
</script>

<style scoped>
.overlay {
  @apply inset-0 fixed z-30;
  @apply bg-transparent pointer-events-none;
  transition: background-color 0.5s linear;
}

.side-menu {
  @apply inset-0 fixed z-30;
  @apply bg-grey-bg flex flex-col;
  transform: translate3d(115%, 0, 0);
  -webkit-transform: translate3d(115%, 0, 0);
  transition: transform 0.5s;
}

.side-menu-body {
  @apply flex flex-col justify-between px-4 py-10 h-full;
  /* The whole side bar is moved to right by 15%, so the width should be 85% */
  width: 85%;
}

.b__close {
  @apply text-white absolute;
  @apply w-8 h-8 pointer-events-none;
  top: 8px;
  left: -40px;
}

.profile__info {
  @apply flex outline-none mb-6;

  & .profile__logo {
    @apply rounded-2xl bg-grey-mlight overflow-hidden mr-2;
    @apply border border-solid border-pink-disabled;
    @apply w-12 h-12;

    & img {
      @apply h-full object-cover;
    }
  }

  & .profile__name {
    @apply inline-block align-middle;
    @apply text-black h-12 font-medium;
    line-height: 48px;
  }
}

.option__btn {
  @apply text-left outline-none flex w-full items-center justify-between text-black h-12;

  & h3 {
    @apply align-middle;
    line-height: 48px;
  }

  & svg {
    @apply text-grey-900;
    height: 30px;
  }
}

.login-btn {
  @apply p-4 bg-red-50 border-red-100 border w-full;
  border-radius: 8px;

  & .option__btn {
    height: auto;
  }

  & svg {
    @apply text-red-500;
  }
}

.login-title {
  @apply font-medium text-red-500;
  font-size: 17px;
}

.login-btn-text {
  @apply text-red-500 text-base text-left;
  margin-right: 15px;
}

.employer-title {
  @apply text-base text-violet-500 border-b border-grey-200 pb-2 mb-2;
}

>>> .ftr-store-link--apple {
  @apply h-auto;
  width: 95px;
}

>>> .ftr-store-link--google {
  @apply h-auto;
  width: 108px;
}

.post-job-btn:hover {
  @apply no-underline;
}
</style>
