import { FullTime, PartTime, Temp } from '../libs/mapping/employmentType'
/**
 * NuxtJS plugin to send pageview event
 * after route change
 */
export default function ({ app, store }) {
  // On client side,
  // send pageview event after route change
  if (process.client) {
    // Set App Version
    app.$eventTracker.setAppVersion(process.env.SEEKER_WEB__VERSION)

    app.router.afterEach((route) => {
      // Extract page name from route name
      const pageTitle = route.name ? route.name.replace(/__.*$/, '') : 'not-found'

      // Generate page URL based on
      // base URL and route full path
      const pageURL = `${process.env.SEEKER_WEB__BASE_URL}${route.fullPath}`
      const pagePath = `${process.env.SEEKER_WEB__S3_PREFIX}${route.fullPath}`

      // Once token ready
      app.$tokenReady((token, { sub } = {}) => {
        const currentLocale = app.i18n.locales.find((locale) => locale && locale.code === app.i18n.locale)
        const isAnonymousUser = store.getters['auth/isAnonymousUser']

        const userProperties = {
          in_app_language: currentLocale.code,
          is_anonymous: isAnonymousUser,
        }

        // Send User id
        app.$eventTracker.setUserId(sub)

        if (isAnonymousUser) {
          app.$eventTracker.setUserProperties(userProperties)
        }

        app.$profileReady(() => {
          const workExperiences = store.getters['profile/workExperiences']
          app.$eventTracker.setUserProperties({
            ...userProperties,
            ft_exp_count: workExperiences.filter(({ employment }) => employment === FullTime).length,
            has_employer_msg: !!store.getters['profile/messageToEmployer'],
            has_profile_img: store.getters['profile/hasProfileImage'],
            profile_education: store.getters['profile/education'],
            pt_exp_count: workExperiences.filter(({ employment }) => employment === PartTime).length,
            tp_exp_count: workExperiences.filter(({ employment }) => employment === Temp).length,
          })
        })

        // Send Page view event
        app.$eventTracker.sendPageViewEvent(route, pageTitle, pageURL, pagePath)
      })
    })
  }
}
