/**
 * Loading *Vuex* module mutations
 */
export default {
  closeLoadingDialog(state) {
    state.isLoading = false
  },

  openLoadingDialog(state) {
    state.isLoading = true
  },
}
