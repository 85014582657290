<template>
  <n-link class="emp-hdr-masthead" :title="title" :to="linkPath">
    <!-- Brand logo -->
    <svg-moovup-logo class="emp-hdr-masthead__logo" />
    <!-- Web crawler only title -->
    <h2 class="emp-hdr-masthead__text">{{ title }}</h2>
  </n-link>
</template>

<script>
import SvgMoovupLogo from '../../../assets/svg/moovupLogo_v4_red.svg?inline'

export default {
  components: {
    'svg-moovup-logo': SvgMoovupLogo,
  },
  computed: {
    linkPath() {
      return this.getArticleLocalePath('employer/landing')
    },
    title() {
      return this.$t('metadata.titleSubfix')
    },
  },
}
</script>

<style lang="postcss" scoped>
.emp-hdr-masthead {
  @apply flex items-center;
}
.emp-hdr-masthead__text {
  @apply sr-only;
}

@screen mobile {
  .emp-hdr-masthead__logo {
    width: 200px;
    padding-top: 1rem;
    padding-bottom: 0.7rem;
  }
}

@screen desktop {
  .emp-hdr-masthead {
    @apply px-6;
  }
  .emp-hdr-masthead__logo {
    width: 250px;
    padding-top: 1rem;
    padding-bottom: 0.5rem;
  }
}
</style>
