import { captureException } from '@sentry/core'
import i18nConfig from '../locales'

/**
 * Try to URI-decode provided path,
 * return original path as fallback
 * @param {string} path
 * @returns {string}
 */
export function decodePathURI(path) {
  if (path) {
    try {
      return decodeURI(path)
    } catch (err) {
      captureException(err)
    }
  }
  return path
}

/**
 * Prepend leading slash + locale code to path
 * @param {string} path
 * @param {string} localeCode
 * @returns {string}
 */
export function prependLocalePrefix(path, localeCode) {
  if (path && localeCode) {
    return `/${localeCode}${path}`
  }
  return ''
}

/**
 * Format localised path
 * for use by vue router,
 * remove prefix for provided locale
 * @param {string} path
 * @param {string?} defaultLocale
 * @returns {string}
 */
export function formatLocalePath(path, defaultLocale) {
  if (path) {
    // Get regex for provided locale prefix,
    // e.g. /zh-hant, /en
    // fallback to default locale
    const defaultLocaleRegexp = new RegExp(`^/${defaultLocale || i18nConfig.defaultLocale}`)

    // Ensure path is URI-decoded
    return (
      decodePathURI(`${path}/`)
        // Remove leading default locale code
        .replace(defaultLocaleRegexp, '')
        // Remove duplicated trailing slashes
        .replace(/\/+$/g, '/')
    )
  }
  return ''
}

/**
 * Format localised paths
 * for use by vue router
 * @param {Dictionary<string>} localePaths
 * @param {string?} defaultLocale
 * @returns {Dictionary<string>}
 */
export function formatLocalePaths(localePaths, defaultLocale) {
  const formattedLocalePaths = {}
  if (localePaths) {
    Object.entries(localePaths).forEach(([localeCode, path]) => {
      if (localeCode && path) {
        // Prepend locale prefix if not available yet
        let localePath = path
        if (!localePath.startsWith(`/${localeCode}`)) {
          localePath = prependLocalePrefix(localePath, localeCode)
        }

        // Format path for use by vue router
        formattedLocalePaths[localeCode] = formatLocalePath(localePath, defaultLocale)
      }
    })
  }

  return formattedLocalePaths
}

/**
 * Get page name based on localised path
 * @example
 * - pages: { 'employer/landing': { en: '/en/employer/', 'zh-hant': '/僱主/' } }
 * - path: '/en/employer/'
 * =>
 * 'employer/landing'
 * @param {Dictionary<Dictionary<string>>} pages
 * @param {string} path
 * @returns {string}
 */
export function getPageNameByLocalePath(pages, path) {
  if (pages && path) {
    const formattedPath = formatLocalePath(path)
    const pageLocales = Object.entries(pages).find(([pageName, localePaths]) =>
      Object.values(localePaths).some((localePath) => localePath === formattedPath),
    )
    if (Array.isArray(pageLocales) && pageLocales[0]) {
      return pageLocales[0]
    }
  }
  return ''
}

/**
 * Extract lookup map of each localised path of an article page,
 * to the markdown file and other localised paths
 * @example
 * 'employer/landing': { en: '/en/employer/', 'zh-hant': '/僱主/' }
 * =>
 * {
 *   '/僱主/': { en: '/en/employer/', md: 'zh-hant/employer/landing/index', 'zh-hant': '/僱主/' },
 *   '/en/employer/': { en: '/en/employer/', md: 'en/employer/landing/index', 'zh-hant': '/僱主/' }
 * }
 * @param {Dictionary<Dictionary<string>>} pages
 * @returns {Dictionary<Dictionary<string>>}
 */
export function getArticlePageLocalePaths(pages) {
  const localePathsMap = {}
  Object.entries(pages).forEach(([pageName, localePaths]) => {
    Object.entries(localePaths).forEach(([localeCode, localePath]) => {
      localePathsMap[localePath] = {
        ...localePaths,
        md: `${localeCode}/${pageName}/index`,
      }
    })
  })
  return localePathsMap
}
