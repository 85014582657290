/**
 * Auth *Vuex* module mutations
 */
export default {
  /**
   * Add callback function to access token ready queue
   * @param {Function} callback
   */
  addReadyCallback(state, callback) {
    if (typeof callback === 'function') {
      state.readyQueue.push(callback)
    }
  },

  /**
   * Clear access token ready queue
   */
  clearReadyQueue(state) {
    state.readyQueue = []
  },

  /**
   * Set provided token value to store
   */
  setTokenAndPayload(state, { payload = null, token = '' } = {}) {
    state.token = token
    state.payload = payload
  },
}
