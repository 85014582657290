import PlatformMixin from '../../mixins/platform'
import { sendAccountSectionClickEvent } from '../../libs/tracking/utils/event'

export const accountEventMapToPage = {
  my_application: 'myApplications',
  my_job_alert: 'myJobAlert',
  my_notification: 'notifications',
  my_profile: 'myProfile',
}

const appInstallLinks = {
  my_job_alert: 'https://s.moovup.com/myjobalert-install-mobile',
  my_notification: 'https://s.moovup.com/noti-install-mobile',
}

export default {
  methods: {
    clickAccountSections(sectionName) {
      const page = accountEventMapToPage[sectionName]
      if (page) {
        sendAccountSectionClickEvent(this.$eventTracker, sectionName)
        const link = appInstallLinks[sectionName]
        if (this.isMobile && link) {
          window.open(link, '_blank')
        } else {
          this.$router.push({
            path: this.localePathName('/profile'),
            query: {
              page,
            },
          })
        }
      }
    },
  },
  mixins: [PlatformMixin],
}
