<template>
  <div class="acc" :class="toggled ? 'acc--toggled' : ''">
    <div class="acc__button">
      <div class="acc__button-container">
        <slot name="button" />
      </div>
      <svg-arrow-down class="acc__button-icon svg-fill" />
    </div>
    <div class="acc__items">
      <slot name="items" />
    </div>
  </div>
</template>

<script>
import SvgArrowDown from '../../assets/svg/arrowDown.svg?inline'

export default {
  components: {
    'svg-arrow-down': SvgArrowDown,
  },
  props: {
    /**
     * Whether accordion has been expanded
     */
    toggled: {
      default: false,
      type: Boolean,
    },
  },
}
</script>

<style lang="postcss" scoped>
/* Accordion toggle button */
.acc__button {
  @apply flex items-center relative bg-white;
}
.acc__button-container {
  @apply relative w-full z-0;
}
.acc__button-icon {
  @apply absolute w-b-sm h-b-sm;
  @apply z-1 pointer-events-none;
  @apply text-grey-900;
  top: calc(50% - 12px);
  right: 0.5rem;
  transform-origin: center center;
  transition: transform 0.2s ease;
}

/* Accordion items */
.acc__items {
  @apply px-2;
}

/* Collapsed accordion */
.acc:not(.acc--toggled) {
  & .acc__items {
    @apply hidden;
  }
}
/* Expanded accordion */
.acc.acc--toggled {
  /* Accordion toggle button */
  & .acc__button-icon {
    transform: rotate(180deg);
  }
}

@screen desktop {
  /* Accordion toggle button */
  .acc__button-icon {
    @apply w-b h-b;
    top: calc(50% - 16px);
  }
}
</style>
