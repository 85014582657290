/**
 * Localization config
 * https://nuxt-community.github.io/nuxt-i18n/options-reference.html
 */
export default {
  /** Default locale; path prefix won't be added */
  defaultLocale: 'zh-hant',

  /**
   * Detect locale based on browser language:
   * Disabled since will interfere with search engine crawling,
   * ref: https://support.google.com/webmasters/answer/182192?hl=en&ref_topic=2370587
   */
  detectBrowserLanguage: false,

  /** Locale files directory, i.e. this directory */
  langDir: './locales/',

  /** Lazy load locale files */
  lazy: true,

  /**
   * Locale definitions, refs:
   * https://hreflang.org/what-is-a-valid-hreflang/,
   * https://support.google.com/webmasters/answer/189077#language-codes
   */
  locales: [
    {
      code: 'en',
      codeAlt: 'en',
      file: 'en.js',
      iso: 'en',
      name: 'English',
      nameShort: 'ENG',
    },
    {
      code: 'zh-hant',
      // Used for wordpress
      codeAlt: 'zh_hk',
      file: 'zh-hant.js',
      iso: 'zh-HK',
      // Alternative ISO codes for use in sitemap
      isoAlt: ['zh', 'zh-Hant'],
      name: '繁體中文',
      nameShort: '中文',
    },
  ],

  /** Add path prefix for all locales except default */
  strategy: 'prefix_except_default',

  /** Vue i18n config */
  vueI18n: {
    fallbackLocale: 'zh-hant',
  },
}
