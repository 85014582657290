import { decodePathURI } from '../../libs/locale'

/**
 * Localisation Vuex module getters
 */
export default {
  /**
   * Return function that get localised path mapping
   * based on provided $route.path
   * @param {*} state
   * @returns {function(string): Dictionary<string>}
   */
  getPageLocalePaths(state) {
    return (path) => {
      // Use URI-decoded path for lookup
      return state.pageLocalePaths[decodePathURI(path)]
    }
  },
}
