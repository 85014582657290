import { postOcto } from './'

/**
 * Send POST login-mfa request to Octo *REST* endpoint,
 * @param {string} displayLanguage
 * @param {string} channelId
 * @param {string} channel
 * @param {string} device
 */
export function requestLoginMfa(displayLanguage, channelId, channel, device, token) {
  return postOcto(
    'request-mfa-login',
    {
      channel,
      channel_id: channelId,
      device,
      display_language: displayLanguage,
    },
    token,
  )
}

/**
 * Send POST login request to Octo *REST* endpoint,
 * returns token if success
 * @param {string} displayLanguage
 * @param {string} channelId
 * @param {string} channel
 * @param {string} pinCode
 */
export function loginPinCode(displayLanguage, channelId, channel, pinCode, token) {
  return postOcto(
    'mfa-login',
    {
      channel,
      channel_id: channelId,
      display_language: displayLanguage,
      token: parseInt(pinCode),
    },
    token,
  )
}

/**
 * Send POST logout request to Octo *REST* endpoint,
 * with provided seeker token
 * @param {string} token
 */
export function logout(token, deviceToken) {
  return postOcto(
    'logout',
    {
      device_token: deviceToken,
    },
    token,
  )
}

/**
 * Refresh a new token
 * return a new token
 * @param {string} token
 */
export function refreshToken(token) {
  return postOcto('refresh', null, token)
}

/**
 * Check if Cloudflare Challenge for MFA exists
 * returns boolean
 */
export async function isMfaChallengeExists() {
  // test for Cloudflare challenges
  const res = await postOcto('request-mfa-login', {}, '')
  // only enable Cloudflare Turnstile if MFA challenge and
  // not the octo unauthenticated error
  return res.error && res.error.status !== 401
}
