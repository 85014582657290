<template>
  <div>
    <button class="b-menu" @click="handleClick">
      <svg-hamburger-menu />
    </button>
    <r-side-menu :class="sideMenuClass" :items="items" @close="closeSideMenu" @click="onItemClick" />
  </div>
</template>

<script>
import ProfileMixin from '../../mixins/profile'
import RightSideMenu from '../menu/rightSideMenu'
import SvgHamMenu from '../../assets/svg/hamburger_menu.svg'

export default {
  components: {
    'r-side-menu': RightSideMenu,
    'svg-hamburger-menu': SvgHamMenu,
  },
  computed: {
    sideMenuClass() {
      return {
        'side-menu--open': this.isActive,
      }
    },
  },
  data() {
    return {
      isActive: false,
    }
  },
  methods: {
    /**
     * dismiss side menu
     */
    closeSideMenu() {
      this.isActive = false
    },
    /**
     * open side menu
     */
    handleClick() {
      this.isActive = true
    },
    /**
     * Handle header menu item click action
     * based on provided item index
     */
    onItemClick(index) {
      this.closeSideMenu()
      this.$emit('click', index)
    },
  },
  mixins: [ProfileMixin],
  props: {
    /**
     * Menu items
     */
    items: {
      default: () => [],
      type: Array,
    },
  },
}
</script>

<style scoped>
.b-menu {
  @apply outline-none pr-1 mr-2;

  & h3 {
    @apply text-red-50 font-medium;
    line-height: 28px;
  }

  & svg {
    @apply text-grey h-8 w-8;
  }
}

@screen mobile {
  .side-menu--open {
    & >>> .overlay {
      @apply pointer-events-auto;
      background-color: rgba(0, 0, 0, 0.6);
    }

    & >>> .side-menu {
      transform: translate3d(15%, 0, 0);
      -webkit-transform: translate3d(15%, 0, 0);
    }
  }
}
</style>
