import { getPageMetadata, getPageUrlTags } from '../../libs/metadata'
import AppFooter from '../../components/footer/appFooter'
import AppHeader from '../../components/header/appHeader'
import Loading from '../../components/loading'
import { sendJobQueryEvent } from '../../libs/tracking/utils/event'

export default {
  components: {
    'app-footer': AppFooter,
    'app-header': AppHeader,
    loading: Loading,
  },
  computed: {
    /** Default title and description tags */
    defaultTags() {
      return getPageMetadata(this.$t('metadata.title'), this.$t('metadata.description'))
    },
    isLoading() {
      return this.$store.getters['loading/isLoading']
    },
    loadingClass() {
      return {
        loading: !this.isLoading,
      }
    },
    /** Page URL <link> and <meta> tags */
    urlTags() {
      return getPageUrlTags(
        // Base URL
        process.env.SEEKER_WEB__BASE_URL,
        // Current locale path
        this.$route.path,
        // Default locale path
        this.switchLocalePath(this.$i18n.defaultLocale),
      )
    },
  },
  head() {
    // Generate localization metadata
    const i18nTags = this.$nuxtI18nSeo()

    return {
      htmlAttrs: i18nTags.htmlAttrs,
      link: this.urlTags.link,
      meta: [...this.defaultTags.meta, ...this.urlTags.meta, ...i18nTags.meta],
      title: this.defaultTags.title,
    }
  },
  methods: {
    onTextSearch(searchText) {
      sendJobQueryEvent(this.$eventTracker, {
        searchTerm: searchText,
        source: 'free_text_search',
      })
      this.$router.push({
        path: this.localePathName('/find-jobs'),
        query: {
          q: searchText,
        },
      })
    },
  },
  watch: {
    isLoading(isLoading) {
      if (isLoading) {
        if (document) {
          document.body.classList.add('body--load')
        }
      } else if (document) {
        document.body.classList.remove('body--load')
      }
    },
  },
}
