import { cleanupIntersectionObserver, createIntersectionObserver } from '../../libs/tracking/utils/impression'

export default {
  beforeDestroy() {
    // Cleanup intersection observer
    // before component destruction
    cleanupIntersectionObserver(this.observer)
  },
  computed: {
    observerObj() {
      return {
        value: this.observer,
      }
    },
  },
  data() {
    return {
      interaction: {},
      observer: null,
    }
  },
  methods: {
    isInteraction(id) {
      return !!this.interaction[id]
    },
    monitorImpression() {
      this.observer = createIntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          this.$set(this.interaction, entry.target.id, entry.isIntersecting)
        })
      })
    },
  },
  mounted() {
    this.monitorImpression()
  },
}
