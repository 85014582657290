import { isSeekerToken } from '../../libs/token'

/**
 * Auth *Vuex* module getters
 */
export default {
  /**
   * is Anonymous User
   * @readonly
   */
  isAnonymousUser(state) {
    return !isSeekerToken(state.payload)
  },

  /**
   * Whether user is logged in
   * @readonly
   */
  isLoggedIn(state) {
    return isSeekerToken(state.payload)
  },

  /**
   * User ID from token payload
   * @readonly
   */
  userId(state) {
    if (state.payload && state.payload.sub) {
      return state.payload.sub
    }
    return ''
  },
}
