<template>
  <div class="app">
    <nuxt id="pageScrollContainer" class="page page--full" />
    <error-box :message="errorMessage" :show="isShowError" :action="$t('general.ok')" @action="dismissError" />
  </div>
</template>

<script>
import AuthMonitorMixin from '../mixins/auth/monitor'
import ErrorBox from '../components/error/errorBox'
import ErrorBoxMixin from '../mixins/error/errorBox'
import TrackingMixin from '../mixins/layout/tracking'

export default {
  components: {
    'error-box': ErrorBox,
  },
  mixins: [AuthMonitorMixin, ErrorBoxMixin, TrackingMixin],
  name: 'PopupLayout',
}
</script>

<style lang="postcss" scoped>
@screen desktop {
  .page.page--full {
    @apply justify-center py-8;
  }
}
</style>
