import { injectScript } from '../utils/init'
import Tracker from './tracker'

/**
 * Initialize Pixel API
 */
const getFbq = () => {
  const fbq = function () {
    if (fbq.callMethod) {
      fbq.callMethod.apply(fbq, arguments)
    } else {
      fbq.queue.push(arguments)
    }
  }
  fbq.queue = []
  fbq.push = fbq
  fbq.loaded = true
  fbq.version = '2.0'

  return fbq
}

export default class FacebookPixelTracker extends Tracker {
  constructor(id) {
    super(id)
    this._id = id
    this._fbInitDone = false

    // Initialize Pixel API
    const fbq = getFbq()
    this._fbq = fbq

    // Set Pixel API reference to window
    if (typeof window !== 'undefined' && !window.fbq) {
      window.fbq = fbq
      window._fbq = fbq
    }
  }

  deferInit() {
    // Only initialize once
    if (!this._deferInitDone) {
      injectScript('https://connect.facebook.net/en_US/fbevents.js', true)
      super.deferInit()
    }
  }

  sendPageViewEvent(route, pageTitle, pageLocation, pagePath) {
    // Set Pixel ID once
    // before sending conversion
    if (!this._fbInitDone) {
      this._fbq('init', this._id)
      this._fbInitDone = true
    }

    this._fbq('track', 'PageView')
  }
}
