import {
  APPLICATION_FRAGMENT_CORE,
  FEATURE_LIST_RESULT_FRAGMENT,
  FEATURED_COMPANY_FRAGMENT,
  FEATURED_COMPANY_LIST_RESULT_FRAGMENT,
  HOME_BANNER_FRAGMENT,
  JOB_FEATURE_LIST_FRAGMENT,
  JOB_FRAGMENT_CORE,
  JOB_FRAGMENT_FOR_APPLY,
  JOB_FRAGMENT_FULL,
  JOB_LIST_FRAGMENT,
  JOB_SEARCH_RESULT_FRAGMENT,
  NOTIFICATION_FRAGMENT_CORE,
} from './fragments'

/** Get all deleted job IDs since provided timestamp */
export const GET_ALL_DELETED_JOB_IDS_QUERY = `
query GetAllDeletedJobIDs (
  $since: Timestamp
) {
  all_deleted_jobs (
    since: $since
  )
}
`

/** Get all job IDs */
export const GET_ALL_JOB_IDS_QUERY = `
query GetAllJobIDs (
  $since: Timestamp
) {
  all_jobs (
    since: $since
  )
}
`

/** Get all homepage data */
export const GET_HOMEPAGE_FULL_QUERY = `
query GetHomepageFull {
  homepage {
    home_banners {
      ...homeBannerFragment
    }
    job_featured_lists {
      ...jobFeatureListFragment
    }
    job_recommended_list {
      ...jobListFragment
    }
    featured_companies {
      ...featuredCompanyFragment
    }
  }
}

${HOME_BANNER_FRAGMENT}
${JOB_LIST_FRAGMENT}
${JOB_FEATURE_LIST_FRAGMENT}
${FEATURED_COMPANY_FRAGMENT}
`

/** Get homepage feature list */
export const GET_HOMEPAGE_FEATURE_LIST = `
query GetHomepageFeatureList {
  homepage {
    job_featured_lists {
      ...jobFeatureListFragment
    }
  }
}

${JOB_FEATURE_LIST_FRAGMENT}
`

/** Get homepage data for site generation */
export const GET_HOMEPAGE_STATIC_QUERY = `
query GetHomepageStatic {
  homepage {
    home_banners {
      ...homeBannerFragment
    }
    job_featured_lists {
      ...jobFeatureListFragment
    }
    featured_companies {
      ...featuredCompanyFragment
    }
  }
}

${HOME_BANNER_FRAGMENT}
${JOB_FEATURE_LIST_FRAGMENT}
${FEATURED_COMPANY_FRAGMENT}
`

/** Get featured company data for site generation */
export const GET_FEATURED_COMPANY_STATIC_QUERY = `
query GetFeaturedCompanyStatic {
  featured_company_list (
    limit: 12
    offset: 0
  ) {
    ...featuredCompanyListResultFragment
  }
}

${FEATURED_COMPANY_LIST_RESULT_FRAGMENT}
`

/** Get all recommended list data */
export const GET_RECOMMENDED_LIST = `
query GetRecommendedList {
  homepage {
    job_recommended_list {
      ...jobListFragment
    }
  }
}

${JOB_LIST_FRAGMENT}
`
/** Get job by job ID */
export const GET_JOBS_BY_IDS_QUERY = `
query GetJobsByIDs (
  $ids: [ID]
) {
  get_jobs (
    _id: $ids
  ) {
    ...jobFragmentFull
  }
}

${JOB_FRAGMENT_FULL}
`

/** Get breif job by job ID */
export const GET_BREIF_JOBS_BY_IDS_QUERY = `
query GetJobsByIDs (
  $ids: [ID]
) {
  get_jobs (
    _id: $ids
  ) {
    ...jobFragmentForApply
  }
}

${JOB_FRAGMENT_FOR_APPLY}
`

/** Get ad job by job ID */
export const GET_ADS_JOBS_BY_IDS_QUERY = `
query GetJobsByIDs (
  $ids: [ID]
) {
  get_jobs (
    _id: $ids
  ) {
    ...jobFragmentCore
  }
}

${JOB_FRAGMENT_CORE}
`

/** Get related and company jobs by job ID and company ID */
export const GET_RELATED_AND_COMPANY_JOBS_BY_IDS_QUERY = `
query GetRelatedAndCompanyJobsByIds (
  $companyIDs: [ID]
  $jobID: ID
  $limit: Int
  $offset: Int
  $exclude: [ID]
) {
  related_jobs (
    _id: $jobID
    limit: $limit
    offset: $offset
  ) {
    ...jobSearchResultFragment
  }
  company_jobs: job_search (
    company: $companyIDs
    exclude: $exclude
    limit: $limit
    offset: $offset
  ) {
    ...jobSearchResultFragment
  }
}

${JOB_SEARCH_RESULT_FRAGMENT}
`

/** Get related and company jobs by job ID and company ID */
export const GET_RELATED_JOBS_BY_IDS_QUERY = `
query GetRelatedJobsByIds (
  $jobID: ID
  $limit: Int
  $offset: Int
) {
  related_jobs (
    _id: $jobID
    limit: $limit
    offset: $offset
  ) {
    ...jobSearchResultFragment
  }
}

${JOB_SEARCH_RESULT_FRAGMENT}
`

export const QUERY_JOBS_BASE = `
query QueryJobs (
  $company: ID
  $exclude: [ID]
  $district: [ID]
  $employment: [Employment]
  $workingHour: [JobSearchWorkingHour]
  $fromWorkingDaysPerWeek: Float
  $fromWorkingHoursPerDay: Float
  $hourlyRate: Float
  $monthlyRate: Float
  $jobType: [ID]
  $limit: Int
  $offset: Int
  $term: String
  $latlng: MLatLng
) {
  job_search(
    company: $company
    exclude: $exclude
    term: $term,
    district: $district,
    employment: $employment,
    from_working_days_per_week: $fromWorkingDaysPerWeek,
    from_working_hours_per_day: $fromWorkingHoursPerDay,
    hourly_rate: $hourlyRate,
    monthly_rate: $monthlyRate,
    job_type: $jobType,
    limit: $limit,
    offset: $offset,
    working_hour: $workingHour,
    latlng: $latlng
  ) {
    __SLOT__
  }
}
__FRAGMENT__
`

/** Query jobs by filter */
export const QUERY_JOBS = QUERY_JOBS_BASE.replace('__SLOT__', '...jobSearchResultFragment').replace(
  '__FRAGMENT__',
  `${JOB_SEARCH_RESULT_FRAGMENT}`,
)

/** Query jobs by filter with count */
export const QUERY_JOBS_COUNT = QUERY_JOBS_BASE.replace('__SLOT__', 'total').replace('__FRAGMENT__', '')

/** Get related and company jobs by job ID and company ID */
export const GET_FEATURE_LIST = `
query GetFeatureList (
  $id: ID
  $limit: Int
  $offset: Int
) {
  job_list (
    _id: $id
    limit: $limit
    offset: $offset
  ) {
    ...featureListResultFragment
  }
}

${FEATURE_LIST_RESULT_FRAGMENT}
`

/** Get featured company list */
export const GET_FEATURED_COMPANY_LIST = `
query GetFeaturedCompanyList (
  $limit: Int
  $offset: Int
) {
  featured_company_list (
    limit: $limit
    offset: $offset
  ) {
    ...featuredCompanyListResultFragment
  }
}

${FEATURED_COMPANY_LIST_RESULT_FRAGMENT}
`

export const GET_APPLICATION = `
query GetApplications (
  $applicationIDs: [ID]
  $limit: Int
  $offset: Int
  $status: ApplicationStatus
) {
  get_applications (
    application_ids: $applicationIDs
    limit: $limit
    offset: $offset
    status: $status
  ) {
    applications {
      ...applicationFragmentCore
      job {
        ...jobFragmentForApply
      }
    }
    total
  }
}

${APPLICATION_FRAGMENT_CORE}
${JOB_FRAGMENT_FOR_APPLY}
`

/** Get applications */
export const GET_APPLICATIONS = `
query GetApplications (
  $limit: Int
  $offset: Int
  $status: ApplicationStatus
) {
  get_applications (
    limit: $limit
    offset: $offset
    status: $status
  ) {
    applications {
      ...applicationFragmentCore
      job {
        ...jobFragmentCore
      }
    }
    total
  }
}

${APPLICATION_FRAGMENT_CORE}
${JOB_FRAGMENT_CORE}
`

/** Get applications */
export const GET_NOTIFICATIONS = `
query GetNotifications (
  $limit: Int
  $offset: Int
) {
  get_notifications (
    limit: $limit
    offset: $offset
  ) {
    notifications {
      ...notificationFragmentCore
    }
    total
  }
}

${NOTIFICATION_FRAGMENT_CORE}
${JOB_FRAGMENT_CORE}
`

export const GET_COMPANY = `
query GetCompany ($company: ID){
  get_company(_id: $company) {
    _id
    about
    name
    company_logo
    company_logo_medium
    company_logo_thumbnail
  }
}
`
