import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _17e1b077 = () => interopDefault(import('../pages/account-restore/index.vue' /* webpackChunkName: "pages/account-restore/index" */))
const _7f884277 = () => interopDefault(import('../pages/apply/index.vue' /* webpackChunkName: "pages/apply/index" */))
const _78ea9146 = () => interopDefault(import('../pages/company/index.vue' /* webpackChunkName: "pages/company/index" */))
const _0cce6818 = () => interopDefault(import('../pages/contact-us/index.vue' /* webpackChunkName: "pages/contact-us/index" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _270ec72e = () => interopDefault(import('../pages/featured-companies/index.vue' /* webpackChunkName: "pages/featured-companies/index" */))
const _5e26a0f3 = () => interopDefault(import('../pages/find-jobs/index.vue' /* webpackChunkName: "pages/find-jobs/index" */))
const _71653be6 = () => interopDefault(import('../pages/login-mfa/index.vue' /* webpackChunkName: "pages/login-mfa/index" */))
const _cad79e9c = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _4e16ad99 = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _7de68bc4 = () => interopDefault(import('../pages/privacy-policy/index.vue' /* webpackChunkName: "pages/privacy-policy/index" */))
const _4bb7511c = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _fe62773e = () => interopDefault(import('../pages/terms-and-conditions/index.vue' /* webpackChunkName: "pages/terms-and-conditions/index" */))
const _0f871f2a = () => interopDefault(import('../pages/account-restore/success/index.vue' /* webpackChunkName: "pages/account-restore/success/index" */))
const _6131026b = () => interopDefault(import('../pages/apply/success/index.vue' /* webpackChunkName: "pages/apply/success/index" */))
const _242cd53e = () => interopDefault(import('../pages/company/advanced/index.vue' /* webpackChunkName: "pages/company/advanced/index" */))
const _c541b9e8 = () => interopDefault(import('../pages/employer/recruitment-services/index.vue' /* webpackChunkName: "pages/employer/recruitment-services/index" */))
const _e1204f58 = () => interopDefault(import('../pages/find-jobs/advanced/index.vue' /* webpackChunkName: "pages/find-jobs/advanced/index" */))
const _9cf24ba0 = () => interopDefault(import('../pages/profile/create-apply/index.vue' /* webpackChunkName: "pages/profile/create-apply/index" */))
const _f248362a = () => interopDefault(import('../pages/profile/create-profile/index.vue' /* webpackChunkName: "pages/profile/create-profile/index" */))
const _0be390ae = () => interopDefault(import('../pages/employer/blogs-list/_name/_page.vue' /* webpackChunkName: "pages/employer/blogs-list/_name/_page" */))
const _62d112f2 = () => interopDefault(import('../pages/blogs/_name.vue' /* webpackChunkName: "pages/blogs/_name" */))
const _382d1300 = () => interopDefault(import('../pages/employer/_name.vue' /* webpackChunkName: "pages/employer/_name" */))
const _f0e43ab2 = () => interopDefault(import('../pages/feature/_id.vue' /* webpackChunkName: "pages/feature/_id" */))
const _5c6fffab = () => interopDefault(import('../pages/find-jobs/_slug.vue' /* webpackChunkName: "pages/find-jobs/_slug" */))
const _49db7cc3 = () => interopDefault(import('../pages/jobseeker/_name.vue' /* webpackChunkName: "pages/jobseeker/_name" */))
const _609748be = () => interopDefault(import('../pages/latest-news/_page.vue' /* webpackChunkName: "pages/latest-news/_page" */))
const _68cebf84 = () => interopDefault(import('../pages/blogs-list/_name/_page.vue' /* webpackChunkName: "pages/blogs-list/_name/_page" */))
const _7330a84c = () => interopDefault(import('../pages/company/_slug/_companyId.vue' /* webpackChunkName: "pages/company/_slug/_companyId" */))
const _420364e9 = () => interopDefault(import('../pages/job/_slug/_id.vue' /* webpackChunkName: "pages/job/_slug/_id" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/hk/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account-restore/",
    component: _17e1b077,
    pathToRegexpOptions: {"strict":true},
    name: "account-restore___zh-hant"
  }, {
    path: "/apply/",
    component: _7f884277,
    pathToRegexpOptions: {"strict":true},
    name: "apply___zh-hant"
  }, {
    path: "/company/",
    component: _78ea9146,
    pathToRegexpOptions: {"strict":true},
    name: "company___zh-hant"
  }, {
    path: "/contact-us/",
    component: _0cce6818,
    pathToRegexpOptions: {"strict":true},
    name: "contact-us___zh-hant"
  }, {
    path: "/en/",
    component: _2dfb1658,
    pathToRegexpOptions: {"strict":true},
    name: "index___en"
  }, {
    path: "/featured-companies/",
    component: _270ec72e,
    pathToRegexpOptions: {"strict":true},
    name: "featured-companies___zh-hant"
  }, {
    path: "/find-jobs/",
    component: _5e26a0f3,
    pathToRegexpOptions: {"strict":true},
    name: "find-jobs___zh-hant"
  }, {
    path: "/login-mfa/",
    component: _71653be6,
    pathToRegexpOptions: {"strict":true},
    name: "login-mfa___zh-hant"
  }, {
    path: "/login/",
    component: _cad79e9c,
    pathToRegexpOptions: {"strict":true},
    name: "login___zh-hant"
  }, {
    path: "/maintenance/",
    component: _4e16ad99,
    pathToRegexpOptions: {"strict":true},
    name: "maintenance___zh-hant"
  }, {
    path: "/privacy-policy/",
    component: _7de68bc4,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___zh-hant"
  }, {
    path: "/profile/",
    component: _4bb7511c,
    pathToRegexpOptions: {"strict":true},
    name: "profile___zh-hant"
  }, {
    path: "/terms-and-conditions/",
    component: _fe62773e,
    pathToRegexpOptions: {"strict":true},
    name: "terms-and-conditions___zh-hant"
  }, {
    path: "/account-restore/success/",
    component: _0f871f2a,
    pathToRegexpOptions: {"strict":true},
    name: "account-restore-success___zh-hant"
  }, {
    path: "/apply/success/",
    component: _6131026b,
    pathToRegexpOptions: {"strict":true},
    name: "apply-success___zh-hant"
  }, {
    path: "/company/advanced/",
    component: _242cd53e,
    pathToRegexpOptions: {"strict":true},
    name: "company-advanced___zh-hant"
  }, {
    path: "/employer/recruitment-services/",
    component: _c541b9e8,
    pathToRegexpOptions: {"strict":true},
    name: "employer-recruitment-services___zh-hant"
  }, {
    path: "/en/account-restore/",
    component: _17e1b077,
    pathToRegexpOptions: {"strict":true},
    name: "account-restore___en"
  }, {
    path: "/en/apply/",
    component: _7f884277,
    pathToRegexpOptions: {"strict":true},
    name: "apply___en"
  }, {
    path: "/en/company/",
    component: _78ea9146,
    pathToRegexpOptions: {"strict":true},
    name: "company___en"
  }, {
    path: "/en/contact-us/",
    component: _0cce6818,
    pathToRegexpOptions: {"strict":true},
    name: "contact-us___en"
  }, {
    path: "/en/featured-companies/",
    component: _270ec72e,
    pathToRegexpOptions: {"strict":true},
    name: "featured-companies___en"
  }, {
    path: "/en/find-jobs/",
    component: _5e26a0f3,
    pathToRegexpOptions: {"strict":true},
    name: "find-jobs___en"
  }, {
    path: "/en/login-mfa/",
    component: _71653be6,
    pathToRegexpOptions: {"strict":true},
    name: "login-mfa___en"
  }, {
    path: "/en/login/",
    component: _cad79e9c,
    pathToRegexpOptions: {"strict":true},
    name: "login___en"
  }, {
    path: "/en/maintenance/",
    component: _4e16ad99,
    pathToRegexpOptions: {"strict":true},
    name: "maintenance___en"
  }, {
    path: "/en/privacy-policy/",
    component: _7de68bc4,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___en"
  }, {
    path: "/en/profile/",
    component: _4bb7511c,
    pathToRegexpOptions: {"strict":true},
    name: "profile___en"
  }, {
    path: "/en/terms-and-conditions/",
    component: _fe62773e,
    pathToRegexpOptions: {"strict":true},
    name: "terms-and-conditions___en"
  }, {
    path: "/find-jobs/advanced/",
    component: _e1204f58,
    pathToRegexpOptions: {"strict":true},
    name: "find-jobs-advanced___zh-hant"
  }, {
    path: "/profile/create-apply/",
    component: _9cf24ba0,
    pathToRegexpOptions: {"strict":true},
    name: "profile-create-apply___zh-hant"
  }, {
    path: "/profile/create-profile/",
    component: _f248362a,
    pathToRegexpOptions: {"strict":true},
    name: "profile-create-profile___zh-hant"
  }, {
    path: "/en/account-restore/success/",
    component: _0f871f2a,
    pathToRegexpOptions: {"strict":true},
    name: "account-restore-success___en"
  }, {
    path: "/en/apply/success/",
    component: _6131026b,
    pathToRegexpOptions: {"strict":true},
    name: "apply-success___en"
  }, {
    path: "/en/company/advanced/",
    component: _242cd53e,
    pathToRegexpOptions: {"strict":true},
    name: "company-advanced___en"
  }, {
    path: "/en/employer/recruitment-services/",
    component: _c541b9e8,
    pathToRegexpOptions: {"strict":true},
    name: "employer-recruitment-services___en"
  }, {
    path: "/en/find-jobs/advanced/",
    component: _e1204f58,
    pathToRegexpOptions: {"strict":true},
    name: "find-jobs-advanced___en"
  }, {
    path: "/en/profile/create-apply/",
    component: _9cf24ba0,
    pathToRegexpOptions: {"strict":true},
    name: "profile-create-apply___en"
  }, {
    path: "/en/profile/create-profile/",
    component: _f248362a,
    pathToRegexpOptions: {"strict":true},
    name: "profile-create-profile___en"
  }, {
    path: "/en/employer/blogs-list/:name?/:page?/",
    component: _0be390ae,
    pathToRegexpOptions: {"strict":true},
    name: "employer-blogs-list-name-page___en"
  }, {
    path: "/en/blogs/:name?/",
    component: _62d112f2,
    pathToRegexpOptions: {"strict":true},
    name: "blogs-name___en"
  }, {
    path: "/en/employer/:name?/",
    component: _382d1300,
    pathToRegexpOptions: {"strict":true},
    name: "employer-name___en"
  }, {
    path: "/en/feature/:id?/",
    component: _f0e43ab2,
    pathToRegexpOptions: {"strict":true},
    name: "feature-id___en"
  }, {
    path: "/en/find-jobs/:slug?/",
    component: _5c6fffab,
    pathToRegexpOptions: {"strict":true},
    name: "find-jobs-slug___en"
  }, {
    path: "/en/jobseeker/:name?/",
    component: _49db7cc3,
    pathToRegexpOptions: {"strict":true},
    name: "jobseeker-name___en"
  }, {
    path: "/en/latest-news/:page?/",
    component: _609748be,
    pathToRegexpOptions: {"strict":true},
    name: "latest-news-page___en"
  }, {
    path: "/employer/blogs-list/:name?/:page?/",
    component: _0be390ae,
    pathToRegexpOptions: {"strict":true},
    name: "employer-blogs-list-name-page___zh-hant"
  }, {
    path: "/en/blogs-list/:name?/:page?/",
    component: _68cebf84,
    pathToRegexpOptions: {"strict":true},
    name: "blogs-list-name-page___en"
  }, {
    path: "/en/company/:slug/:companyId?/",
    component: _7330a84c,
    pathToRegexpOptions: {"strict":true},
    name: "company-slug-companyId___en"
  }, {
    path: "/en/job/:slug?/:id?/",
    component: _420364e9,
    pathToRegexpOptions: {"strict":true},
    name: "job-slug-id___en"
  }, {
    path: "/blogs/:name?/",
    component: _62d112f2,
    pathToRegexpOptions: {"strict":true},
    name: "blogs-name___zh-hant"
  }, {
    path: "/employer/:name?/",
    component: _382d1300,
    pathToRegexpOptions: {"strict":true},
    name: "employer-name___zh-hant"
  }, {
    path: "/feature/:id?/",
    component: _f0e43ab2,
    pathToRegexpOptions: {"strict":true},
    name: "feature-id___zh-hant"
  }, {
    path: "/find-jobs/:slug?/",
    component: _5c6fffab,
    pathToRegexpOptions: {"strict":true},
    name: "find-jobs-slug___zh-hant"
  }, {
    path: "/jobseeker/:name?/",
    component: _49db7cc3,
    pathToRegexpOptions: {"strict":true},
    name: "jobseeker-name___zh-hant"
  }, {
    path: "/latest-news/:page?/",
    component: _609748be,
    pathToRegexpOptions: {"strict":true},
    name: "latest-news-page___zh-hant"
  }, {
    path: "/blogs-list/:name?/:page?/",
    component: _68cebf84,
    pathToRegexpOptions: {"strict":true},
    name: "blogs-list-name-page___zh-hant"
  }, {
    path: "/company/:slug/:companyId?/",
    component: _7330a84c,
    pathToRegexpOptions: {"strict":true},
    name: "company-slug-companyId___zh-hant"
  }, {
    path: "/job/:slug?/:id?/",
    component: _420364e9,
    pathToRegexpOptions: {"strict":true},
    name: "job-slug-id___zh-hant"
  }, {
    path: "/",
    component: _2dfb1658,
    pathToRegexpOptions: {"strict":true},
    name: "index___zh-hant"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
