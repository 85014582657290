<template>
  <div class="b--list">
    <n-link v-if="linkPath" class="b b--menu b--full" tabindex="0" v-bind="buttonProps" @click.native="onLinkClick">
      <span class="b__title">{{ title }}</span>
      <slot v-if="icon" name="icon" />
    </n-link>
    <button v-else class="b b--menu b--full" tabindex="0" v-bind="buttonProps" @click="onClick">
      <span class="b__title">{{ title }}</span>
      <slot v-if="icon" name="icon" />
    </button>
  </div>
</template>

<script>
import ButtonMixin from '../../mixins/button'

export default {
  mixins: [ButtonMixin],
  props: {
    icon: {
      default: false,
      type: Boolean,
    },
  },
}
</script>

<style lang="postcss" scoped>
.b.b--menu {
  @apply justify-start px-4 text-black;
  @apply rounded-none shadow-none;
  height: 2.5rem;

  &:after {
    @apply rounded-none;
  }
}

@screen desktop {
  .b.b--menu {
    @apply px-5;
    height: 3rem;
  }
}
</style>
