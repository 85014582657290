<template>
  <div class="app-footer-section">
    <div class="app-footer-section__desktop">
      <span class="app-footer-section__title">
        {{ title }}
      </span>
      <f-section-links :links="links" @click="onClick" />
    </div>
    <m-accord class="app-footer-section__mobile" :toggled="toggled">
      <!-- Item group accordion button -->
      <div slot="button" class="app-footer-section__title-expand" @click="onToggle">
        {{ title }}
      </div>
      <!-- Items -->
      <f-section-links slot="items" :links="links" @click="onClick" />
    </m-accord>
  </div>
</template>

<script>
import AccordionMenu from '../menu/accordionMenu'
import FooterSectionLinks from './appFooterSectionLinks'

export default {
  components: {
    'f-section-links': FooterSectionLinks,
    'm-accord': AccordionMenu,
  },
  data() {
    return {
      toggled: false,
    }
  },
  methods: {
    onClick(link) {
      this.$emit('click', link)
    },
    onToggle() {
      this.toggled = !this.toggled
    },
  },
  props: {
    links: {
      default: () => [],
      type: Array,
    },
    title: {
      default: '',
      type: String,
    },
  },
}
</script>

<style lang="postcss" scoped>
.app-footer-section::v-deep {
  & .acc__button {
    background-color: inherit;
    height: 48px;
  }
  & .acc__items {
    @apply mt-3 px-0;
  }
}
.app-footer-section {
  @apply flex flex-col;
}
.app-footer-section__title {
  @apply text-cplain-action2 font-cplain-action2;
}
.app-footer-section__title-expand {
  @apply text-cplain-action2 font-cplain-action2;
}
.app-footer-section__desktop {
  @apply flex flex-col;
}
@screen mobile {
  .app-footer-section__desktop {
    @apply hidden;
  }
  .ftr__link {
    @apply mb-7;
  }
  .app-footer-section__title {
    @apply mb-7;
  }
}
@screen desktop {
  .ftr__link:not(:last-child) {
    @apply mb-4;
  }
  .app-footer-section__title {
    @apply mb-4;
  }
  .app-footer-section__mobile {
    @apply hidden;
  }
}
</style>
