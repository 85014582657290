/**
 * Mixin for monitoring token change
 */
import { LS_TOKEN_KEY } from '../../libs/token'
import RedirectMixin from '../redirect'

const windowObj = typeof window !== 'undefined' ? window : null

export default {
  created() {
    this.startMonitor()
  },
  destroyed() {
    this.removeMonitor()
  },
  methods: {
    removeMonitor() {
      if (windowObj) {
        windowObj.removeEventListener('storage', this.tokenChange)
      }
    },
    startMonitor() {
      if (windowObj) {
        windowObj.addEventListener('storage', this.tokenChange)
      }
    },
    async tokenChange(event) {
      if (event && event.key && event.key === LS_TOKEN_KEY) {
        await this.$store.dispatch('auth/handleLocalStorageTokenChange', {
          newToken: event.newValue,
          routeCallback: (isLoggedIn) => {
            if (isLoggedIn) {
              if (
                this.$router.currentRoute.path.includes('/login') ||
                this.$router.currentRoute.path.includes('/login-mfa')
              ) {
                this.redirect()
              }
            } else {
              // Redirect to home page
              this.$router.push(this.localePathName('/'))
            }
          },
        })
      }
    },
  },
  mixins: [RedirectMixin],
}
