import { captureException } from '@sentry/browser'
import { getMSecTimestamp } from './datetime'
import jwtDecode from 'jwt-decode'

/** Default token issuer name */
const TOKEN_ISSUER = 'moovup'

/**
 * Check if token is expired or not
 */
const isTokenExpired = (tokenPayload) => {
  return !!tokenPayload && !!tokenPayload.exp && getMSecTimestamp(tokenPayload.exp) < Date.now()
}

/**
 * Try to decode token,
 * return token payload if success
 * @param {string} token
 */
export function decodeToken(token) {
  try {
    if (token) {
      const payload = jwtDecode(token)
      // Validate token issuer and expiry date
      if (payload && payload.iss === TOKEN_ISSUER && !isTokenExpired(payload)) {
        return payload
      }
    }
  } catch (err) {
    captureException(err)
  }
}

/**
 * Check if token is seeker-only or not:
 * 1) Token payload available,
 * 2) Anonymous flag *not* set to true
 */
export function isSeekerToken(payload) {
  return !!payload && !payload['anonymous?']
}

/**
 * *local storage* key for seeker web token
 */
export const LS_TOKEN_KEY = 'moovup_sw_token'
