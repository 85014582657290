<template>
  <ol class="m m--list">
    <template v-for="(item, i) in items">
      <!-- Menu separator -->
      <span v-if="i > 0" :key="`MenuSeparator${i}`" class="m__separator" />
      <!-- Menu item -->
      <li :key="`MenuItem${i}`" class="m__item">
        <b-list v-bind="item" @click="onItemClick(i)">
          <slot slot="icon" name="icon" />
        </b-list>
      </li>
    </template>
  </ol>
</template>

<script>
import ListButton from '../button/listButton'

export default {
  components: {
    'b-list': ListButton,
  },
  methods: {
    /**
     * Emit menu item click event
     * w/ item index
     */
    onItemClick(index) {
      if (index >= 0 && index < this.items.length) {
        this.$emit('click', index)
      }
    },
  },
  props: {
    /**
     * Menu items
     */
    items: {
      default: () => [],
      type: Array,
    },
  },
}
</script>

<style lang="postcss" scoped>
.m {
  @apply rounded-md bg-white shadow;
}
.m__item {
  @apply overflow-hidden;

  & .b--list {
    @apply w-full;
  }
  &:first-child {
    @apply rounded-t-md;
  }
  &:last-child {
    @apply rounded-b-md;
  }
}
.m__separator {
  @apply block h-px mx-4 bg-grey-200;
  width: calc(100% - 2rem);
}

@screen desktop {
  .m__separator {
    @apply mx-5;
    width: calc(100% - 2.5rem);
  }
}
</style>
