import { findDistrictByIDOrShortCode } from '../mapping/district'
import { findJobTypeByIDOrShortCode } from '../mapping/jobType'
import { getSearchParamFromURL } from '../mapping/searchParam'
import { transformValueToArray } from '../data/transform'

/**
 * createFilterQueryObjectFromRoute
 * @param route - The vuejs route object contain query and path
 */
export function createFilterQueryObjectFromRoute(route = {}) {
  return {
    ...createFilterQueryObjectFromRouteQuery(route.query),
    ...getSearchParamFromURL(route.params),
  }
}

function createFilterQueryObjectFromRouteQuery(queryData = {}) {
  const queryObj = {
    /** Company ID */
    company: queryData.company || null,
    /** District IDs */
    district: transformValueToArray(queryData.district)
      // TODO: backward compatible to support district ID in url query. Can remove the mapping in the future
      .map(findDistrictByIDOrShortCode)
      .map(({ shortCode } = {}) => shortCode)
      .filter((sc) => sc),
    /** Employment */
    employment: transformValueToArray(queryData.employment),
    fromWorkingDaysPerWeek: queryData.fromWorkingDaysPerWeek || null,
    fromWorkingHoursPerDay: queryData.fromWorkingHoursPerDay || null,
    hourlyRate: queryData.hourlyRate ? parseFloat(queryData.hourlyRate) : null,
    /** Job Type IDs */
    jobType: transformValueToArray(queryData.jobType)
      // TODO: backward compatible to support job type ID in url query. Can remove the mapping in the future
      .map(findJobTypeByIDOrShortCode)
      .map(({ shortCode } = {}) => shortCode)
      .filter((sc) => sc),
    /** Current position (latitude) */
    latitude: queryData.latitude ? parseFloat(queryData.latitude) : null,
    /** Current position (longitude) */
    longitude: queryData.longitude ? parseFloat(queryData.longitude) : null,
    monthlyRate: queryData.monthlyRate ? parseFloat(queryData.monthlyRate) : null,
    /** Search Text */
    q: queryData.q || '',
    /** Working Day IDs - Weekend | Weekday */
    workingDay: queryData.workingDay || null,
    /** Working Hour IDs */
    workingHour: transformValueToArray(queryData.workingHour),
  }
  return queryObj
}

/**
 * Transform Search query from feature list's data into filter query format
 * @param searchQuery Feature list's data
 */
export function transformSearchQueryToFilterQueryObject(searchQuery = {}) {
  return {
    /** District Short Codes */
    district: transformValueToArray(searchQuery.district),
    /** Employment */
    employment: transformValueToArray(searchQuery.employment),
    fromWorkingDaysPerWeek: searchQuery.from_working_days_per_week || null,
    fromWorkingHoursPerDay: searchQuery.from_working_hours_per_day || null,
    hourlyRate: searchQuery.hourly_rate ? parseFloat(searchQuery.hourly_rate) : null,
    /** Job Type Short codes */
    jobType: transformValueToArray(searchQuery.job_type),
    /** Current position (latitude) */
    latitude: searchQuery.latlng && searchQuery.latlng.lat ? parseFloat(searchQuery.latlng.lat) : null,
    /** Current position (longitude) */
    longitude: searchQuery.latlng && searchQuery.latlng.lng ? parseFloat(searchQuery.latlng.lng) : null,
    monthlyRate: searchQuery.monthly_rate ? parseFloat(searchQuery.monthly_rate) : null,
    /** Working Day IDs - Weekend | Weekday */
    workingDay: transformValueToArray(searchQuery.working_hour).filter((id) => id === 'Weekend' || id === 'Weekday'),
    /** Working Hour IDs */
    workingHour: transformValueToArray(searchQuery.working_hour).filter((id) => id !== 'Weekend' && id !== 'Weekday'),
  }
}
