export default {
  dismissError({ commit }) {
    commit('dismissError')
    commit('clearTimer')
  },

  showError({ commit }, errMsg) {
    commit('showError', errMsg)
    commit('clearTimer')
    commit('startTimer', () => {
      commit('dismissError')
      commit('clearTimer')
    })
  },
}
