/** Core fragment for jobs */
export const JOB_FRAGMENT_CORE = `
fragment jobFragmentCore on Job {
  _id
  address {
    district_name
    district_short_code
  }
  company {
    company_logo_thumbnail
    name
  }
  employment
  from_hourly_rate
  from_monthly_rate
  to_hourly_rate
  to_monthly_rate
  job_name
  slug {
    locale
    value
  }
}
`

/** Core fragment for featured company */
export const FEATURED_COMPANY_FRAGMENT_CORE = `
fragment featuredCompanyFragmentCore on FeaturedCompany {
  _id
  about
  alt_text
  name
  company_id
  company_logo
  company_logo_medium
  company_logo_thumbnail
}
`

/** fragment for apply jobs */
export const JOB_FRAGMENT_FOR_APPLY = `
fragment jobFragmentForApply on Job {
  _id
  published_at
  address {
    _id
    address
    district_name
    district_short_code
  }
  company {
    name
    company_logo_thumbnail
  }
  employment
  end_date
  from_hourly_rate
  from_monthly_rate
  from_working_days_per_week
  from_working_hours_per_day
  job_description
  job_name
  start_date
  slug {
    locale
    value
  }
  to_hourly_rate
  to_monthly_rate
  to_working_days_per_week
  to_working_hours_per_day
  vacancy
  is_applied
}
`

/** Full fragment for jobs */
export const JOB_FRAGMENT_FULL = `
fragment jobFragmentFull on Job {
  published_at
  _id
  _updated_at
  address {
    _id
    address
    district_name
    district_short_code
    lat
    lng
  }
  address_on_map
  allowances {
    description
    name
  }
  attributes {
    category
    category_display_sequence
    name
    name_display_sequence
  }
  alternative_sat
  career_level
  cert
  company {
    _id
    about
    company_logo_medium
    company_logo_thumbnail
    name
  }
  education_requirement {
    category
    level
    name
  }
  employment
  end_date
  from_hourly_rate
  from_monthly_rate
  from_working_days_per_week
  from_working_hours_per_day
  job_description
  job_name
  job_types {
    category
    name
    short_code
  }
  shift_required
  skill {
    name
  }
  slug {
    locale
    value
  }
  spoken_skill {
    level
    name
  }
  start_date
  to_hourly_rate
  to_monthly_rate
  to_working_days_per_week
  to_working_hours_per_day
  vacancy
  working_hour {
    _id
    day_of_week
    end_time
    start_time
  }
  written_skill {
    level
    name
  }
  state
  is_applied
  is_closed
  is_suspended
}
`

/** Fragment for job list */
export const JOB_LIST_FRAGMENT = `
fragment jobListFragment on JobList {
  _id
  jobs {
    ...jobFragmentCore
  }
  name {
    locale
    value
  }
}

${JOB_FRAGMENT_CORE}
`

/** Fragment for job feature list */
export const JOB_FEATURE_LIST_FRAGMENT = `
fragment jobFeatureListFragment on JobList {
  _id
  data
  jobs {
    ...jobFragmentCore
  }
  name {
    locale
    value
  }
}

${JOB_FRAGMENT_CORE}
`

/** Fragment for job search result */
export const JOB_SEARCH_RESULT_FRAGMENT = `
fragment jobSearchResultFragment on JobSearchResult {
  result {
    ...jobFragmentCore
  }
  total
}

${JOB_FRAGMENT_CORE}
`

/** Fragment for feature list result */
export const FEATURE_LIST_RESULT_FRAGMENT = `
fragment featureListResultFragment on CountedJobList {
  _id
  jobs {
    ...jobFragmentCore
  }
  name {
    locale
    value
  }
  total
}

${JOB_FRAGMENT_CORE}
`

/** Fragment for featured company list result */
export const FEATURED_COMPANY_LIST_RESULT_FRAGMENT = `
fragment featuredCompanyListResultFragment on CountedFeaturedCompanyList {
  companies {
    ...featuredCompanyFragmentCore
  }
  total
}

${FEATURED_COMPANY_FRAGMENT_CORE}
`

/** User profile fragment */
export const PROFILE_FRAGMENT = `
fragment profileFragment on Seeker {
  _id
  is_anonymous
  is_suspended
  name
  telephone
  applied_job_count
  profile_picture
  profile_picture_medium
  profile_picture_thumbnail
  max_work_day_per_week
  message_to_employer
  _created_by
  availability {
    from_minute_of_week
    to_minute_of_week
  }
  education {
    _id
    category
    level
    name
  }
  written_skill {
    level
    name
  }
  spoken_skill {
    level
    name
  }
  skill {
    name
  }
  cert {
    name
  }
  work_experience {
    company_name
    description
    start_date
    end_date
    position_name
    position_type
    employment
  }
  delete_account_at
}
`

/** Core fragment for applications */
export const APPLICATION_FRAGMENT_CORE = `
fragment applicationFragmentCore on Application {
  _id
  address {
    _id
    address
    district_name
    district_short_code
  }
  applied_at
  history {
    _created_at
    event
  }
}
`

/** Core fragment for notifications */
export const NOTIFICATION_FRAGMENT_CORE = `
fragment notificationFragmentCore on Notification {
  _id
  application_id
  _updated_at
  read
  data
  type
  job {
    ...jobFragmentCore
  }
}
`

/** Fragment for homepage banner */
export const HOME_BANNER_FRAGMENT = `
fragment homeBannerFragment on HomeBanner {
  cover_image
  link
  description
}
`

/** Fragment for featured company */
export const FEATURED_COMPANY_FRAGMENT = `
fragment featuredCompanyFragment on FeaturedCompanyList {
  _id
  companies {
    ...featuredCompanyFragmentCore
  }
  name {
    locale
    value
  }
}

${FEATURED_COMPANY_FRAGMENT_CORE}
`
