/**
 * Search *Vuex* module state
 */
export default function () {
  return {
    /**
     * Search result by full path
     * <full path>: {
     *   data: {component data},
     *   timestamp,
     * }
     */
    cache: {},
  }
}
