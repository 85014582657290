// ---------------------
// Employment type names
// ---------------------
export const FullTime = 'FullTime'
export const PartTime = 'PartTime'
export const Temp = 'Temp'

/**
 * List of ordered employment types
 * TODO: Remove IDs once notification page will not be supported the old employment_type
 * @type {[{_id: string, name: string, shortCode: string, trackingName: string}]}
 */
export const employmentTypes = [
  {
    _id: 'a752193e-e1a3-4166-8fbf-4f4f297b453c',
    name: FullTime,
    shortCode: 'ft',
    trackingName: 'full_time',
  },
  {
    _id: '7234e723-fd12-4fb7-8f00-aec9d61bbc68',
    name: PartTime,
    shortCode: 'pt',
    trackingName: 'part_time',
  },
  {
    _id: '4b476044-f0c3-4796-b0fd-dce122159daa',
    name: Temp,
    shortCode: 'ct',
    trackingName: 'casual_temp',
  },
]

/**
 * Find employment type data by ID
 * TODO: Remove once notification page will not be supported the old employment_type
 * @param {string} empTypeID
 * @param {string} fieldName
 * @returns {string}
 */
export function findEmploymentTypeFieldByID(empTypeID, fieldName) {
  if (empTypeID) {
    const empType = employmentTypes.find((et) => et._id === empTypeID)
    if (empType && empType[fieldName]) {
      return empType[fieldName]
    }
  }
  return ''
}

/**
 * Find employment type field by type and field names
 * @param {string} empTypeName
 * @param {string} fieldName
 * @returns {string}
 */
export function findEmploymentTypeFieldByName(empTypeName, fieldName) {
  if (empTypeName) {
    const empType = employmentTypes.find((et) => et.name === empTypeName)
    if (empType && empType[fieldName]) {
      return empType[fieldName]
    }
  }
  return null
}

/**
 * Format employment type name into localisation key
 * @param {string} empTypeName
 * @returns {string}
 */
export function getEmploymentTypeLocaleKey(empTypeName) {
  if (empTypeName) {
    return `employment.${empTypeName}`
  }
  return ''
}

/**
 * Sort employment type names
 * @param {[string]} empTypeNames
 * @returns {[string]}
 */
export function sortEmploymentTypeNames(empTypeNames = []) {
  return employmentTypes.map(({ name }) => name).filter((name) => empTypeNames.includes(name))
}
