import AdListMixin from './bidding/adList'
import ImpressionMixin from './tracking/impressionObserver'
import JobAdCard from '../components/job/jobCard/jobAdCard'
import JobCard from '../components/job/jobCard/jobCard'

export default {
  components: {
    'job-ad-card': JobAdCard,
    'job-card': JobCard,
  },
  mixins: [AdListMixin, ImpressionMixin],
  props: {
    jobs: {
      required: true,
      type: Array,
    },
    sessionId: {
      default: '',
      type: String,
    },
    source: {
      default: '',
      type: String,
    },
    sourceSection: {
      default: '',
      type: String,
    },
    /** Formatted tracking params */
    trackingParams: {
      default: () => ({}),
      type: Object,
    },
  },
}
