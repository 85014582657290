<template>
  <div class="error">
    <section class="error__container">
      <svg-error class="error__logo" />
      <h1>{{ $t('error.content404') }}</h1>
      <b-main type="submit" class="b__action" :title="$t('error.home')" @click="onClick" />
    </section>
    <featured-list
      v-if="showFeaturedList"
      :id="featureList._id"
      :key="`ErrorFeaturedList-${featureList._id}`"
      class="featured-list"
      :session-id="session"
      :jobs="featureList.jobs"
      :filter-query-object="getFilterQueryObjectFromData(featureList.data)"
      :title-locales="featureList.name"
      :source="source"
    />
  </div>
</template>

<script>
import { GET_HOMEPAGE_FEATURE_LIST, GET_RELATED_JOBS_BY_IDS_QUERY } from '../../api/octo/graphql/queries'
import { createSessionId } from '../../libs/tracking/utils/session'
import FeaturedList from '../../components/list/featuredList'
import GraphqlFormMixin from '../../mixins/form/graphql'
import MainButton from '../../components/button/mainButton'
import SvgError from '../../assets/svg/error_logo.svg?inline'
import { transformSearchQueryToFilterQueryObject } from '../../libs/search/filter'

export default {
  components: {
    'b-main': MainButton,
    'featured-list': FeaturedList,
    'svg-error': SvgError,
  },
  computed: {
    /**
     * Graphql variables
     */
    gqlVariables() {
      if (this.gqlOperation === 'getRelatedJobs') {
        return {
          jobID: this.jobId,
          limit: 6, // Fetch one extra job to prevent return current job
          offset: 0,
        }
      }
      return {}
    },
    /**
     * show or hide featured list
     */
    showFeaturedList() {
      return this.featureList && this.featureList.jobs.length
    },
  },
  created() {
    this.$tokenReady(this.getSimilarJobs)
  },
  data() {
    return {
      featureList: null,
      /**
       * Graphql queries
       */
      gqlQueries: {
        getFeatureList: GET_HOMEPAGE_FEATURE_LIST,
        getRelatedJobs: GET_RELATED_JOBS_BY_IDS_QUERY,
      },
      session: createSessionId(),
      source: '',
    }
  },
  head() {
    return {
      title: this.$t('error.title404'),
    }
  },
  methods: {
    async _getRecommendedList(token) {
      const res = await this.submitGql('getFeatureList', true)
      // Extract homepage result from response
      if (res && res.data && res.data.homepage && Array.isArray(res.data.homepage.job_featured_lists)) {
        const matchList = res.data.homepage.job_featured_lists.filter(
          (item) => item.name.filter((localeItem) => localeItem.value === 'Recommended jobs').length,
        )
        if (matchList.length) {
          this.source = 'error.recommended'
          this.featureList = matchList[0]
        }
      }
    },
    async _getRelatedJobs(token) {
      if (this.jobId) {
        const res = await this.submitGql('getRelatedJobs', true)
        // Extract jobs result from response
        if (res && res.data && res.data.related_jobs) {
          const relatedJobsList = res.data.related_jobs
          if (Array.isArray(relatedJobsList.result)) {
            this.source = 'error.related_job'
            this.featureList = {
              _id: '',
              data: null,
              jobs: relatedJobsList.result,
              name: [
                { locale: 'en', value: this.$t('jobDetail.relatedJobs') },
                { locale: 'zh-hant', value: this.$t('jobDetail.relatedJobs') },
              ],
            }
            return true
          }
        }
      }
      return false
    },
    getFilterQueryObjectFromData(data) {
      if (data && data.search_query) {
        return transformSearchQueryToFilterQueryObject(data.search_query)
      }
    },
    async getSimilarJobs(token) {
      // attempt to get related jobs
      if (!(await this._getRelatedJobs(token))) {
        // if the related job is not there, use recommended list as a fallback
        await this._getRecommendedList(token)
      }
    },
    onClick() {
      this.$router.push({
        path: '/',
      })
    },
  },
  mixins: [GraphqlFormMixin],
  props: {
    jobId: {
      default: '',
      required: false,
      type: String,
    },
  },
}
</script>

<style lang="postcss" scoped>
.error {
  @apply w-full;
}
.error__container {
  @apply flex flex-col items-center py-8;
}

h1 {
  @apply p-4;
  @apply text-17/4 font-bold text-center;
  margin-bottom: 1.5em;
}

@screen mobile {
  .error__logo {
    @apply mb-4;
    width: 267px;
  }
  .featured-list {
    @apply mx-2;
  }
}

@screen desktop {
  .error__container {
    @apply py-16;
  }
  .error__logo {
    width: 359px;
  }
  h1 {
    @apply text-xl;
  }
  span {
    @apply text-17/4;
  }
}
</style>
