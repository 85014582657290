import { decodeToken, isSeekerToken, LS_TOKEN_KEY } from '../../libs/token'
import { logout, refreshToken } from '../../api/octo/auth'
import { getAnonymousToken } from '../../api/octo'
import { setLocalStorage } from '../../libs/localStorage'

async function processToken(commit, dispatch, token) {
  // Try to decode token
  const payload = decodeToken(token)
  // If token is valid,
  // set token to *Vuex* store and *local storage*
  if (payload) {
    // Set token and payload to store
    await commit('setTokenAndPayload', {
      payload,
      token,
    })

    // Persist token to local storage
    setLocalStorage(LS_TOKEN_KEY, token)

    // Trigger queue callbacks with token and payload value
    dispatch('triggerReadyQueueCallbacks', {
      payload,
      token,
    })
  }
}

/**
 * Auth *Vuex* module actions
 */
export default {
  /**
   * Handle token change in local storage
   */
  async handleLocalStorageTokenChange({ commit, dispatch, state }, { newToken, routeCallback }) {
    if (state.token !== newToken) {
      const oldPayload = state.payload
      const oldIdentity = isSeekerToken(oldPayload)
      const newPayload = decodeToken(newToken)
      const newIdentity = isSeekerToken(newPayload)

      // Set token and payload to store
      await commit('setTokenAndPayload', {
        payload: newPayload,
        token: newToken,
      })

      // Persist token to local storage
      setLocalStorage(LS_TOKEN_KEY, newToken)

      // Identity Change
      if (oldIdentity !== newIdentity) {
        if (newIdentity) {
          await dispatch('profile/getProfile', newToken, { root: true })
        }
        routeCallback(newIdentity)
      }
    }
  },

  /**
   * Logout seeker account and return to an Anonymous user
   */
  async logout({ dispatch, state }) {
    await logout(state.token, '')
    await dispatch('switchToAnonymousUser')
  },

  /**
   * Refresh token and get user profile
   */
  async resfreshTokenAndGetProfile({ commit, dispatch, state }) {
    const tempToken = state.token
    // Clear token
    await commit('setTokenAndPayload')
    const res = await refreshToken(tempToken)

    if (res && res.data && res.data.access_token) {
      await dispatch('setSeekerToken', res.data.access_token)
    } else {
      // If refresh token fail, force to be anonymous user
      await dispatch('signinWithAnonymousUser')
    }
  },

  /**
   * Validate provided seeker token,
   * if valid set it to *Vuex* store and *local storage*,
   * then get user profile from Octo *graphql* endpoint,
   * otherwise clear token and profile from the stores
   */
  async setSeekerToken({ commit, dispatch }, token = '') {
    if (token) {
      await processToken(commit, dispatch, token)
      await dispatch('profile/getProfile', token, { root: true })
    }
  },

  /**
   * Signin to be anonymous user
   * Will fetch token from *Octo*, if valid set it to *Vuex* store and *local storage*
   */
  async signinWithAnonymousUser({ commit, dispatch }) {
    // Get token from *Octo* endpoint
    const res = await getAnonymousToken()
    if (res && res.data && res.data.access_token) {
      await processToken(commit, dispatch, res.data.access_token)
    } else {
      // Remarks: Monitor the sentry logging to check hit rate
    }
  },

  /**
   * Switch to Anonymous User
   */
  async switchToAnonymousUser({ commit, dispatch }) {
    await dispatch('signinWithAnonymousUser')
    await commit('profile/clearProfile', null, { root: true })
  },

  /**
   * Trigger callbacks in token ready queue
   * with token and payload value
   */
  triggerReadyQueueCallbacks({ commit, state }, { payload, token }) {
    if (state.readyQueue.length) {
      // Trigger the callbacks
      state.readyQueue.forEach((callback) => callback(token, payload))

      // Clear ready queue
      commit('clearReadyQueue')
    }
  },

  /**
   * On client side,
   * trigger provided callback with token and payload
   * once token is ready
   * @param {*} context
   * @param {Function} callback
   */
  whenTokenReady({ commit, state }, callback) {
    if (typeof callback === 'function') {
      if (state.token && state.payload) {
        // If token ready,
        // trigger callback directly
        callback(state.token, state.payload)
      } else {
        // Otherwise add callback to ready queue
        commit('addReadyCallback', callback)
      }
    }
  },
}
