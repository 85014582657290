<template>
  <component
    :is="buttonComponent"
    class="b b-icon_text"
    tabindex="0"
    v-bind="buttonProps"
    @click="onClick"
    @click.native="onLinkClick"
  >
    <slot name="icon" />
    <span class="b__title">{{ title }}</span>
    <slot name="label" />
  </component>
</template>

<script>
import ButtonMixin from '../../mixins/button'

export default {
  mixins: [ButtonMixin],
}
</script>

<style lang="postcss" scoped>
.b.b-icon_text {
  @apply justify-start;

  & >>> svg {
    @apply flex-shrink-0 w-b-sm h-b-sm;
    @apply mr-2 text-grey-900;
  }
}
.b__title {
  line-height: 20px;
}

@screen desktop {
  .b.b-icon_text >>> svg {
    @apply mr-3;
  }
  .b__title {
    line-height: 26px;
  }
}
</style>
