<template>
  <section class="emp-hdr-main">
    <!-- Menu toggle button -->
    <b-icon class="emp-hdr-main__menu-toggle" title="Show menu" @click="onMenuToggleClick">
      <svg-menu slot="icon" />
    </b-icon>
    <!-- Masthead -->
    <emp-header-masthead />
    <ol class="emp-hdr-actions">
      <!-- Employer login button -->
      <li class="emp-hdr-actions__item emp-hdr-actions__item--mobile">
        <b-main :href="employerLoginURL" :title="$t('employer.login')" />
      </li>
      <!-- Other action buttons -->
      <template v-for="(item, i) in actionItems">
        <li v-if="item && item.title" :key="`EmployerHeaderActionItem${i}`" class="emp-hdr-actions__item">
          <b-nav :link-path="item.linkPath" :title="item.title" />
        </li>
      </template>
    </ol>
  </section>
</template>

<script>
import EmployerHeaderMasthead from './employerHeaderMasthead'
import EmployerNavMixin from '../../../mixins/employer/nav'
import { getTrackingURL } from '../../../libs/tracking/utils/url'
import IconButton from '../../button/iconButton'
import MainButton from '../../button/mainButton'
import NavButton from '../../button/navButton'
import SvgMenu from '../../../assets/svg/menu.svg?inline'

export default {
  components: {
    'b-icon': IconButton,
    'b-main': MainButton,
    'b-nav': NavButton,
    'emp-header-masthead': EmployerHeaderMasthead,
    'svg-menu': SvgMenu,
  },
  computed: {
    /**
     * Login link URL
     */
    employerLoginURL() {
      return getTrackingURL('https://employer.moovup.com/', 'referral', 'employer', 'header')
    },
  },
  methods: {
    /**
     * Menu toggle click event
     */
    onMenuToggleClick() {
      this.$emit('menu-toggle')
    },
  },
  mixins: [EmployerNavMixin],
}
</script>

<style lang="postcss" scoped>
.emp-hdr-main {
  @apply flex items-center w-full;

  & .emp-hdr-masthead {
    @apply h-full;
  }
}

.emp-hdr-actions {
  @apply flex-grow flex flex-row-reverse items-center;
}
.emp-hdr-actions__item {
  @apply flex-shrink-0;
}

@screen mobile {
  .emp-hdr-main {
    & .b.emp-hdr-main__menu-toggle {
      @apply flex-shrink-0 ml-1;
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  .emp-hdr-actions {
    @apply px-2;
  }
  .emp-hdr-actions__item {
    &:not(.emp-hdr-actions__item--mobile) {
      @apply hidden;
    }
    & .b.b--main {
      @apply px-4;
      height: 2rem;
    }
    & .b.b--main,
    & .b.b--main:after {
      @apply rounded;
    }
  }
}

@screen desktop {
  .emp-hdr-main {
    & .b.emp-hdr-main__menu-toggle {
      @apply hidden;
    }
  }

  .emp-hdr-actions {
    @apply px-6;
  }
  .emp-hdr-actions__item {
    & .b.b--main {
      @apply px-5;
      height: 2.5rem;
    }
    & .b.b-nav {
      height: 40px;
    }
  }
}
</style>
