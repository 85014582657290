import { formatHHmm, groupNeighbourDaysOfWeek } from './datetime'
import _round from 'lodash/round'
import _sortBy from 'lodash/sortBy'

/**
 * Format monthly salary into tens of thousands,
 * rounding up to 1 decimal place
 * @param {number} salary
 */
export function formatMonthlySalary(salary) {
  if (salary) {
    return _round(salary / 1000, 1)
  }
  return 0
}

/**
 * Group allowances and sort ASC by name
 * @param {Array} allowances
 */
export function groupAllowancesByName(allowances) {
  if (Array.isArray(allowances)) {
    const names = {}
    allowances
      .filter((allowance) => allowance && allowance.name && allowance.description)
      .forEach((allowance) => {
        // Add new name if not exist
        if (!names[allowance.name]) {
          names[allowance.name] = {
            descriptions: [],
            name: allowance.name,
          }
        }
        // Add description to name group
        names[allowance.name].descriptions.push(allowance.description)
      })

    // Sort ASC by names
    return _sortBy(Object.values(names), 'name')
  }
  return []
}

/**
 * Format job shift entries
 * @param {Array} shifts
 */
export function mapShifts(shifts) {
  if (Array.isArray(shifts)) {
    return shifts.map((shift) => {
      // Format start and end times into HH:mm format
      const startTime = formatHHmm(shift.start_time)
      const endTime = formatHHmm(shift.end_time)

      return {
        // Group neighbouring days of week together
        dayGroups: groupNeighbourDaysOfWeek(shift.day_of_week),
        endTime,
        isOvernight: endTime < startTime,
        startTime,
      }
    })
  }
  return []
}

/**
 * Format job ID and slug into locale paths
 * @param {{_id: string, slug: [{locale: string, value: string}]}} job
 * @returns {Dictionary<string>}
 */
export function getJobLocalePaths({ _id, slug } = {}) {
  const localePaths = {}
  if (_id && Array.isArray(slug)) {
    slug.forEach(({ locale, value } = {}) => {
      if (locale && value) {
        localePaths[locale] = `/job/${value}/${_id}/`
      }
    })
  }

  return localePaths
}
