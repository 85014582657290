import Vue from 'vue'

import { getMatchedComponentsInstances, getChildrenComponentInstancesUsingFetch, promisify, globalHandleError, urlJoin, sanitizeComponent } from './utils'
import NuxtError from '../layouts/error.vue'
import NuxtLoading from './components/nuxt-loading.vue'

import '../assets/styles/core.css'

import '../node_modules/prismjs/themes/prism.css'

import _4c0f67c3 from '../layouts/content.vue'
import _6f6c098b from '../layouts/default.vue'
import _77384fb2 from '../layouts/defaultNoQuickBar.vue'
import _9e00fe6c from '../layouts/defaultNoSearchAction.vue'
import _5720c9b5 from '../layouts/defaultNoSticky.vue'
import _c25dc69e from '../layouts/employer.vue'
import _ee7125b0 from '../layouts/simple.vue'
import _ee708084 from '../layouts/single.vue'

const layouts = { "_content": sanitizeComponent(_4c0f67c3),"_default": sanitizeComponent(_6f6c098b),"_defaultNoQuickBar": sanitizeComponent(_77384fb2),"_defaultNoSearchAction": sanitizeComponent(_9e00fe6c),"_defaultNoSticky": sanitizeComponent(_5720c9b5),"_employer": sanitizeComponent(_c25dc69e),"_simple": sanitizeComponent(_ee7125b0),"_single": sanitizeComponent(_ee708084) }

export default {
  render (h, props) {
    const loadingEl = h('NuxtLoading', { ref: 'loading' })

    const layoutEl = h(this.layout || 'nuxt')
    const templateEl = h('div', {
      domProps: {
        id: '__layout'
      },
      key: this.layoutName
    }, [layoutEl])

    return h('div', {
      domProps: {
        id: '__nuxt'
      }
    }, [
      loadingEl,

      templateEl
    ])
  },

  data: () => ({
    isOnline: true,

    layout: null,
    layoutName: '',

    nbFetching: 0
    }),

  beforeCreate () {
    Vue.util.defineReactive(this, 'nuxt', this.$options.nuxt)
  },
  created () {
    // Add this.$nuxt in child instances
    this.$root.$options.$nuxt = this

    if (process.client) {
      // add to window so we can listen when ready
      window.$nuxt = this

      this.refreshOnlineStatus()
      // Setup the listeners
      window.addEventListener('online', this.refreshOnlineStatus)
      window.addEventListener('offline', this.refreshOnlineStatus)
    }
    // Add $nuxt.error()
    this.error = this.nuxt.error
    // Add $nuxt.context
    this.context = this.$options.context
  },

  async mounted () {
    this.$loading = this.$refs.loading

    if (this.isPreview) {
      if (this.$store && this.$store._actions.nuxtServerInit) {
        this.$loading.start()
        await this.$store.dispatch('nuxtServerInit', this.context)
      }
      await this.refresh()
      this.$loading.finish()
    }
  },

  watch: {
    'nuxt.err': 'errorChanged'
  },

  computed: {
    isOffline () {
      return !this.isOnline
    },

    isFetching () {
      return this.nbFetching > 0
    },

    isPreview () {
      return Boolean(this.$options.previewData)
    },
  },

  methods: {
    refreshOnlineStatus () {
      if (process.client) {
        if (typeof window.navigator.onLine === 'undefined') {
          // If the browser doesn't support connection status reports
          // assume that we are online because most apps' only react
          // when they now that the connection has been interrupted
          this.isOnline = true
        } else {
          this.isOnline = window.navigator.onLine
        }
      }
    },

    async refresh () {
      const pages = getMatchedComponentsInstances(this.$route)

      if (!pages.length) {
        return
      }
      this.$loading.start()

      const promises = pages.map((page) => {
        const p = []

        // Old fetch
        if (page.$options.fetch && page.$options.fetch.length) {
          p.push(promisify(page.$options.fetch, this.context))
        }
        if (page.$fetch) {
          p.push(page.$fetch())
        } else {
          // Get all component instance to call $fetch
          for (const component of getChildrenComponentInstancesUsingFetch(page.$vnode.componentInstance)) {
            p.push(component.$fetch())
          }
        }

        if (page.$options.asyncData) {
          p.push(
            promisify(page.$options.asyncData, this.context)
              .then((newData) => {
                for (const key in newData) {
                  Vue.set(page.$data, key, newData[key])
                }
              })
          )
        }

        return Promise.all(p)
      })
      try {
        await Promise.all(promises)
      } catch (error) {
        this.$loading.fail(error)
        globalHandleError(error)
        this.error(error)
      }
      this.$loading.finish()
    },
    errorChanged () {
      if (this.nuxt.err) {
        if (this.$loading) {
          if (this.$loading.fail) {
            this.$loading.fail(this.nuxt.err)
          }
          if (this.$loading.finish) {
            this.$loading.finish()
          }
        }

        let errorLayout = (NuxtError.options || NuxtError).layout;

        if (typeof errorLayout === 'function') {
          errorLayout = errorLayout(this.context)
        }

        this.setLayout(errorLayout)
      }
    },

    setLayout (layout) {
      if (!layout || !layouts['_' + layout]) {
        layout = 'default'
      }
      this.layoutName = layout
      this.layout = layouts['_' + layout]
      return this.layout
    },
    loadLayout (layout) {
      if (!layout || !layouts['_' + layout]) {
        layout = 'default'
      }
      return Promise.resolve(layouts['_' + layout])
    },

    setPagePayload(payload) {
      this._pagePayload = payload
      this._payloadFetchIndex = 0
    },
    async fetchPayload(route) {
      const { staticAssetsBase } = window.__NUXT__
      const base = (this.$router.options.base || '').replace(/\/+$/, '')
      if (base && route.startsWith(base)) {
        route = route.substr(base.length)
      }
      route = (route.replace(/\/+$/, '') || '/').split('?')[0].split('#')[0]
      const src = urlJoin(base, staticAssetsBase, route, 'payload.js')
      try {
        const payload = await window.__NUXT_IMPORT__(decodeURI(route), encodeURI(src))
        this.setPagePayload(payload)
        return payload
      } catch (err) {
        this.setPagePayload(false)
        throw err
      }
    }
  },

  components: {
    NuxtLoading
  }
}
