<template>
  <div class="search">
    <form @submit.prevent="onSubmit()">
      <div class="search_main">
        <label class="search__icon" for="search-text">
          <icon-button class="search__icon--main" :title="$t('search.hint')" @click="onSubmit">
            <svg-search-icon slot="icon" />
          </icon-button>
          <div class="search__icon--bg" />
        </label>
        <input
          id="search-text"
          ref="input"
          v-model="searchText"
          class="search__input"
          type="search"
          name="q"
          :placeholder="$t('search.hint')"
        />
      </div>
    </form>
  </div>
</template>

<script>
import IconButton from '../button/iconButton'
import SvgSearchIcon from '../../assets/svg/search.svg?inline'

export default {
  components: {
    'icon-button': IconButton,
    'svg-search-icon': SvgSearchIcon,
  },
  created() {
    this.searchText = this.$route.query.q || ''
  },
  data() {
    return {
      isChange: false,
      searchText: '',
    }
  },
  methods: {
    onSubmit() {
      this.$refs.input.blur()

      if (this.searchText && this.isChange) {
        this.isChange = false
        this.$emit('on-search', this.searchText)
      }
    },
  },
  watch: {
    $route(to, from) {
      this.searchText = to.query.q || ''
    },
    searchText() {
      this.isChange = true
    },
  },
}
</script>

<style lang="postcss" scoped>
.search {
  @apply w-full;
}
.search_main {
  @apply relative;
}
.search__input {
  @apply w-full text-black bg-pink-xlight rounded-lg pr-2 text-cbody1 font-cbody1;
  -webkit-appearance: none;
  height: 40px;
  padding-left: 44px;
  &:focus {
    @apply outline-none;
  }
  &::placeholder {
    @apply text-grey-400;
  }
}
.search__icon {
  @apply absolute left-0 top-0 flex items-center h-full justify-center;
  width: 44px;
}
.search__icon--main {
  @apply relative z-1;
  background-color: transparent;
}

@screen desktop {
  .search__input {
    height: 44px;
    padding-left: 60px;
  }
  .search__icon {
    width: 52px;
  }
}
</style>
