/**
 * NuxtJS plugin to setup profile on client side
 */
export default function ({ store }, inject) {
  // Inject global function for
  // adding callback to profile ready queue
  // only on client side
  inject('profileReady', (callback) => {
    if (process.client) {
      store.dispatch('profile/whenProfileReady', callback)
    }
  })
}
