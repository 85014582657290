/**
 * Whether current environment is development
 * @returns {boolean}
 */
export function isEnvDevelopment() {
  return process.env.SEEKER_WEB__ENVIRONMENT === 'development'
}

/**
 * Whether current environment is production or beta
 * @returns {boolean}
 */
export function isEnvProduction() {
  return ['production', 'beta'].includes(process.env.SEEKER_WEB__ENVIRONMENT)
}
