<template>
  <nav class="emp-hdr-nav">
    <ol class="emp-hdr-nav__items">
      <template v-for="(item, i) in pageItems">
        <li v-if="item && item.title" :key="`EmployerHeaderNavItem${i}`" class="emp-hdr-nav__item">
          <b-nav :href="item.href" :is-new="item.isNew" :link-path="item.linkPath" :title="item.title" />
        </li>
      </template>
    </ol>
  </nav>
</template>

<script>
import EmployerNavMixin from '../../../mixins/employer/nav'
import NavButton from '../../button/navButton'

export default {
  components: {
    'b-nav': NavButton,
  },
  mixins: [EmployerNavMixin],
}
</script>

<style lang="postcss" scoped>
.emp-hdr-nav {
  @apply w-full h-h-emp;
}
.emp-hdr-nav__items {
  @apply flex items-center w-full h-full px-1;

  & .emp-hdr-nav__item {
    @apply flex-shrink-0;
  }
}
</style>
