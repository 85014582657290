import Tracker from './tracker'

/**
 * The global site tag (gtag.js) is a JavaScript tagging framework and API that allows you to send event data to Google Analytics, Google Ads, and Google Marketing Platform
 * https://developers.google.com/analytics/devguides/collection/gtagjs
 *
 * Refer to nuxt plugin - google-gtag for configuration
 * https://github.com/nuxt-community/google-gtag
 */
export default class GtagTracker extends Tracker {
  constructor(gtag, id) {
    super()
    this._gtag = gtag
    this._id = id
  }

  init() {
    // 'google-gtag' in nuxt.config.js for gtag setup
  }

  sendCustomEvent(eventName, eventVariables) {
    const variables = {
      ...eventVariables,
      send_to: this._id,
    }
    this._gtag('event', eventName, variables)
  }
}
