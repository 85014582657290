import { requestIdleCallback } from '../../libs/timeout'

/**
 * Tracking logic for layouts
 */
export default {
  mounted() {
    // Delay async initializing event trackers
    // to after page is mounted and idle
    setTimeout(() => {
      requestIdleCallback(() => {
        this.$eventTracker.deferInit()
      })
    }, 1500)
  },
}
