<template>
  <footer class="ftr">
    <div class="ftr__main container">
      <app-footer-top :referral-source="referralSource" />
      <span class="section-break" />
      <app-footer-bottom />
    </div>
  </footer>
</template>

<script>
import AppFooterBottom from './appFooterBottom'
import AppFooterTop from './appFooterTop'

export default {
  components: {
    'app-footer-bottom': AppFooterBottom,
    'app-footer-top': AppFooterTop,
  },
  props: {
    /**
     * Source for tracking in referral links
     */
    referralSource: {
      default: 'seeker',
      type: String,
    },
  },
}
</script>

<style lang="postcss" scoped>
.ftr {
  @apply w-full text-black bg-grey-xlight;
}
.ftr__main {
  @apply mx-auto;
}
.section-break {
  @apply my-3;
}

>>> .ftr__links {
  @apply flex flex-col items-start;
}
>>> a {
  @apply text-sm text-black;
}
>>> svg {
  @apply text-white;
}

@screen mobile {
  .ftr {
    @apply px-6 pt-6 pb-7;
  }
}

@screen desktop {
  .ftr {
    @apply px-6 pt-8 pb-5;
  }
  .section-break {
    @apply mt-12 mb-2;
  }

  >>> .ftr__section {
    @apply flex relative;
  }
}
</style>
